import React, { useEffect, useState } from 'react';
import editRoundSamplesStyle from './editRoundCategories.module.scss';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import {
  GenericTable,
  Modal,
  UploadButton,
  Button,
  Spinner,
  Checkbox,
  DropdownMultiField,
  TextField,
  CardRow,
  CardColumn,
  Loading,
} from '../../../components';
import PropTypes from 'prop-types';
import RoundService from '../../services/rounds/round.service';
import SampleService from '../../services/samples/sample.service';
import ReportService from '../../services/reports/report.service';
import BrandService from '../../services/brands/brand.service';
import AppService from '../../../app.service';
import { useTranslation } from 'react-i18next';
import EditRoundCategoriesSample from '../editRoundCategoriesSample/EditRoundCategoriesSample';
import uuid from 'react-uuid';
import { HelpCircle, X } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import configs from '../../../configs';

const EditRoundCategories = props => {
  const [samples, setSamples] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState({ arr_categories_samples: [] });
  const [brands, setBrands] = useState([]);
  const [samplesRound, setSamplesRound] = useState([]);
  const [filteredSamplesRound, setFilteredSamplesRound] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRoundSample, setLoadingRoundSample] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const roundService = new RoundService();
  const sampleService = new SampleService();
  const reportService = new ReportService();
  const brandService = new BrandService();
  const appService = new AppService();

  const [templateName, setTemplateName] = useState('');
  const { t } = useTranslation();
  const [downloadFlag, setDownloadFlag] = useState(false);
  const [testTypeOpts, setTestTypeOpts] = useState([]);
  const [productionLineOpts, setProductionLineOpts] = useState([]);

  useEffect(() => {
    setLoading(true);
    refreshState();
  }, []);

  const refreshState = async () => {
    //GET SAMPLES
    brandService.listBrands().then(res => {
      setBrands(res.results);
    });

    sampleService.listCategoriesSamples().then(
      res => {
        if (res.type === 'success' && res.results) {
          const arrSamples = res.results.map(data => {
            data.bol_check = 0;
            return data;
          });
          roundService.listRoundCategoriesSamples(props.round.uuid_round).then(
            res => {
              if (res.type === 'success' && res.results) {
                props.setRound({
                  ...props.round,
                  arr_samples: res.results,
                });
                let arrSamplesRound = res.results.filter(data => {
                  return data.dat_del == null;
                });
                setCategories(reduceSamplesToCategories(arrSamples));
                setSamples(arrSamples);
                setSamplesRound(
                  arrSamplesRound.map((sample, index) => {
                    return { ...sample, int_order_app: index };
                  }),
                );
                setTemplateName(props.round.str_template_name);
                setLoading(false);
              } else {
                window.alert(res.message);
                setLoading(false);
              }
            },
            error => {
              //console.log(error);
              setLoading(false);
            },
          );
        } else {
          window.alert(res.message);
          setLoading(false);
        }
      },
      error => {
        //console.log(error);
        setLoading(false);
      },
    );
    const { results } = await sampleService.listRoutineWarehouse();
    if (results) {
      setTestTypeOpts(results);
    }
    const res = await sampleService.listProductionLine();
    if (res) {
      setProductionLineOpts(res.results);
    }
  };

  const reduceSamplesToCategories = samples => {
    const arrCategories = [];
    const map = new Map();
    for (const item of samples) {
      if (!map.has(item.uuid_category)) {
        map.set(item.uuid_category, true); // set any value to Map
        arrCategories.push({
          uuid_category: item.uuid_category,
          str_name_category: item.str_name_category,
        });
      }
    }
    return arrCategories;
  };

  const handleSampleOrder = (oldIndex, newIndex) => {
    //CHANGE ORDER OF SAMPLES AND UPDATE APP INDEX
    let samplesTemp = samplesRound;
    const sampleTemp = samplesTemp[oldIndex];

    samplesTemp.splice(oldIndex, 1);
    samplesTemp.splice(newIndex, 0, sampleTemp);

    return samplesTemp.map((sample, index) => {
      return { ...sample, int_order_app: index };
    });
  };

  const handleDropSample = ({ oldIndex, newIndex }) => {
    let arrSamples = samplesRound ? handleSampleOrder(oldIndex, newIndex) : [];
    props.setRound({
      ...props.round,
      arr_samples: arrSamples,
    });
    setSamplesRound(arrSamples);
  };

  const handleAddSample = () => {
    let samplesTemp = samples;
    const dt_collection = new Date().toISOString();
    const samplesToSave = samplesTemp
      .filter(sample => {
        return sample.bol_check;
      })
      .map(sample => {
        return { ...sample, uuid_sample: uuid(), dt_collection };
      });
    let arrSamples = samplesRound ? samplesRound : [];
    arrSamples = arrSamples
      .concat(samplesToSave)
      .map((e, i) => ({ ...e, int_order_app: i }));
    props.setRound({
      ...props.round,
      arr_samples: arrSamples,
    });
    props.setAddUpdateSample && props.setAddUpdateSample([...samplesToSave]);
    setSamplesRound(arrSamples);
    setSamples(
      samplesTemp.map(sample => {
        if (sample.bol_check) {
          sample.bol_check = 0;
        }
        return sample;
      }),
    );
  };

  const handleRemoveSample = sample => {
    const samplesTemp = samplesRound.filter(sampleTemp => {
      return sample.uuid_sample !== sampleTemp.uuid_sample;
    });
    const arrSamples = samplesTemp
      ? samplesTemp.map((e, i) => ({ ...e, int_order_app: i }))
      : [];
    props.setRound({
      ...props.round,
      arr_samples: arrSamples,
    });
    let samps = samples;
    setSamples(
      samps.map(sample => {
        sample.bol_check = false;
        return sample;
      }),
    );
    setSamplesRound(arrSamples);
  };

  const handleCheckSample = (sample, evt) => {
    setSamples(
      samples.map(sampleTemp => {
        if (
          evt.target &&
          sampleTemp.uuid_category_option === sample.uuid_category_option
        ) {
          sampleTemp.bol_check = evt.target.value;
        }
        return sampleTemp;
      }),
    );
  };

  const handleCheckTemplate = sample => {
    if (sample.target) {
      props.round.bol_template = sample.target.value;
      roundService.updateRound(props.round).then(
        res => {
          if (res.type === 'success' && res.results) {
            props.setRound({
              ...props.round,
              arr_samples: props.round.arr_samples,
            });
          } else {
            window.alert(res.message);
          }
        },
        error => {
          //console.log(error);
        },
      );
    }
  };

  const handleTemplateName = e => {
    props.round.str_template_name = e.target.value;
    roundService.updateRound(props.round).then(
      res => {
        if (res.type === 'success' && res.results) {
          props.setRound({
            ...props.round,
            arr_samples: props.round.arr_samples,
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  const filterObjsInArr = (arr, selection) => {
    const filteredArray = [];
    arr.map(obj => {
      selection.map(filter => {
        if (obj.uuid_category === filter.uuid_category) {
          filteredArray.push(obj);
          return;
        }
      });
    });
    return filteredArray;
  };

  const handleChangeFilter = evt => {
    const value = evt.target.value;
    setFilter({
      [evt.target.name]: value,
    });
  };

  const handleSetSamples = arr_samples => {
    setSamplesRound([...arr_samples]);
  };

  useEffect(() => {
    if (filter.arr_categories_samples.length) {
      setFilteredSamplesRound(
        filterObjsInArr(samplesRound, filter.arr_categories_samples),
      );
    }
  }, [filter, samplesRound]);

  const headers = [
    { description: t('common_sample'), field: 'str_name_category_sample' },
    { description: t('common_category_sample'), field: 'str_name_category' },
  ];

  const SortableItem = SortableElement(({ value }) => (
    <EditRoundCategoriesSample
      state={props.state}
      sample={value}
      round={props.round}
      brands={brands}
      testTypes={testTypeOpts}
      setRound={props.setRound}
      setSamples={handleSetSamples}
      onRemove={handleRemoveSample}
      productionLineOpts={productionLineOpts}
    />
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className={editRoundSamplesStyle.sortableListEditRoundCategories}>
        {items.map((value, index) => (
          <SortableItem key={`sample-${index}`} index={index} value={value} />
        ))}
      </div>
    );
  });

  const handleDownloadTemplate = () => {
    setDownloadFlag(true);
    reportService
      .generateTemplateSamplesOkNok({ privileges: props.privilege })
      .then(
        res => {
          if (res.status === 'success' && res.file) {
            setDownloadFlag(false);
            let a = document.createElement('a');
            a.href = configs.file_api_url + '/v1/' + res.file;
            a.download = res.file;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            window.alert(res.message);
          }
        },
        error => {
          //console.log(error);
        },
      );
  };

  const handleUpload = (name, file) => {
    setLoading(true);
    appService.uploadSampleOkNok(props.round.uuid_round, file).then(
      res => {
        window.alert(res.message);
        setLoading(false);
        refreshState();
      },
      e => {
        //console.log(e);
        window.alert(e.message);
        setLoading(false);
      },
    );
  };

  return (
    <div className={editRoundSamplesStyle.droppableCard}>
      {props.round && samplesRound ? (
        <div>
          <GenericTable
            arrHeader={headers}
            arrRow={samples}
            searchable={true}
            onCheck={handleCheckSample}
            loading={loading}
            rowsPage={6}
            buttonList={[
              {
                onClick: () => {
                  setShowModal(!showModal);
                },
                label: t('common_import'),
              },
            ]}
            handleAddSample={handleAddSample}
          />
          <CardRow>
            <CardColumn maxWidth={350}>
              <DropdownMultiField
                name="arr_categories_samples"
                options={categories ? categories : []}
                valueField="uuid_category"
                margin="0px"
                labelField="str_name_category"
                label={t('categories_list_categories')}
                value={filter.arr_categories_samples}
                onChange={value => {
                  handleChangeFilter(value);
                }}
              />
            </CardColumn>
            <CardColumn maxWidth={350}>
              <div className={editRoundSamplesStyle.box}>
                <Checkbox
                  name="bol_template"
                  value={
                    props.round.bol_template === 'Template' ||
                    props.round.bol_template === 'Yes' ||
                    props.round.bol_template === 1
                      ? true
                      : false
                  }
                  onChange={evt => {
                    handleCheckTemplate(evt);
                  }}
                />
                <TextField
                  disabled={
                    !props.round.bol_template ||
                    props.round.bol_template === 'No'
                  }
                  placeholder={t('common_name_template')}
                  style={{ minHeight: '40px' }}
                  label={t('common_template')}
                  value={templateName}
                  onBlur={e => {
                    handleTemplateName(e);
                  }}
                  onChange={e => {
                    setTemplateName(e.target.value);
                  }}
                ></TextField>
              </div>
            </CardColumn>
          </CardRow>

          <Modal className={editRoundSamplesStyle.Modal} show={showModal}>
            <CardRow justifyContent="flex-end">
              <Button
                onClick={() => {
                  setShowModal(!showModal);
                }}
                className={editRoundSamplesStyle.btnClose}
              >
                <X />
              </Button>
            </CardRow>

            <CardRow itemAlign="center" justifyContent="center" padding="10px">
              <UploadButton
                name="imp_template"
                label={t('sample_upload_document')}
                uploadFunc={handleUpload}
                allowExtension={['.xlsx']}
              />
              <HelpCircle
                data-tip={t('sample_import_message')}
                className={editRoundSamplesStyle.helpCircle}
              />
              <ReactTooltip
                effect="solid"
                place="top"
                type="warning"
                className={editRoundSamplesStyle.tooltip}
              />
            </CardRow>

            <CardRow justifyContent="flex-end">
              {!downloadFlag ? (
                <Button
                  className={editRoundSamplesStyle.buttonLink}
                  label={t('common_download_template')}
                  transparent
                  onClick={() => {
                    handleDownloadTemplate();
                  }}
                ></Button>
              ) : (
                <Loading
                  loading={true}
                  style={{ margin: '0px 5px 5px 0px' }}
                ></Loading>
              )}
            </CardRow>
          </Modal>

          {loadingRoundSample ? (
            <Spinner />
          ) : (
            <SortableList
              distance={5}
              items={
                filter.arr_categories_samples.length > 0
                  ? filteredSamplesRound
                  : samplesRound
              }
              axis={'xy'}
              onSortEnd={handleDropSample}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

EditRoundCategories.propTypes = {
  privilege: PropTypes.any,
  round: PropTypes.any,
  setRound: PropTypes.any,
};

export default EditRoundCategories;

