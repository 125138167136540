import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import listTastingStyle from './listTasting.module.scss';
import {
  CardRound,
  DatePickerField,
  DropdownField,
  CardColumn,
  CardRow,
  Loading,
  Tab,
  Button,
  GenericTable,
  Label,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import RoundAppService from '../../services/rounds/roundApp.service';
import RoundService from '../../services/rounds/round.service';
import { useTasting } from '../../../contexts/Tasting';
import Moment from 'moment';
import { timeRemaining } from '../../../utils/timeRemaining';
import { useUser } from '../../../contexts/User';
import UserService from '../../services/users/user.service';
import uuid from 'react-uuid';

const ListTasting = () => {
  const { t } = useTranslation();
  const { user, handleUser } = useUser();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});

  const [roundTypes, setRoundTypes] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [roundsOrg, setRoundsOrg] = useState([]);
  const roundAppService = new RoundAppService();
  const roundService = new RoundService();
  const { loadTaste } = useTasting();
  const dat_start = Moment(new Date()).format('YYYY-MM-DD');
  const [showAbsenceList, setShowAbsenceList] = useState(false);
  const userService = new UserService();
  const [absenceReasons, setAbsenceReasons] = useState([]);
  const timeNow = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  const [absenceObj, setAbsenceObj] = useState({});
  const [editPermission, setEditPermission] = useState(0);

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);

    setFilter({
      ...filter,
      dat_start,
    });

    roundService
      .listRoundTypes()
      .then(res => {
        setRoundTypes(res.results);
      })
      .catch(error => {
        console.log(error);
      });

    userService
      .listAbsenceReason()
      .then(res => {
        setAbsenceReasons(res.results);
      })
      .catch(error => {
        console.log(error);
      });

    roundAppService.listRounds().then(res => {
      const list = res.results;
      handleFilterRounds(
        list,
        filter.dat_start ? filter.dat_start : dat_start,
        filter.uuid_round_types,
      );
      setRoundsOrg(list);
      setLoading(false);
      handleUser({
        ...user,
        roundList: list,
      });
    });
  };

  const handleFilterRounds = (row, startDate, tesType) => {
    const newRounds = row.filter(item => {
      const flag = tesType ? tesType === item.uuid_round_type : true;
      return item?.dat_start?.indexOf(startDate) > -1 && flag;
    });
    setRounds([...newRounds]);
  };

  const handleChange = evt => {
    const value = evt.target.value;
    const name = evt.target.name;
    if (name === 'dat_start' || name === 'uuid_round_types') {
      const startDate = name === 'dat_start' ? value : filter.dat_start;
      const testType =
        name === 'uuid_round_types' ? value : filter.uuid_round_types;
      setFilter({
        ...filter,
        [evt.target.name]: value,
        int_round: '',
      });
      handleFilterRounds(roundsOrg, startDate, testType);
      return;
    }
    setFilter({
      ...filter,
      [evt.target.name]: value,
    });
  };

  const handleChangeAbsence = evt => {
    const value = evt.target.value;
    const name = evt.target.name;
    if (evt.target.name === 'dat_finish' && evt.target.value) {
      if (new Date(value) < new Date(absenceObj.str_dat_start)) {
        alert(t('round_schedule_start_finish_date'));
        setAbsenceObj({
          ...absenceObj,
          dat_finish: '',
        });
        return false;
      }
    }
    if (evt.target.name === 'str_dat_start' && evt.target.value) {
      if (new Date(value) > new Date(absenceObj.dat_finish)) {
        alert(t('round_schedule_start_finish_date'));
        setAbsenceObj({
          ...absenceObj,
          str_dat_start: '',
        });
        return false;
      }
    }

    setAbsenceObj({
      ...absenceObj,
      [name]: value,
    });
  };

  const handleChangeFilter = val => {
    const value = val;
    setFilter({
      ...filter,
      str_status: value,
    });
  };

  const handleFilter = () => {
    const datStartFilter = filter.dat_start ? filter.dat_start : null;

    if (!rounds) {
      return [];
    }

    const returnedArray = rounds.filter(round => {
      let datStart = round.dat_start ? round.dat_start.toString() : '';
      datStart = datStart.includes('-')
        ? datStart.substring(0, 10).split('-')
        : datStart;

      return (
        //STATUS FILTER
        (filter.str_status && filter.str_status !== ''
          ? (filter.str_status === 'not_started'
              ? !round.bol_started && !round.bol_finished
              : true) &&
            (filter.str_status === 'in_progress'
              ? round.bol_started && !round.bol_finished
              : true) &&
            (filter.str_status === 'finished' ? round.bol_finished : true)
          : true) &&
        //DATE FILTER
        //(filter.dat_start ? datStartFilter.toISOString().slice(0, 10) === datStart.toISOString().slice(0, 10) : true) &&
        (filter.dat_start
          ? `${Moment(datStartFilter).format('YYYY-MM-DD')}` ===
            `${datStart[0]}-${datStart[1]}-${datStart[2]}`
          : true) &&
        //DATE FILTER
        !round.dat_finish_real &&
        //ROUND FILTER
        (filter.int_round
          ? round.uuid_round
              .toString()
              .trim()
              .indexOf(filter.int_round.toString().trim()) !== -1
          : true) &&
        //ROUND TYPE
        (filter.uuid_round_types
          ? round.uuid_round_type
              .toString()
              .trim()
              .indexOf(filter.uuid_round_types.toString().trim()) !== -1
          : true)
      );
    });

    return returnedArray
      .map(el => {
        return {
          ...el,
          roundOrder:
            el.int_round.split('-')[0] === 'F10'
              ? el.int_round.split('-')[3]
              : el.int_round.split('-')[2],
        };
      })
      .sort((a, b) => a.roundOrder - b.roundOrder);
  };

  const checkRoundAlreadyVoted = uuid_round => {
    return new Promise((resolve, reject) => {
      roundAppService
        .getRound({ uuid_round: uuid_round })
        .then(res => {
          if (res.results && res.results[0]?.bol_finished) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(err => {
          reject(false);
        });
    });
  };

  const handleClickRound = async roundClicked => {
    if (
      !roundClicked.bol_started &&
      !roundClicked.bol_finished &&
      roundClicked.uuid_round_type !== '02095a80-6cc4-11ec-8d68-000000000000' &&
      roundClicked.uuid_round_type !== '0d55fca0-e7a2-11ec-a8bc-000000000000' &&
      roundClicked.uuid_round_type !== 'e4454b00-6950-11ed-bb18-000000000000'
    ) {
      await roundAppService.updateStart(roundClicked, timeNow);
    }
    if (roundClicked.bol_is_countdown && !roundClicked.bol_started) {
      const num = timeRemaining(roundClicked.dat_finish);
      if (num <= 300 && num > 0) {
        alert(t('less_than_five_minutes'));
        return;
      } else if (num <= 0) {
        alert(t('the_countdown_to_the_evaluation_has_ended'));
        return;
      }
    }
    const roundVoted = await checkRoundAlreadyVoted(roundClicked.uuid_round);
    if (roundVoted) {
      loadTaste(roundClicked).then(res => {
        if (
          roundClicked.uuid_round_type ===
          'be229b18-2eb4-11eb-ba1d-0229531a6f14'
        ) {
          history.push({
            pathname: `/tasting_discussion_ok_nok`,
            state: { round: roundClicked, bol_score: res.results.bol_score },
          });
          return;
        } else if (
          roundClicked.uuid_round_type ===
          '02095a80-6cc4-11ec-8d68-000000000000'
        ) {
          history.push({
            pathname: `/tasting_discussion_threshold/${roundClicked.uuid_round}`,
            state: { round: roundClicked },
          });
          return;
        } else if (
          roundClicked.uuid_round_type ===
          '0d55fca0-e7a2-11ec-a8bc-000000000000'
        ) {
          history.push({
            pathname: `/tasting_flavor_session`,
            state: { round: roundClicked },
          });
          return;
        } else if (
          roundClicked.uuid_round_type ===
          'e4454b00-6950-11ed-bb18-000000000000'
        ) {
          history.push({
            pathname: `/tasting_discussion_triangle/${roundClicked.uuid_round}`,
            state: { round: roundClicked },
          });
          return;
        } else {
          history.push({
            pathname: `/tasting_discussion`,
            state: { round: roundClicked },
          });
          return;
        }
      });
      return false;
    }
    setLoading(true);
    if (loading) return;
    loadTaste(roundClicked).then(res => {
      if (
        roundClicked.uuid_round_type === 'be229b18-2eb4-11eb-ba1d-0229531a6f14'
      ) {
        if (roundClicked.bol_finished) {
          history.push({
            pathname: `/tasting_discussion_ok_nok`,
            state: { round: roundClicked, bol_score: res.results.bol_score },
          });
          return;
        }
        history.push({
          pathname: `/tasting_ok_nok`,
          state: { round: roundClicked, bol_score: res.results.bol_score },
        });
        return;
      }

      if (
        roundClicked.uuid_round_type !==
          'be229b18-2eb4-11eb-ba1d-0229531a6f14' &&
        roundClicked.uuid_round_type !==
          '02095a80-6cc4-11ec-8d68-000000000000' &&
        roundClicked.uuid_round_type !==
          '0d55fca0-e7a2-11ec-a8bc-000000000000' &&
        roundClicked.uuid_round_type !==
          'e4454b00-6950-11ed-bb18-000000000000' &&
        roundClicked.bol_finished
      ) {
        history.push({
          pathname: `/tasting_discussion`,
          state: { round: roundClicked },
        });
        return;
      }

      if (
        roundClicked.uuid_round_type !==
          'be229b18-2eb4-11eb-ba1d-0229531a6f14' &&
        roundClicked.uuid_round_type !==
          '02095a80-6cc4-11ec-8d68-000000000000' &&
        roundClicked.uuid_round_type !==
          '0d55fca0-e7a2-11ec-a8bc-000000000000' &&
        roundClicked.uuid_round_type !==
          'e4454b00-6950-11ed-bb18-000000000000' &&
        roundClicked.bol_started &&
        !roundClicked.bol_finished
      ) {
        history.push({
          pathname: `/tasting_review`,
          state: { round: roundClicked },
        });
        return false;
      }

      if (
        roundClicked.uuid_round_type === '0d55fca0-e7a2-11ec-a8bc-000000000000'
      ) {
        if (roundClicked.bol_is_countdown) {
          const num = timeRemaining(roundClicked.dat_finish);
          if (roundClicked.bol_started) {
            roundAppService
              .getFlavorCode({
                uuid_round: roundClicked.uuid_round,
              })
              .then(res => {
                if (
                  res.type === 'success' &&
                  res.results[0].round_sp_se_flavors_identification_current_code
                ) {
                  history.push({
                    pathname: `/tasting_flavor_session`,
                    state: { round: roundClicked },
                  });
                } else {
                  if (num <= 0 && !roundClicked.bol_finished) {
                    alert(t('the_countdown_to_the_evaluation_has_ended'));
                    setLoading(false);
                    return;
                  }
                  history.push({
                    pathname: `/code_flavor`,
                    state: { round: roundClicked },
                  });
                }
              });
          } else {
            if (num <= 0) {
              alert(t('the_countdown_to_the_evaluation_has_ended'));
              setLoading(false);
              return;
            }
            history.push({
              pathname: `/code_flavor`,
              state: { round: roundClicked },
            });
          }
        } else {
          if (roundClicked.bol_started) {
            roundAppService
              .getFlavorCode({
                uuid_round: roundClicked.uuid_round,
              })
              .then(res => {
                if (
                  res.type === 'success' &&
                  res.results[0].round_sp_se_flavors_identification_current_code
                ) {
                  history.push({
                    pathname: `/tasting_flavor_session`,
                    state: { round: roundClicked },
                  });
                } else {
                  sessionStorage.removeItem('bol_finished');
                  history.push({
                    pathname: `/code_flavor`,
                    state: { round: roundClicked },
                  });
                }
              });
          } else {
            history.push({
              pathname: `/code_flavor`,
              state: { round: roundClicked },
            });
          }
        }
        return;
      }

      if (
        roundClicked.uuid_round_type === '02095a80-6cc4-11ec-8d68-000000000000'
      ) {
        if (roundClicked.bol_is_countdown) {
          const num = timeRemaining(roundClicked.dat_finish);
          if (roundClicked.bol_started) {
            if (roundClicked.bol_finished) {
              history.push({
                pathname: `/tasting_discussion_threshold/${roundClicked.uuid_round}`,
                state: { round: roundClicked },
              });
              return;
            }
            history.push({
              pathname: `/tasting_threshold`,
              state: { round: roundClicked },
            });
            return;
          } else {
            if (num > 0 && num < 5) {
              history.push('/');
              alert(t('less_than_five_minutes'));
              return;
            }
            if (num < 0) {
              history.push('/');
              alert(t('the_countdown_to_the_evaluation_has_ended'));
              return;
            }
            history.push({
              pathname: `/tasting_threshold`,
              state: { round: roundClicked },
            });
            return;
          }
        } else {
          if (roundClicked.bol_finished) {
            history.push({
              pathname: `/tasting_discussion_threshold/${roundClicked.uuid_round}`,
              state: { round: roundClicked },
            });
            return;
          }
          history.push({
            pathname: `/tasting_threshold`,
            state: { round: roundClicked },
          });
          return;
        }
      }
      if (
        roundClicked.uuid_round_type === 'e4454b00-6950-11ed-bb18-000000000000'
      ) {
        if (roundClicked.bol_is_countdown) {
          const num = timeRemaining(roundClicked.dat_finish);
          if (roundClicked.bol_started) {
            if (roundClicked.bol_finished) {
              history.push({
                pathname: `/tasting_discussion_triangle/${roundClicked.uuid_round}`,
                state: { round: roundClicked },
              });
              return;
            }
            history.push({
              pathname: `/tasting_triangle`,
              state: { round: roundClicked },
            });
            return;
          } else {
            if (num > 0 && num < 5) {
              history.push('/');
              alert(t('less_than_five_minutes'));
              return;
            }
            if (num < 0) {
              history.push('/');
              alert(t('the_countdown_to_the_evaluation_has_ended'));
              return;
            }
            history.push({
              pathname: `/tasting_triangle`,
              state: { round: roundClicked },
            });
            return;
          }
        } else {
          if (roundClicked.bol_finished) {
            history.push({
              pathname: `/tasting_discussion_triangle/${roundClicked.uuid_round}`,
              state: { round: roundClicked },
            });
            return;
          }
          history.push({
            pathname: `/tasting_triangle`,
            state: { round: roundClicked },
          });
          return;
        }
      }
      history.push({
        pathname: `/tasting_samples`,
        state: { round: roundClicked },
      });
      setLoading(false);
    });
  };

  const handleType = val => {
    switch (val) {
      case t('common_start'):
        return 2;
      case t('common_all'):
      default:
        return 1;
    }
  };

  const tabs = [
    {
      index: 1,
      description: t('common_all'),
      component: () => (
        <CardRow transparent flexWrap="wrap" style={{ flexGrow: 1 }}>
          {handleFilter().map((round, i) => (
            <CardRound
              key={round.uuid_round}
              round={round}
              handleClick={() => handleClickRound(round)}
            />
          ))}
        </CardRow>
      ),
      backgroundColor: '#ebebeb',
      fontColor: '#ff8f4a',
      onClick: () => handleChangeFilter(''),
    },
    {
      index: 2,
      description: t('common_start'),
      component: () => (
        <CardRow transparent flexWrap="wrap" style={{ flexGrow: 1 }}>
          {handleFilter().map((round, i) => (
            <CardRound
              key={round.uuid_round}
              round={round}
              handleClick={() => handleClickRound(round)}
            />
          ))}
        </CardRow>
      ),
      backgroundColor: '#a2a2a3',
      fontColor: '#fff',
      onClick: () => handleChangeFilter('not_started'),
    },
    {
      index: 3,
      description: t('common_continue'),
      component: () => (
        <CardRow transparent flexWrap="wrap" style={{ flexGrow: 1 }}>
          {handleFilter().map((round, i) => (
            <CardRound
              key={round.uuid_round}
              round={round}
              handleClick={() => handleClickRound(round)}
            />
          ))}
        </CardRow>
      ),
      backgroundColor: '#ff9600',
      fontColor: '#fff',
      onClick: () => handleChangeFilter('in_progress'),
    },
    {
      index: 4,
      description: t('common_discussion'),
      component: () => (
        <CardRow transparent flexWrap="wrap" style={{ flexGrow: 1 }}>
          {handleFilter().map((round, i) => (
            <CardRound
              key={round.uuid_round}
              round={round}
              handleClick={() => handleClickRound(round)}
            />
          ))}
        </CardRow>
      ),
      backgroundColor: '#87c700',
      fontColor: '#fff',
      onClick: () => handleChangeFilter('finished'),
    },
  ];

  const headers = [
    {
      description: t('start_date'),
      field: 'dat_start',
    },
    {
      description: t('end_date'),
      field: 'dat_finish',
    },
    {
      description: t('Reason'),
      field: 'str_name_reason',
    },
  ];

  const handleRemoveAbsence = values => {
    return new Promise((resolve, reject) => {
      if (
        window.confirm(
          t('Remove_Absence') + values.dat_start + '/' + values.dat_finish,
        )
      ) {
        userService.delAbsenceReason(values.uuid_user_absence).then(
          res => {
            if (res.type === 'success' && res.results) {
              resolve(true);
            } else {
              window.alert(res.message);
              resolve(true);
            }
          },
          error => {
            //console.log(error);
            reject(error);
          },
        );
      }
    });
  };

  const handleSaveAbsence = async () => {
    if (loading) return;
    if (
      absenceObj.str_dat_start === undefined ||
      absenceObj.str_dat_start === null ||
      absenceObj.str_dat_start === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('start_date')}` }),
      );
      return;
    }
    if (
      absenceObj.dat_finish === undefined ||
      absenceObj.dat_finish === null ||
      absenceObj.dat_finish === ''
    ) {
      window.alert(t('common_cannot_be_empty', { field: `${t('end_date')}` }));
      return;
    }
    if (
      absenceObj.uuid_absence_reason === undefined ||
      absenceObj.uuid_absence_reason === null ||
      absenceObj.uuid_absence_reason === ''
    ) {
      window.alert(
        t('common_cannot_be_empty', { field: `${t('absence_reason')}` }),
      );
      return;
    }
    setLoading(true);
    let data = {
      ...absenceObj,
      uuid_user_absence: uuid(),
      dat_start: absenceObj.str_dat_start,
      dat_finish: absenceObj.dat_finish,
      uuid_absence_reason: absenceObj.uuid_absence_reason,
    };
    const res = await userService.saveAbsenceReason(data);
    if (res.results[0].sp_in_up_user_absence) {
      setEditPermission(prev => {
        return prev + 1;
      });
      setAbsenceObj({
        str_dat_start: '',
        dat_finish: '',
        uuid_absence_reason: '',
      });
      setLoading(false);
    } else {
      window.alert(t('You_have_duplicate_absence_dates'));
      setLoading(false);
    }
  };

  return (
    <div className={listTastingStyle.pageContainer}>
      <CardRow flexWrap="wrap">
        <CardColumn padding="5px">
          <DatePickerField
            name="dat_start"
            type={'date'}
            label={t('common_start_date') + ':'}
            value={filter.dat_start}
            format="dd-mm-yyyy"
            onChange={handleChange}
          />
        </CardColumn>
        <CardColumn>
          <DropdownField
            name="uuid_round_types"
            options={roundTypes}
            valueField="uuid_round_types"
            labelField="str_name"
            margin="-5px 0px 0px 0px"
            value={filter.uuid_round_types}
            label={t('common_round_type')}
            onChange={handleChange}
            bolOrder={false}
          />
        </CardColumn>

        <CardColumn>
          <DropdownField
            name="int_round"
            options={rounds}
            valueField="uuid_round"
            labelField="int_round"
            margin="-5px 0px 0px 0px"
            value={filter.int_round}
            label={t('common_round')}
            onChange={handleChange}
          />
        </CardColumn>
      </CardRow>
      <div style={{ textAlign: 'right', height: '20px' }}>
        <Button
          zIndex={0}
          confirm
          label={t('Add_Absence_Reason')}
          onClick={() => setShowAbsenceList(true)}
          style={{
            whiteSpace: 'nowrap',
            width: 'auto',
            minWidth: '120px',
            padding: '10px',
            marginRight: '10px',
          }}
        />
      </div>
      {showAbsenceList && (
        <div
          className={listTastingStyle.showListModal}
          style={{ height: '100%' }}
        >
          <div className={listTastingStyle.showListBox}>
            <div className={listTastingStyle.closeButton}>
              <h2 className={listTastingStyle.title}>{t('Absence_Title')}</h2>
              <Button
                label="x"
                round
                style={{
                  display: 'flex',
                  width: '30px',
                  fontSize: '18px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '5px',
                }}
                onClick={() => {
                  setShowAbsenceList(false);
                  setAbsenceObj({
                    str_dat_start: '',
                    dat_finish: '',
                    uuid_absence_reason: '',
                  });
                }}
              />
            </div>
            <CardRow flexWrap="wrap">
              <DatePickerField
                name="str_dat_start"
                type={'date'}
                label={t('start_date')}
                margin="10px 10px 0px 10px"
                value={absenceObj.str_dat_start}
                onChange={handleChangeAbsence}
                isRequired={true}
              />
              <DatePickerField
                name="dat_finish"
                type={'date'}
                label={t('end_date')}
                margin="10px 10px 0px 0px"
                value={absenceObj.dat_finish}
                onChange={handleChangeAbsence}
                isRequired={true}
              />
              <DropdownField
                options={absenceReasons}
                name="uuid_absence_reason"
                labelField="str_name"
                valueField="uuid_absence_reason"
                value={absenceObj.uuid_absence_reason}
                margin="10px 10px 0px 0px"
                label={t('Reason')}
                onChange={handleChangeAbsence}
                hideRemoveButton
                isRequired={true}
                bolOrder={false}
                hidedropdownOuterList
              />
            </CardRow>
            <CardRow padding="0" justifyContent={'center'}>
              <Button
                label={t('common_cancel')}
                onClick={() => {
                  setShowAbsenceList(false);
                  setAbsenceObj({
                    str_dat_start: '',
                    dat_finish: '',
                    uuid_absence_reason: '',
                  });
                }}
                neutral
                margin="10px 10px 0px 0px"
                style={{
                  whiteSpace: 'nowrap',
                  width: 'auto',
                  minWidth: '120px',
                  padding: '10px',
                }}
              />
              <Button
                zIndex={1}
                confirm
                label={t('common_save')}
                onClick={handleSaveAbsence}
                margin="10px 0px 0px 0px"
                style={{
                  whiteSpace: 'nowrap',
                  width: 'auto',
                  minWidth: '120px',
                  padding: '10px',
                }}
              />
            </CardRow>
            <GenericTable
              arrHeader={headers}
              dataServer={userService.getAbsenceReason}
              searchable={true}
              onRemove={handleRemoveAbsence}
              loading={loading}
              hidedropdownOuterList={true}
              editPermission={editPermission}
            />
          </div>
        </div>
      )}
      <Loading loading={loading} />
      {!loading && (
        <Tab
          tabs={tabs}
          selectedTabIndex={handleType()}
          handleType={handleType}
          justifyContent="left"
          marginTop="10px"
        />
      )}
    </div>
  );
};

export default ListTasting;
