import React, { useState } from 'react';
import { useTasting } from '../../../contexts/Tasting';
import SampleAppService from '../../services/samples/sampleApp.service';
import RoundAppService from '../../services/rounds/roundApp.service';
import { useHistory } from 'react-router-dom';
import listReviews from './listreviews.module.scss';
import { useTranslation } from 'react-i18next';
import { IoEmit } from '../../../utils/socketIo';
import moment from 'moment';

import { Text } from '../../../components';
import { useUser } from '../../../contexts/User';

const useListReviews = () => {
  const [showWarningEmptySamples, setShowWarningEmptySamples] = useState(null);
  const [showWarningTieSamples, setShowWarningTieSamples] = useState(false);

  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [round, setRound] = useState({});
  const { user } = useUser();

  const { t } = useTranslation();
  const {
    taste,
    handleSample,
    handleTasteSamples,
    updateUserRankAndScoreSample,
    updateFinishRound,
  } = useTasting();
  const samples = taste.arr_samples;

  const sampleAppService = new SampleAppService();
  const roundAppService = new RoundAppService();
  const history = useHistory();

  const getNumberOfOptions = score => {
    let totalOptions = 0;

    samples.forEach(sample => {
      if (parseFloat(sample.flt_score) === score) {
        totalOptions += 1;
      }
    });

    return totalOptions;
  };

  const getLowerRank = (samples, index) => {
    const orderedList = [...samples].sort((a, b) => {
      const keyA = parseFloat(a.flt_score);
      const keyB = parseFloat(b.flt_score);

      if (isFinite(keyB - keyA)) {
        return keyB - keyA;
      } else {
        return isFinite(keyA) ? -1 : 1;
      }
    });

    return (
      orderedList.findIndex(item => parseFloat(item.flt_score) === index) + 1
    );
  };

  const getOptions = sample => {
    const totalOptions = getNumberOfOptions(parseFloat(sample.flt_score));
    const lowerRank = getLowerRank(samples, parseFloat(sample.flt_score));

    return Array.from(Array(totalOptions).keys()).map((item, index) => {
      if (index > 0) {
        return +lowerRank + +index;
      }
      return +lowerRank;
    });
  };

  const sortSamples = list => {
    const newList = list.sort((a, b) => {
      const keyA = a.int_order_app;
      const keyB = b.int_order_app;

      if (keyA < keyB) return -1;
      if (keyB > keyA) return 1;
      return 0;
    });

    return newList;
  };

  const handleClickSample = async (sample, round) => {
    await handleSample(sample);
    history.push({
      pathname: `/tasting_voting`,
      state: { round },
    });
  };

  const handleValidateForm = rounds => {
    roundAppService.getRound({ uuid_round: round.uuid_round }).then(res => {
      if (res.results && res.results[0]?.bol_finished) {
        history.push({
          pathname: `/tasting_discussion`,
          state: { round: rounds },
        });
      } else {
        roundAppService.validateSamplesEmpty(samples).then(res => {
          if (res && showWarningEmptySamples == null) {
            setShowWarningEmptySamples(true);
          } else {
            if (round.bol_ranked === 1) {
              roundAppService.validateRoundTie(samples).then(res => {
                if (!res) {
                  setShowWarningTieSamples(true);
                } else {
                  setModalShow(true);
                }
              });
            } else {
              setModalShow(true);
            }
          }
        });
      }
    });
  };

  const handleSubmit = rounds => {
    const timeNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    setLoading(true);
    if (!loading) {
      roundAppService.updateFinish(round, timeNow).then(
        res => {
          updateFinishRound();
          if (res.type === 'success') {
            IoEmit('finishRound', {
              ...rounds,
              user_name: user.str_name,
            });
            history.push({
              pathname: `/tasting_discussion`,
              state: { round: rounds },
            });
            setLoading(false);
          } else {
            window.alert(res.message);
            setLoading(false);
          }
        },
        error => {
          setLoading(false);
        },
      );
    }
  };

  const handleValidateRank = async (sample, rank) => {
    return new Promise((resolve, reject) => {
      if (round.bol_ranked != 0) {
        setLoading(true);

        handleSubmitRank(sample, rank);
        const newTasteSamples = [...taste.arr_samples].map(currentSample => {
          if (currentSample.uuid_sample === sample.uuid_sample) {
            return {
              ...currentSample,
              int_rank: rank !== null ? parseFloat(rank) : null,
            };
          } else {
            return currentSample;
          }
        });
        handleTasteSamples(newTasteSamples);
        setLoading(false);

        resolve(true);
      }
    });
  };

  const handleSubmitRank = async (sample, rank) => {
    setLoading(true);

    sampleAppService
      .updateRank(
        sample.uuid_round,
        sample.uuid_sample,
        rank && rank != '' ? +rank : null,
      )
      .then(
        res => {
          if (res.type === 'success') {
            setLoading(false);
          } else {
            window.alert(res.message);
            setLoading(false);
          }
        },
        error => {
          setLoading(false);
        },
      );
  };

  const handleComments = comments => {
    if (!comments || !comments?.length) {
      return (
        <Text className={listReviews.noComments}>
          {t('common_no_comments')}
        </Text>
      );
    }

    return comments.map((characteristic, i) => (
      <div key={'characteristic_' + i} className={listReviews.itemCard}>
        <Text className={listReviews.textComment}>
          {' '}
          {characteristic.str_degree ? characteristic.str_degree + ' - ' : ''}
          {characteristic.str_comment}
        </Text>
      </div>
    ));
  };

  const handleAutoRank = async samples_list => {
    const onlyScores = [...samples_list].map(item =>
      item.flt_score ? parseFloat(item.flt_score) : null,
    );

    const orderedList = [...samples_list].sort((a, b) => {
      const keyA = a.int_rank;
      const keyB = b.int_rank;

      if (!keyA) return 1;
      if (keyA < keyB) return -1;
      if (keyB > keyA) return 1;
      return 0;
    });

    const orderedScore = [...onlyScores].sort((a, b) => {
      if (isFinite(b - a)) {
        return b - a;
      } else {
        return isFinite(a) ? -1 : 1;
      }
    });

    const withRank = [...orderedList].map(item => {
      return {
        ...item,
        int_rank:
          item.flt_score && !item.int_rank
            ? orderedScore.indexOf(item.flt_score) + 1
            : item.flt_score
            ? +item.int_rank
            : null,
      };
    });

    sortSamples([...withRank]).forEach(async (rankedSample, index) => {
      await handleSubmitRank(
        rankedSample,
        rankedSample.int_rank !== null
          ? +rankedSample.int_rank
          : rankedSample.flt_score
          ? index + 1
          : null,
      );
    });

    handleTasteSamples(sortSamples([...withRank]));
    return sortSamples([...withRank]);
  };

  const handleCleanRanks = async samples_list => {
    const cleanedSamples = [...samples_list].map(item => {
      return { ...item, int_rank: null };
    });

    sortSamples([...cleanedSamples]).forEach(async (rankedSample, index) => {
      await handleSubmitRank(rankedSample, rankedSample.int_rank);
    });
    handleTasteSamples(sortSamples([...cleanedSamples]));
  };

  return {
    showWarningEmptySamples,
    setShowWarningEmptySamples,
    showWarningTieSamples,
    setShowWarningTieSamples,
    samples,
    handleTasteSamples,
    loading,
    setLoading,
    modalShow,
    setModalShow,
    round,
    setRound,
    taste,
    handleSample,
    updateUserRankAndScoreSample,
    updateFinishRound,
    history,
    t,
    handleAutoRank,
    getOptions,
    sortSamples,
    handleClickSample,
    handleValidateForm,
    handleSubmit,
    handleValidateRank,
    handleComments,
    handleCleanRanks,
  };
};

export default useListReviews;

