import React, { useEffect, useState } from 'react';
import editSampleProductionStyle from './editsampleproduction.module.scss';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Card,
  CardRow,
  DropdownField,
  Button,
  DatePickerField,
  TextArea,
  UploadButton,
  CardDocument,
  DropdownMultiField,
} from '../../../components';
import SampleService from '../../services/samples/sample.service';
import AppService from '../../../app.service';
import ReportService from '../../services/reports/report.service';
import { useTranslation } from 'react-i18next';
import { HelpCircle } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import configs from '../../../configs';
import _ from 'lodash';
import Moment from 'moment';
import { useUser } from '../../../contexts/User';
const NO_KPI = 'nokpi';
const arr = [];
const deleteFiles = [];

const EditSampleProduction = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const sampleService = new SampleService();
  const appService = new AppService();
  const reportService = new ReportService();
  const now = new Date();
  const [disabled, setDisabled] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [datExp, setDatExp] = useState(null);
  const [datProd, setDatProd] = useState(null);
  const [isKpiBrand, setIsKpiBrand] = useState();
  const [isLoadingKPI, setIsLoadingKPI] = useState(false);
  const [submissionType, setSubmissionType] = useState([]);
  const [subType, setSubType] = useState({});
  const { user } = useUser();
  const [zone] = useState(user.uuid_zone);
  const [country] = useState(user.uuid_country);
  const [site] = useState(user.uuid_site);
  const [isSelectedKPIMonth, setIsSelectedKPIMonth] = useState(false);

  useEffect(() => {
    setSubType({
      ...subType,
      uuid_submission_type:
        props.values.uuid_submission_type || props.sample.uuid_sample_type,
      str_submission_type_name:
        props.values.str_submission_type_name ||
        props.sample.str_submission_type_name,
    });
    sampleService.listSubmission().then(res => {
      setSubmissionType(res.results);
    });
    if (props.isNewSample && props.newSampleNum === 0) {
      handleChange({ target: { name: 'uuid_zone', value: zone } });
      handleChange({ target: { name: 'uuid_country', value: country } });
      handleChange({ target: { name: 'uuid_site', value: site } });
      props.setNewSampleNum(1);
    }
  }, []);

  useEffect(() => {
    for (var i = arr.length; i > 0; i--) {
      arr.pop();
    }

    for (var j = deleteFiles.length; j > 0; j--) {
      deleteFiles.pop();
    }
  }, []);

  useEffect(() => {
    if (
      props.sample.dat_expire !== undefined &&
      props.sample.dat_expire !== null &&
      props.sample.dat_expire !== 'Invalid date'
    ) {
      let date = props.sample.dat_expire.toString();

      date = date.substring(0, 10);

      if (props.sample.dat_expire !== 'Invalid date') {
        setDatExp(`${date}`);
      } else {
        setDatExp(null);
      }
    }
  }, [props.sample.dat_expire]);

  useEffect(() => {
    if (
      props.sample.dat_production !== undefined &&
      props.sample.dat_production !== null &&
      props.sample.dat_production !== 'Invalid date'
    ) {
      let date = props.sample.dat_production.toString();

      date = date.substring(0, 10);

      if (props.sample.dat_production !== 'Invalid date') {
        setDatProd(date);
      } else {
        setDatProd(null);
      }
    }
  }, [props.sample.dat_production]);

  function objSort (obj) {
    const newObj = {};
    Object.keys(obj)
      .sort()
      .forEach(key => {
        newObj[key] = obj[key];
      });
    return JSON.stringify(newObj);
  }
  const uniqueArr = arr => {
    const set = new Set();
    for (let i = 0; i < arr.length; i++) {
      let str = objSort(arr[i]);
      set.add(str);
    }
    arr = [...set].map(item => {
      return JSON.parse(item);
    });
    return arr;
  };

  const format = arr => {
    let res = {};
    for (const p of arr) {
      res = { ...res, ...p };
    }
    delete res.uuid_submission_type;
    delete res.uuid_country;
    delete res.uuid_site;
    return res;
  };

  const findValue = () => {
    const site =
      props.privilege.arr_sites && props.sample.uuid_country
        ? props.privilege.arr_sites.filter(site => {
          return site.uuid_country === props.sample.uuid_country;
        })
        : [];
    let originSite = '';
    for (const arrSite of props.privilege.arr_sites) {
      if (arrSite.uuid_site === user.uuid_site) {
        originSite = arrSite;
      }
    }
    const res = [];
    const result = [originSite, { str_sku: hanldeSKUChange() }];
    res.push(
      { dat_kpi: props.sample.dat_kpi },
      ...submissionType,
      ...site,
      { dat_production: props.sample.dat_production },
      { str_batch: props.sample.str_batch },
    );
    for (const item of res) {
      for (const key in props.sample) {
        if (
          item?.uuid_site === props.sample[key] ||
          item?.dat_kpi === props.sample[key] ||
          item?.str_submission_type_name === props.sample[key] ||
          item?.dat_production === props.sample[key] ||
          item?.str_batch === props.sample[key]
        ) {
          result.push(item);
        }
      }
    }
    return format(uniqueArr(result));
  };

  const handleChange = async evt => {
    const { name, value, uuid, uuidName } = evt.target;
    let arrDocs = [];
    let data = '';
    let removeKPI = false;

    if (
      name === 'str_submission_type_name' &&
      uuidName === 'uuid_submission_type'
    ) {
      setSubType({
        str_submission_type_name: value,
        uuid_submission_type: uuid,
      });
    }

    if (
      (name === 'uuid_brand' && !!props.sample.uuid_site) ||
      (name === 'uuid_site' && !!props.sample.uuid_brand)
    ) {
      props.setForIsKpiBrand(false);
      setIsKpiBrand(false);
      setIsLoadingKPI(true);
      await sampleService.isBreweryBrandKPI(props.sample.uuid_site, value).then(
        res => {
          const isAllowToBeKPI = res.results[0].kpi_sp_se_existing_brand_kpi;
          if (!isAllowToBeKPI) {
            removeKPI = true;
          }
          props.setForIsKpiBrand(isAllowToBeKPI);
          setIsKpiBrand(isAllowToBeKPI);
          setIsLoadingKPI(false);
        },
        err => {
          console.log('error calling service', err);
        },
      );
    }

    if (name === 'dat_kpi' && value !== 'nokpi') {
      const value = submissionType.filter(e => {
        return e.str_submission_type_name == 'Monthly Corporate Shipment (MCS)';
      })[0];
      setSubType({
        uuid_submission_type: value.uuid_submission_type,
        str_submission_type_name: value.str_submission_type_name,
      });
    }

    if (name === 'dat_kpi' && value !== 'nokpi' && value !== null) {
      setIsSelectedKPIMonth(true);
    } else if (name === 'dat_kpi' && (value === 'nokpi' || value === null)) {
      setIsSelectedKPIMonth(false);
    }

    if (
      name === 'dat_kpi' &&
      value !== 'nokpi' &&
      props.sample.dat_kpi !== null &&
      props.sample.uuid_brand !== null &&
      props.sample.uuid_site !== null
    ) {
      sampleService
        .listSampleKpi(
          props.sample.uuid_sample,
          props.sample.uuid_site,
          props.sample.uuid_brand,
          value,
        )
        .then(res => {
          if (res.length) {
            alert(t('sample_duplicated_kpi_message'));
          }
        });
    } else if (
      name === 'uuid_brand' &&
      props.sample.dat_kpi !== 'nokpi' &&
      props.sample.dat_kpi !== null &&
      props.sample.uuid_site !== null
    ) {
      sampleService
        .listSampleKpi(
          props.sample.uuid_sample,
          props.sample.uuid_site,
          value,
          props.sample.dat_kpi,
        )
        .then(res => {
          if (res.length) {
            alert(t('sample_duplicated_kpi_message'));
          }
        });
    } else if (
      name === 'uuid_site' &&
      props.sample.dat_kpi !== 'nokpi' &&
      props.sample.dat_kpi !== null &&
      props.sample.uuid_brand !== null
    ) {
      sampleService
        .listSampleKpi(
          props.sample.uuid_sample,
          value,
          props.sample.uuid_brand,
          props.sample.dat_kpi,
        )
        .then(res => {
          if (res.length) {
            alert(t('sample_duplicated_kpi_message'));
          }
        });
    } else if (name === 'str_zip_documents') {
      let dataFilter = _.filter(arr, { str_zip_document: value });
      let dataFilterSample = _.filter(props.sample.str_zip_documents, {
        str_zip_document: value,
      });

      if (dataFilter.length === 0 && dataFilterSample.length === 0) {
        arr.push({ str_zip_document: value, uuid_document: '' });
        arrDocs = _.union(props.sample.str_zip_documents, arr);
      }
    }

    data =
      evt.target.name !== 'str_zip_documents'
        ? { [evt.target.name]: value }
        : { [evt.target.name]: arrDocs };

    setDisabled(false);
    //DISABLE PACKAGE AND VOLUME IF IS PRODUCT TYPE IS CONGRESS WORT
    if (
      name === 'uuid_product_type' &&
      value === 'e0a056e4-05ba-11eb-b946-02be69c96cde'
    ) {
      setDisabled(true);
      data.uuid_volume = null;
      data.uuid_package = null;
    } else if (
      name === 'uuid_product_type' &&
      value !== null &&
      value !== 'e0a056e4-05ba-11eb-b946-02be69c96cde'
    ) {
      data.uuid_malt_varieties = null;
    } else if (name === 'uuid_product_type' && value === null) {
      data.uuid_brand = null;
      data.uuid_product_type = null;
    }
    if (removeKPI) {
      data.dat_kpi = 'nokpi';
    }
    props.setSample(prevState => {
      return { ...prevState, ...data };
    });
  };

  const handleRemove = (evt, index) => {
    deleteFiles.push({ index: index, evt: evt });

    let data = {
      str_zip_documents: props.sample.str_zip_documents.filter(
        (doc, i) => i !== index,
      ),
    };
    for (var i = arr.length; i > 0; i--) {
      arr.pop();
    }
    arr.pop();

    props.setSample(prevState => {
      return { ...prevState, ...data };
    });
  };

  const handleSave = () => {
    const { uuid_submission_type, str_submission_type_name } = subType;
    let sampleTemp = {
      ...props.sample,
      uuid_submission_type: uuid_submission_type,
      str_submission_type_name: str_submission_type_name,
    };

    if (sampleTemp.dat_kpi === NO_KPI || sampleTemp.dat_kpi === null) {
      sampleTemp.dat_kpi = null;
      sampleTemp.bol_kpi = false;
    } else {
      sampleTemp.bol_kpi = true;
    }

    if (!sampleTemp.uuid_site) {
      alert(t('sample_no_site_message'));
      return false;
    }

    if (!sampleTemp.uuid_brand) {
      alert(t('sample_no_brand_message'));
      return false;
    }

    if (!sampleTemp.uuid_product_type) {
      alert(t('sample_no_product_type'));
      return false;
    }

    if (!sampleTemp.dat_production) {
      alert(t('sample_no_production_date_message'));
      return;
    }

    if (
      sampleTemp.dat_production &&
      sampleTemp.dat_expire &&
      new Date(sampleTemp.dat_production) > new Date(sampleTemp.dat_expire)
    ) {
      alert(t('sample_expire_bigger_than_production'));
      return false;
    }

    if (
      !sampleTemp.uuid_volume &&
      sampleTemp.uuid_product_type !== 'e0a056e4-05ba-11eb-b946-02be69c96cde'
    ) {
      alert(t('sample_no_volume_message'));
      return false;
    }

    if (deleteFiles.length > 0) {
      deleteFiles.forEach(file => {
        if (
          file.evt.uuid_document !== undefined &&
          file.evt.uuid_document !== ''
        ) {
          appService.removeDocument(file.evt.uuid_document);
        }
      });
    }

    if (props.sampleType === 'PROJECT') {
      if (!window.confirm(t('sample_project_to_production_confirm_message'))) {
        return false;
      }
    }
    if (sampleTemp.uuid_malt_varieties?.length) {
      sampleTemp.uuid_malt_varieties = sampleTemp.uuid_malt_varieties.map(
        item => {
          return item.uuid_malt_varieties;
        },
      );
    }

    if (sampleTemp.uuid_ship_site?.length) {
      sampleTemp.uuid_sample_send_site = sampleTemp.uuid_ship_site.map(item => {
        return item.uuid_ship_site;
      });
    }

    sampleTemp.str_type = 'PRODUCTION';
    sampleTemp.str_name_project = null;
    sampleTemp.str_trial_identifier = null;
    sampleService
      .listSampleKpi(
        sampleTemp.uuid_sample,
        sampleTemp.uuid_site,
        sampleTemp.uuid_brand,
        sampleTemp.dat_kpi,
      )
      .then(res => {
        if (res.length) {
          alert(t('sample_duplicated_kpi_message'));
          return false;
        } else {
          setDisabledSave(true);
          sampleService.updateSample(sampleTemp).then(
            res => {
              if (res.type === 'success' && res.results) {
                let dataFilter = _.filter(arr, { uuid_document: '' });

                if (dataFilter.length > 0) {
                  const upload = new Promise(async (resolve, reject) => {
                    appService
                      .updateDocument({
                        uuid_sample: sampleTemp.uuid_sample,
                        uuid_round: null,
                        str_zip_document: sampleTemp.str_zip_documents,
                        uuid_submission_type: uuid_submission_type,
                        str_submission_type_name: str_submission_type_name,
                      })
                      .then(
                        res => {
                          resolve(true);
                        },
                        error => {
                          reject(true);
                        },
                      );
                  });

                  upload.then(
                    res => {
                      for (var i = arr.length; i > 0; i--) {
                        arr.pop();
                      }
                      arr.pop();

                      setTimeout(() => {
                        setDisabledSave(false);
                        history.goBack();
                      }, 3000);
                    },
                    error => { },
                  );
                } else {
                  setDisabledSave(false);
                  history.goBack();
                }
              } else {
                window.alert(res.message);
                setDisabledSave(false);
              }
            },
            error => {
              setDisabledSave(false);
            },
          );
        }
      })
      .catch(() => {
        setDisabledSave(false);
        alert(
          'An error occured when validating the sample. If this persists, contact the support.',
        );
      });
  };

  const madatoryFieldValidate = () => {
    const { uuid_submission_type, str_submission_type_name } = subType;
    let sampleTemp = {
      ...props.sample,
      uuid_submission_type: uuid_submission_type,
      str_submission_type_name: str_submission_type_name,
    };
    if (
      !sampleTemp.uuid_site ||
      !sampleTemp.uuid_brand ||
      !sampleTemp.uuid_product_type ||
      !sampleTemp.dat_production ||
      !sampleTemp.uuid_volume
    ) {
      return false;
    }
    return true;
  };

  const hanldeSKUChange = () => {
    let sku = '';
    if (
      props.sample.uuid_brand &&
      props.sample.uuid_package &&
      props.sample.uuid_volume &&
      props.brands &&
      props.packages &&
      props.volumes
    ) {
      const brandTemp = props.brands.find(brand => {
        return brand.uuid_brand === props.sample.uuid_brand;
      });
      const packageTemp = props.packages.find(pack => {
        return pack.uuid_package === props.sample.uuid_package;
      });
      const volumeTemp = props.volumes.find(volume => {
        return volume.uuid_volume === props.sample.uuid_volume;
      });
      sku =
        (brandTemp ? brandTemp.str_name + ' - ' : '') +
        (packageTemp ? packageTemp.str_name + ' - ' : '') +
        (volumeTemp ? volumeTemp.str_name : '');
    }
    return sku;
  };
  const monthLength = now.getMonth() > 9 ? 12 : now.getMonth() + 3;
  const kpiOptions = [...Array(monthLength /*months*/)]
    .map((x, i) => {
      const month = i;
      const year = now.getFullYear();
      return {
        date: `${t(`month_${month}`)}-${year}`,
        value: `${year}-${`0${month + 1}`.substr(-2)}-01`,
      };
    })
    .reverse();
  kpiOptions.splice(0, 0, { date: t('sample_not_for_kpi'), value: NO_KPI });

  if (props.sample.dat_kpi === null || props.sample.dat_kpi === undefined) {
    props.sample.dat_kpi = NO_KPI;
  }

  const handleDownload = async () => {
    const defaultDownloadObj = {
      dat_kpi: '',
      dat_production: '',
      str_batch: '',
      str_name: '',
      str_sku: '',
      str_submission_type_name: '',
      str_name_project: '',
      str_trial_identifier: '',
      sampleTab: 'Production',
    };
    const downloadObj = {
      ...defaultDownloadObj,
      ...findValue(),
    };
    await reportService.generateSampleLabels(downloadObj).then(res => {
      let a = document.createElement('a');
      a.href = configs.file_api_url + '/v1/' + res.file;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  return (
    <div className={editSampleProductionStyle.pageContainer}>
      <Card>
        <CardRow flexWrap="wrap">
          <DropdownField
            name="uuid_zone"
            options={props.privilege.arr_zones ? props.privilege.arr_zones : []}
            valueField="uuid_zone"
            labelField="str_name"
            label={t('common_zone')}
            margin="10px 10px 0px 0px"
            value={props.sample.uuid_zone}
            onChange={value => {
              props.sample.uuid_country = null;
              props.sample.uuid_site = null;
              handleChange(value);
            }}
            isRequired={true}
          />
          <DropdownField
            name="uuid_country"
            options={
              props.privilege.arr_countries && props.sample.uuid_zone
                ? props.privilege.arr_countries.filter(country => {
                  return country.uuid_zone === props.sample.uuid_zone;
                })
                : []
            }
            valueField="uuid_country"
            labelField="str_name"
            label={t('common_country')}
            margin="10px 10px 0px 0px"
            value={props.sample.uuid_country}
            onChange={value => {
              props.sample.uuid_site = null;
              handleChange(value);
            }}
            isRequired={true}
          />
          <DropdownField
            name="uuid_site"
            options={
              props.privilege.arr_sites && props.sample.uuid_country
                ? props.privilege.arr_sites.filter(site => {
                  return site.uuid_country === props.sample.uuid_country;
                })
                : []
            }
            valueField="uuid_site"
            labelField="str_name"
            label={t('common_site')}
            margin="10px 10px 0px 0px"
            value={props.sample.uuid_site}
            onChange={handleChange}
            isRequired={true}
          />
        </CardRow>
        <CardRow flexWrap="wrap">
          <DropdownField
            name="uuid_brand"
            options={props.brands.filter(data => {
              return (
                data.uuid_product_type === props.sample.uuid_product_type ||
                !props.sample.uuid_product_type
              );
            })}
            valueField="uuid_brand"
            labelField="str_name"
            label={t('common_brand')}
            margin="10px 10px 0px 0px"
            value={props.sample.uuid_brand}
            onChange={handleChange}
            isRequired={true}
          />
          <DropdownField
            name="uuid_product_type"
            options={props.products.filter(data => {
              return (
                props.brands
                  .filter(brand => {
                    return brand.uuid_brand === props.sample.uuid_brand;
                  })
                  .map(brand => {
                    return brand.uuid_product_type;
                  })
                  .includes(data.uuid_product_type) || !props.sample.uuid_brand
              );
            })}
            valueField="uuid_product_type"
            labelField="str_name_product"
            label={t('common_product_type')}
            margin="10px 10px 0px 0px"
            value={props.sample.uuid_product_type}
            isRequired={true}
            onChange={handleChange}
          />
          <DropdownField
            name="dat_kpi"
            disabled={
              isKpiBrand !== undefined ? !isKpiBrand : !props.isKpiBrand
            }
            options={kpiOptions}
            valueField="value"
            labelField="date"
            label={t('common_kpi_month')}
            margin="10px 10px 0px 0px"
            isLoading={isLoadingKPI}
            value={
              props.sample.dat_kpi && props.sample.dat_kpi.includes('T')
                ? props.sample.dat_kpi.split('T')[0]
                : props.sample.dat_kpi
            }
            onChange={handleChange}
            bolOrder={false}
          />
          <DropdownField
            name="str_submission_type_name"
            options={submissionType}
            valueField="str_submission_type_name"
            labelField="str_submission_type_name"
            uuid="uuid_submission_type"
            label={t('submission_type')}
            margin="10px 10px 0px 0px"
            value={subType.str_submission_type_name}
            onChange={handleChange}
            bolOrder={false}
          />
        </CardRow>
        <CardRow flexWrap="wrap">
          {props.sample.uuid_product_type ===
            'e0a056e4-05ba-11eb-b946-02be69c96cde' ? (
            <DropdownMultiField
              name="uuid_malt_varieties"
              options={props.maltVarieties ? props.maltVarieties : []}
              valueField="uuid_malt_varieties"
              labelField="str_name_en"
              label={t('sample_malt_varieties')}
              margin="10px 10px 0px 0px"
              value={props.sample.uuid_malt_varieties}
              onChange={value => {
                handleChange(value);
              }}
              maxValueSelect={5}
              messageMaxValue={t('you_can_only_select_up_to_five')}
            />
          ) : (
            <DropdownField
              name="uuid_package"
              options={props.packages}
              valueField="uuid_package"
              labelField="str_name"
              label={t('sample_package')}
              margin="10px 10px 0px 0px"
              value={props.sample.uuid_package}
              disabled={
                props.sample.uuid_product_type ===
                  'e0a056e4-05ba-11eb-b946-02be69c96cde'
                  ? true
                  : disabled
              }
              onChange={value => {
                props.sample.uuid_volume = null;
                handleChange(value);
              }}
              isRequired={true}
            />
          )}

          <DropdownField
            name="uuid_volume"
            options={
              props.volumes && props.sample.uuid_package
                ? props.volumes.filter(volume => {
                  return volume.uuid_package === props.sample.uuid_package;
                })
                : []
            }
            valueField="uuid_volume"
            labelField="str_name"
            label={t('sample_volume')}
            margin="10px 10px 0px 0px"
            value={props.sample.uuid_volume}
            disabled={
              props.sample.uuid_product_type ===
                'e0a056e4-05ba-11eb-b946-02be69c96cde'
                ? true
                : disabled
            }
            onChange={handleChange}
            isRequired={true}
          />
          <TextField
            name="str_sku"
            label={t('common_sku')}
            margin="10px 10px 0px 0px"
            value={hanldeSKUChange()}
            disabled
          />
        </CardRow>
        <CardRow flexWrap="wrap">
          <DatePickerField
            name="dat_production"
            type={'date'}
            label={t('sample_production_date')}
            margin="10px 10px 0px 0px"
            value={datProd}
            onChange={handleChange}
            isRequired={true}
            maxDate={Moment(new Date()).format('YYYY-MM-DD')}
          />
          <DatePickerField
            name="dat_expire"
            type={'date'}
            label={t('sample_expire_date')}
            margin="10px 10px 0px 0px"
            value={datExp}
            onChange={handleChange}
          />
          <div
            style={{
              margin: '10px 10px 0px 0px',
              flex: '1 1 0%',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t('common_batch')}
              <HelpCircle
                className={editSampleProductionStyle.helpCircleId}
                data-tip={`${t('sample_id_code_message')}`}
              />
              <ReactTooltip
                effect="solid"
                place="top"
                type="warning"
                className={editSampleProductionStyle.tooltip}
              />
            </div>
            <TextField
              name="str_batch"
              value={props.sample.str_batch}
              onChange={handleChange}
            />
          </div>
        </CardRow>
        {(isSelectedKPIMonth && isKpiBrand) ||
          (props.sample.dat_kpi !== 'nokpi' && props.sample.dat_kpi !== null) ? (
          <CardRow flexWrap="wrap">
            <DropdownMultiField
              name="uuid_ship_site"
              options={props.shippingSites ? props.shippingSites : []}
              valueField="uuid_ship_site"
              labelField="str_ship_name"
              label={t('Shipping_Site')}
              margin="10px 10px 0px 0px"
              showHint
              hintText={t('receive_hint')}
              hintID="receive_hint"
              value={props.sample.uuid_ship_site}
              onChange={value => {
                handleChange(value);
              }}
            />
            <div
              style={{
                flex: 'auto',
                width: '34%',
              }}
            ></div>
          </CardRow>
        ) : (
          <></>
        )}
        <CardRow flexWrap="wrap">
          <TextArea
            name="str_comment"
            label={t('common_notes')}
            margin="10px 10px 0px 0px"
            value={props.sample.str_comment}
            onChange={handleChange}
          />
        </CardRow>
        <CardRow display="inline-flex">
          {props.sample.str_zip_documents
            ? props.sample.str_zip_documents.map((doc, i) => (
              <CardDocument
                name="str_zip_documents"
                src={doc.str_zip_document}
                onRemove={() => handleRemove(doc, i)}
                margin="10px 0px"
                size={80}
                width="100px"
              />
            ))
            : null}
        </CardRow>
        <CardRow flexWrap="wrap">
          <React.Fragment>
            <div className={editSampleProductionStyle.uploadCard}>
              <UploadButton
                name="str_zip_documents"
                label={t('sample_upload_document')}
                onUpload={handleChange}
                margin="0px 10px 0px 0px"
                allowExtension={['.jpeg', '.jpg', '.png', '.pdf']}
                multiple={true}
                disabled={!props.sample.uuid_site}
                maxSize={5242880}
              />
              <HelpCircle
                className={editSampleProductionStyle.helpCircle}
                data-tip={`${t(
                  'sample_upload_message',
                )} .jpeg, .jpg, .png, .pdf. (5mb)`}
              />
              <ReactTooltip
                effect="solid"
                place="top"
                type="warning"
                className={editSampleProductionStyle.tooltip}
              />
            </div>
          </React.Fragment>

          <React.Fragment>
            <div className={editSampleProductionStyle.uploadCard}>
              <Button
                blue
                upload
                disabled={!madatoryFieldValidate()}
                width="155px"
                height="40px"
                label={t('download_label')}
                onClick={handleDownload}
                margin="0px 0px 0px 20px"
              ></Button>
            </div>
          </React.Fragment>
        </CardRow>
        <CardRow justifyContent="flex-end" flexWrap="wrap">
          <Button
            label={t('common_cancel')}
            onClick={() => {
              history.goBack();
            }}
            neutral
            margin="10px 10px 0px 0px"
          />
          <Button
            label={t('common_save')}
            onClick={handleSave}
            confirm
            disabled={disabledSave || !props.sample.uuid_site}
            margin="10px 10px 0px 0px"
          />
        </CardRow>
      </Card>
    </div>
  );
};

export default EditSampleProduction;

