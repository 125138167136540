import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button } from '../../components';
import AppService from '../../app.service';
import loginStyle from './login.module.scss';
import logo from '../../logo.png';
import Spinner from '../../components/spinner/Spinner';
import {useUser} from '../../contexts/User';


const Login = props => {

  const appService = new AppService();
  
  const { i18n } = useTranslation();
  const [login, setLogin] = useState({});
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const {handleUser, handleLogged} = useUser();

  const handleLogin = () => {
    setLoading(true);
    appService.login({str_login: login.str_login, str_pass: login.str_pass, str_token: 'token_sso'}).then(res => {
      if (res.type === 'success' && res.results) {
        let user = {
          ...res.results[0],
          currentTab: 'menu_tasting_session',
          currentMenu: 'menu_tasting_session',
          showMenu: res.results[0]?.arr_privileges && res.results[0]?.arr_privileges.length > 0
        };
        handleUser(user);
        handleLogged();
        i18n.changeLanguage(res.results[0].str_language);
      } else {
        window.alert(res.message);
        setLoading(false);
      }
    }, err => {
      //console.log(err);
      setLoading(false);
      setInvalid(true);
    });
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setLogin({
      ...login,
      [evt.target.name]: value
    });
  };

  return (
    <div className={loginStyle.loginContainer}>
      <div className={loginStyle.loginBox}>
        <div className={loginStyle.loginLogo}>
          <img src={logo}  alt="ABInBev" height="30"/>
        </div>
        <TextField 
          text
          name="str_login" 
          flex="unset"
          margin="5px 0px 0px 0px"
          placeholder="E-mail or ABI ID"
          value={login.str_login} 
          onChange={handleChange}
        />
        <TextField
          password
          name="str_pass" 
          flex="unset"
          margin="0px 0px 10px 0px"
          placeholder="Password"
          value={login.str_pass}
          onChange={handleChange}
        />
        {invalid ? <span className={loginStyle.invalid}>Incorrect Password or Login!</span> : null}
        {loading ? <Spinner/> : <Button label="Login" onClick={handleLogin} confirm/>}
      </div>
    </div>
  );
}

Login.propTypes = {
  // onLogon: PropTypes.func.isRequired
}

export default Login;