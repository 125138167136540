import React, { useState, useEffect } from 'react';
import editUserStyle from './edituser.module.scss';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Label,
  Card,
  CardRow,
  DropdownField,
  Button,
  CardColumn,
} from '../../../components';
import ProfileService from '../../services/users/profile.service';
import UserService from '../../services/users/user.service';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../contexts/User';
import _ from 'lodash';

const EditUser = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [user, setUser] = useState({
    ...props.location.state.values,
  });
  const [profiles, setProfiles] = useState([]);
  const [jobPositions, setJobPosition] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const profileService = new ProfileService();
  const userService = new UserService();
  const loggedUser = useUser();

  useEffect(() => {
    refreshState();
    if (!props.location.state.show) {
      setUser(prevState => {
        return {
          ...prevState,
          uuid_zone: JSON.parse(sessionStorage.getItem('user')).uuid_zone,
          uuid_country: JSON.parse(sessionStorage.getItem('user')).uuid_country,
          uuid_site: JSON.parse(sessionStorage.getItem('user')).uuid_site,
        };
      });
    }
  }, []);

  const refreshState = () => {
    //GET PROFILES
    profileService.listProfiles(null).then(
      res => {
        if (res.type === 'success' && res.results) {
          setProfiles(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
    setPrivilege(props.location.state.privilege);
    userService.getJobPosition(null).then(res => {
      if (res.type === 'success' && res.results) {
        setJobPosition(res.results);
      } else {
        window.alert(res.message);
      }
    });
  };

  const handleChange = evt => {
    const value = evt.target.value;
    const trim_value = _.trim(value);
    setUser({
      ...user,
      [evt.target.name]: trim_value,
    });
  };

  const handleSave = () => {
    if (!user.uuid_profile) {
      user.uuid_profile = null;
    }
    if (!user.uuid_job_position) {
      user.uuid_job_position = null;
    }
    if (!user.uuid_zone) {
      window.alert(t('zone_can_not_empty'));
      return;
    }
    userService.updateUser(user).then(
      res => {
        if (res.type === 'success' && res.results) {
          if (user.uuid_user === loggedUser.user.uuid_user) {
            const tempUser = loggedUser.user;

            tempUser.uuid_country = user.uuid_country;
            tempUser.uuid_profile = user.uuid_profile;
            tempUser.uuid_job_position = user.uuid_job_position;
            tempUser.uuid_site = user.uuid_site;
            tempUser.uuid_zone = user.uuid_zone;
            tempUser.str_name_country = privilege.arr_countries.filter(
              item => item.uuid_country === user.uuid_country,
            )[0]?.str_name;
            tempUser.str_name_site = privilege.arr_sites.filter(
              item => item.uuid_site === user.uuid_site,
            )[0]?.str_name;
            tempUser.str_name_zone = privilege.arr_zones.filter(
              item => item.uuid_zone === user.uuid_zone,
            )[0]?.str_name;

            loggedUser.handleUser(tempUser);
          }
          history.goBack();
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  return (
    <div className={editUserStyle.pageContainer}>
      <Label label={t('user_edit_user')} bold neutral />

      <Card bordered>
        <CardRow flexWrap="wrap">
          <TextField
            disabled={
              typeof props.location.state.show === 'boolean' &&
              props.location.state.show
            }
            name="str_abi_id"
            label={t('common_id')}
            margin="10px 10px 0px 0px"
            value={user.str_abi_id}
            onChange={handleChange}
          />
          <TextField
            name="str_name"
            label={t('common_name')}
            margin="10px 10px 0px 0px"
            value={user.str_name}
            onChange={handleChange}
          />
          <TextField
            disabled={
              typeof props.location.state.show === 'boolean' &&
              props.location.state.show
            }
            name="str_email"
            label={t('common_email')}
            margin="10px 10px 0px 0px"
            value={user.str_email}
            onChange={handleChange}
          />
        </CardRow>
        <CardRow flexWrap="wrap">
          <DropdownField
            name="uuid_profile"
            options={profiles}
            valueField="uuid_profiles"
            labelField="str_name"
            label={t('common_profile')}
            margin="10px 10px 0px 0px"
            value={user.uuid_profile}
            onChange={handleChange}
          />
          <TextField
            name="str_language"
            label={t('common_language')}
            margin="10px 10px 0px 0px"
            value={user.str_language}
            disabled
          />
          <TextField
            name="dat_accept_terms_of_use"
            label={t('user_use_terms')}
            margin="10px 10px 0px 0px"
            value={user.dat_accept_terms_of_use}
            disabled
          />
        </CardRow>
        <CardRow flexWrap="wrap">
          <DropdownField
            name="uuid_zone"
            options={privilege.arr_zones ? privilege.arr_zones : []}
            valueField="uuid_zone"
            labelField="str_name"
            label={t('common_zone')}
            margin="10px 10px 0px 0px"
            value={user.uuid_zone}
            onChange={value => {
              user.uuid_country = null;
              user.uuid_site = null;
              handleChange(value);
            }}
          />
          <DropdownField
            name="uuid_country"
            options={
              privilege.arr_countries && user.uuid_zone
                ? privilege.arr_countries.filter(country => {
                    return country.uuid_zone === user.uuid_zone;
                  })
                : []
            }
            valueField="uuid_country"
            labelField="str_name"
            label={t('common_country')}
            margin="10px 10px 0px 0px"
            value={user.uuid_country}
            onChange={value => {
              user.uuid_site = null;
              handleChange(value);
            }}
          />
          <DropdownField
            name="uuid_site"
            options={
              privilege.arr_sites && user.uuid_country
                ? privilege.arr_sites.filter(site => {
                    return site.uuid_country === user.uuid_country;
                  })
                : []
            }
            valueField="uuid_site"
            labelField="str_name"
            label={t('common_site')}
            margin="10px 10px 0px 0px"
            value={user.uuid_site}
            onChange={handleChange}
          />
        </CardRow>
        <CardRow flexWrap="wrap">
          <DropdownField
            name="uuid_job_position"
            options={jobPositions}
            valueField="uuid_job_position"
            labelField="str_name_job_position"
            label={t('common_job_position')}
            margin="10px 10px 0px 0px"
            value={user.uuid_job_position}
            onChange={handleChange}
            bolOrder={false}
          />
          <CardColumn padding="5px" flex={1}></CardColumn>
          <CardColumn padding="5px" flex={1}></CardColumn>
        </CardRow>
        <CardRow justifyContent="flex-end" flexWrap="wrap">
          <Button
            label={t('common_cancel')}
            onClick={() => {
              history.goBack();
            }}
            neutral
            margin="10px 10px 0px 0px"
          />
          <Button
            label={t('common_save')}
            onClick={handleSave}
            confirm
            margin="10px 10px 0px 0px"
            disabled={!user.str_abi_id}
          />
        </CardRow>
      </Card>
    </div>
  );
};

export default EditUser;

