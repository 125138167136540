import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Loading } from '../';
import RoundUpAndDownStyle from './RoundUpAndDown.module.scss';
import { useTasting } from '../../contexts/Tasting';
import RoundAppService from '../../modules/services/rounds/roundApp.service';
import { timeRemaining } from '../../utils/timeRemaining';
import moment from 'moment';

export default function RoundUpAndDown(props) {
  const roundAppService = new RoundAppService();
  const history = useHistory();
  const { t } = useTranslation();
  const { loadTaste } = useTasting();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [afterClickRound, setAfterClickRound] = useState('');
  const [isShowPreButton, setIsShowPreButton] = useState(false);
  const [isShowNextButton, setIsShowNextButton] = useState(false);
  const curRound = props.round;
  const [showModal, setShowModal] = useState(false);
  const timeNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

  useEffect(() => {
    getRoundStatus();
  }, [props.round]);

  const getRoundStatus = async () => {
    setLoading(true);
    const d = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const statusRes = await roundAppService.getRoundStatus({
      uuid_round: curRound.uuid_round,
      cur_date: d,
    });
    if (statusRes.type === 'success') {
      setIsShowPreButton(!!statusRes.results[0].round_status);
      setIsShowNextButton(!!statusRes.results[1].round_status);
    }
    setLoading(false);
  };
  const updatePreNextRound = async name1 => {
    setIsShowConfirm(false);
    const d = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const roundRes = await roundAppService.getNextRound({
      uuid_round: curRound?.uuid_round,
      type: name1,
      cur_date: d,
    });
    if (roundRes.type === 'success') {
      setAfterClickRound(roundRes.results[0]);
      setShowModal(true);
      setIsShowConfirm(true);
    }
  };

  const jumpFn = (
    pathname1,
    curPath,
    pathname2,
    obj,
    currentUrl = currentUrl,
    bol_score,
  ) => {
    if (obj.bol_finished) {
      history.replace({
        pathname: pathname1,
        state: { round: obj, bol_score },
      });
      window.location.reload();
      return;
    }
    history.replace({ pathname: pathname2, state: { round: obj, bol_score } });
    window.location.reload();
    return;
  };

  const jump = async obj => {
    const num = timeRemaining(obj.dat_finish) / 60;
    setLoading(true);
    let currentUrl = window.location.pathname;
    if (
      !obj.bol_started &&
      !obj.bol_finished &&
      obj.uuid_round_type !== '02095a80-6cc4-11ec-8d68-000000000000' &&
      obj.uuid_round_type !== '0d55fca0-e7a2-11ec-a8bc-000000000000' &&
      obj.uuid_round_type !== 'e4454b00-6950-11ed-bb18-000000000000'
    ) {
      await roundAppService.updateStart(obj, timeNow);
    }
    const res = await loadTaste(obj);
    setLoading(false);
    if (obj.uuid_round_type === 'be229b18-2eb4-11eb-ba1d-0229531a6f14') {
      jumpFn(
        '/tasting_discussion_ok_nok',
        '/tasting_discussion_ok_nok',
        '/tasting_ok_nok',
        obj,
        currentUrl,
        res.results.bol_score,
      );
    } else if (obj.uuid_round_type === '02095a80-6cc4-11ec-8d68-000000000000') {
      if (!obj.bol_is_countdown) {
        jumpFn(
          `/tasting_discussion_threshold/${obj.uuid_round}`,
          '/tasting_discussion_threshold',
          '/tasting_threshold',
          obj,
          currentUrl,
        );
        return;
      }
      if (obj.bol_started && obj.bol_is_next) {
        jumpFn(
          `/tasting_discussion_threshold/${obj.uuid_round}`,
          '/tasting_discussion_threshold',
          '/tasting_threshold',
          obj,
          currentUrl,
        );
        return;
      }
      if (num > 0 && num < 5) {
        history.replace('/');
        alert(t('less_than_five_minutes'));
        return;
      }
      if (num < 0) {
        history.replace('/');
        alert(t('the_countdown_to_the_evaluation_has_ended'));
        return;
      }
      history.replace({
        pathname: `/tasting_threshold`,
        state: { round: obj },
      });
      window.location.reload();
    } else if (obj.uuid_round_type === '0d55fca0-e7a2-11ec-a8bc-000000000000') {
      if (obj.bol_is_countdown) {
        if (obj.bol_started && obj.bol_is_next) {
          const resCode = await roundAppService.getFlavorCode({
            uuid_round: obj.uuid_round,
          });
          if (
            resCode.type === 'success' &&
            resCode.results[0].round_sp_se_flavors_identification_current_code
          ) {
            history.replace({
              pathname: `/tasting_flavor_session`,
              state: {
                round: obj,
              },
            });
            if (currentUrl === '/tasting_flavor_session') {
              sessionStorage.removeItem('bol_finished');
              window.location.reload();
            }
            return;
          } else {
            history.replace({
              pathname: `/code_flavor`,
              state: { round: obj },
            });
          }
        } else {
          if (num > 0 && num < 5) {
            history.replace('/');
            alert(t('less_than_five_minutes'));
            return;
          }
          if (num < 0) {
            history.replace('/');
            alert(t('the_countdown_to_the_evaluation_has_ended'));
            return;
          }
          history.replace({
            pathname: `/code_flavor`,
            state: { round: obj },
          });
        }
      } else {
        if (obj.bol_started) {
          const res = await roundAppService.getFlavorCode({
            uuid_round: obj.uuid_round,
          });
          if (
            res.type === 'success' &&
            res.results[0].round_sp_se_flavors_identification_current_code
          ) {
            history.replace({
              pathname: `/tasting_flavor_session`,
              state: { round: obj },
            });
            if (currentUrl === '/tasting_flavor_session') {
              sessionStorage.removeItem('bol_finished');
              window.location.reload();
            }
            return;
          }
          history.replace({
            pathname: `/code_flavor`,
            state: { round: obj },
          });
        } else {
          history.replace({
            pathname: `/code_flavor`,
            state: { round: obj },
          });
        }
      }
      return;
    } else if (obj.uuid_round_type === 'e4454b00-6950-11ed-bb18-000000000000') {
      if (!obj.bol_is_countdown) {
        jumpFn(
          `/tasting_discussion_triangle/${obj.uuid_round}`,
          '/tasting_discussion_triangle',
          '/tasting_triangle',
          obj,
          currentUrl,
        );
        return;
      }
      if (obj.bol_started && obj.bol_is_next) {
        jumpFn(
          `/tasting_discussion_triangle/${obj.uuid_round}`,
          '/tasting_discussion_triangle',
          '/tasting_triangle',
          obj,
          currentUrl,
        );
        return;
      }
      if (num > 0 && num < 5) {
        history.replace('/');
        alert(t('less_than_five_minutes'));
        return;
      }
      if (num < 0) {
        history.replace('/');
        alert(t('the_countdown_to_the_evaluation_has_ended'));
        return;
      }
      history.replace({
        pathname: `/tasting_triangle`,
        state: { round: obj },
      });
      window.location.reload();
    } else {
      jumpFn(
        '/tasting_discussion',
        '/tasting_discussion',
        `/tasting_review`,
        obj,
        currentUrl,
      );
    }
  };

  const Confirmation = () => {
    return (
      <div className={RoundUpAndDownStyle.Confirmation}>
        <div className={RoundUpAndDownStyle.Confirm}>
          <div className={RoundUpAndDownStyle.roundlayout}>
            <div className={RoundUpAndDownStyle.roundTitle}>
              {name === 'Previous' ? t('previous_round') : t('next_round')}
            </div>
          </div>

          <p className={RoundUpAndDownStyle.roundInfo}>
            {afterClickRound?.int_round}
          </p>
          <p className={RoundUpAndDownStyle.roundInfo}>
            {afterClickRound?.str_name_round}
          </p>
          <p className={RoundUpAndDownStyle.type}>
            {afterClickRound?.str_round_type_name}
          </p>
          <div className={RoundUpAndDownStyle.alertContainer}>
            {showModal && (
              <>
                <Button
                  className={RoundUpAndDownStyle.concel}
                  label={t('common_cancel')}
                  onClick={() => {
                    setIsShowConfirm(false);
                  }}
                ></Button>
                <Button
                  className={RoundUpAndDownStyle.confirm}
                  label={t('confirm')}
                  onClick={() => {
                    jump(afterClickRound);
                    setIsShowConfirm(false);
                  }}
                ></Button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {isShowConfirm && Confirmation()}
      {
        <div className={RoundUpAndDownStyle.round}>
          <Button
            disabled={!isShowPreButton}
            className={
              RoundUpAndDownStyle.previous +
              (!isShowPreButton ? ' ' + RoundUpAndDownStyle.NotButton : '')
            }
            label={t('previous_round')}
            onClick={() => {
              let roundName = 'privious';
              updatePreNextRound(roundName);
              setName('Previous');
            }}
          >
            {name === 'Previous' && (
              <Loading
                loading={loading}
                width="18px"
                height="18px"
                className={RoundUpAndDownStyle.loading}
              />
            )}
          </Button>
          <Button
            disabled={!isShowNextButton}
            className={
              RoundUpAndDownStyle.next +
              (!isShowNextButton ? ' ' + RoundUpAndDownStyle.NotButton : '')
            }
            label={t('next_round')}
            onClick={() => {
              let roundName = 'next';
              updatePreNextRound(roundName);
              setName('Next');
            }}
          >
            {name === 'Next' && (
              <Loading
                loading={loading}
                width="18px"
                height="18px"
                className={RoundUpAndDownStyle.loading}
              />
            )}
          </Button>
        </div>
      }
    </>
  );
}

