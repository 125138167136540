import React, { memo, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { Download, X, Trash2, ZoomIn, ZoomOut, Maximize } from 'react-feather';
import loadingStyle from './pdf.module.scss';
import { Button, Modal, Spinner } from '../';
import AppService from '../../../src/app.service';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDFViewerModal = props => {
  const { t } = useTranslation();

  const [maxPage, setMaxPages] = useState(1);
  const [maxScale, setMaxScale] = useState(3);
  const [minScale, setMinScale] = useState(1);
  const [scale, setScale] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [pageInputValue, setPageInputValue] = useState(1);
  const [file, setFile] = useState('');
  const [showPic, setShowPic] = useState(false);
  const [fullButton, setFullButton] = useState({});
  const [fullHeight, setHeight] = useState('');
  const [showPdf, setShowPdf] = useState(false);
  const appService = new AppService();

  useEffect(() => {
    appService.getFileBase64(props.data).then(res => {
      if (res.str_file_name !== '') {
        const str_file = res.str_file_name;
        setFile(str_file);
        detectBase64Type();
      }
    });
  }, [props.data, appService]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageNo(1);
    setPageInputValue(1);
    if (numPages > 1) {
      setMaxPages(numPages);
    }
  };

  const detectBase64Type = useMemo(() => {
    const signatures = {
      J: 'application/pdf',
      R: 'image/gif',
      i: 'image/png',
      '/': 'image/jpeg',
    };

    for (const s in signatures) {
      if (file.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  }, [file]);

  const changePageHandler = page => {
    const pageAux = Math.floor(page);

    if (Number.isNaN(pageAux)) {
      setPageNo(pageNo);
      setPageInputValue(pageNo);
    } else if (pageAux > maxPage) {
      setPageNo(maxPage);
      setPageInputValue(maxPage);
    } else if (pageAux > 0) {
      setPageNo(pageAux);
      setPageInputValue(pageAux);
    } else {
      setPageNo(1);
      setPageInputValue(1);
    }
  };

  const changeZoomHandler = value => {
    if (value <= maxScale && value >= minScale) {
      setScale(value);
    }
  };

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = `data:${detectBase64Type};base64,${file}`;
    link.download = props.downloadFileName.length
      ? props.downloadFileName
      : 'download.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRemove = () => {
    const res = { target: { value: null, name: props.name } };
    props.onRemove(res);
  };
  return (
    <>
      {props.open && file.length > 10 && (
        <Modal
          show={props.open}
          fullButton={fullButton}
          fullHeight={fullHeight}
          style={{ position: showPdf ? 'fixed' : '' }}
        >
          {!props.loading ? (
            //<Modal show={props.open} onClick={props.closeForm}>
            <>
              <div className={loadingStyle.header}>
                <Button
                  width={140}
                  style={{
                    borderRadius: '5px',
                    backgroundColor: '#F49B00',
                    color: '#fff',
                    fontWeight: 'bold',
                    marginRight: '5px',
                  }}
                  icon={<Download />}
                  onClick={downloadFile}
                >
                  {t('Download')}
                </Button>

                <div className={loadingStyle.groupButton}>
                  <Button
                    width={10}
                    style={{
                      borderRadius: '5px',
                      backgroundColor: '#F49B00',
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                    onClick={() => changePageHandler(pageNo - 1)}
                  >
                    {`<`}
                  </Button>

                  <div className={loadingStyle.page}>
                    <div>
                      <form
                        onSubmit={event => {
                          event.preventDefault();
                          changePageHandler(event?.target[0]?.valueAsNumber);
                        }}
                      >
                        <div className={loadingStyle.inputPageWrapper}>
                          {`${t('page')}`}

                          <input
                            type="number"
                            value={pageInputValue}
                            onChange={e =>
                              setPageInputValue(e.target.valueAsNumber)
                            }
                            onBlur={e => {
                              changePageHandler(e.target.valueAsNumber);
                            }}
                          />

                          {`${t('of')} ${maxPage}`}
                        </div>
                      </form>
                    </div>
                  </div>

                  <Button
                    width={10}
                    style={{
                      borderRadius: '5px',
                      backgroundColor: '#F49B00',
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                    onClick={() => changePageHandler(pageNo + 1)}
                  >
                    {`>`}
                  </Button>
                </div>

                <div
                  className={loadingStyle.groupButton}
                  style={{ marginLeft: '30px' }}
                >
                  <Button
                    width={35}
                    style={{
                      borderRadius: '5px',
                      backgroundColor: '#F49B00',
                      color: '#fff',
                      fontWeight: 'bold',
                      padding: '4px',
                      marginRight: '10px',
                    }}
                    onClick={() => changeZoomHandler(scale - 1)}
                  >
                    <ZoomOut />
                  </Button>

                  <Button
                    width={35}
                    style={{
                      borderRadius: '5px',
                      backgroundColor: '#F49B00',
                      color: '#fff',
                      fontWeight: 'bold',
                      padding: '4px',
                    }}
                    onClick={() => changeZoomHandler(scale + 1)}
                  >
                    <ZoomIn />
                  </Button>
                </div>

                <button
                  style={{
                    float: 'right',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className={loadingStyle.close}
                  onClick={props.closeForm}
                >
                  <X />
                </button>
                <button
                  style={{ margin: '60px' }}
                  className={loadingStyle.maximize}
                  onClick={() => {
                    setShowPdf(!showPdf);
                    setShowPic(!showPic);
                    if (showPic) {
                      setFullButton('70vw');
                      setHeight('');
                    } else {
                      setFullButton('96vw');
                      setHeight('97vh');
                    }
                  }}
                >
                  <Maximize />
                </button>
              </div>

              <div
                className={loadingStyle.contentContainer}
                style={{
                  overflowY: scale > 0 ? 'scroll' : 'none',
                }}
              >
                <Document
                  file={`data:application/pdf;base64,${file}`}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={<Spinner center />}
                  options={{
                    cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                    cMapPacked: true,
                    disableWorker: true,
                  }}
                >
                  <Page
                    className={showPic ? loadingStyle.Box : ''}
                    pageNumber={pageNo}
                    renderMode={'svg'}
                    renderTextLayer={false}
                    scale={scale}
                    renderAnnotationLayer={false}
                  />
                </Document>
              </div>
            </>
          ) : (
            <Spinner center />
          )}
        </Modal>
      )}
      {props.onClick !== undefined ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            zIndex={0}
            style={{
              borderRadius: '5px',
              backgroundColor: '#F49B00',
              color: '#fff',
              fontWeight: 'bold',
              width: '100%',
            }}
            icon={
              props.loading ? <Spinner size="small" width="12" /> : <Download />
            }
            onClick={props.onClick}
          >
            {props.label ? props.label : t('pdf_viewer')}
          </Button>

          {props.onRemove ? (
            <Trash2
              style={{ color: '#F49B00', marginLeft: '10px' }}
              onClick={handleRemove}
            />
          ) : (
            ''
          )}
        </div>
      ) : null}
    </>
  );
};

export default memo(PDFViewerModal);
