import React from 'react';
import PropTypes from 'prop-types';
import buttonStyle from './button.module.scss';
import { ArrowDown } from 'react-feather';

const Button = props => {
  const getClassName = children => {
    let classes = buttonStyle.button + ' ';
    if (props.confirm) {
      classes += buttonStyle.confirmButton + ' ';
    }
    if (props.neutral) {
      classes += buttonStyle.neutralButton + ' ';
    }
    if (props.remove) {
      classes += buttonStyle.removeButton + ' ';
    }
    if (props.round) {
      classes += buttonStyle.roundButton + ' ';
    }
    if (props.green) {
      classes += buttonStyle.greenButton + ' ';
    }
    if (props.blue) {
      classes += buttonStyle.uploadButton + ' ';
    }
    if (props.disabled || props.readOnly) {
      classes += buttonStyle.disabledButton + ' ';
    }
    if (props.transparent) {
      classes += buttonStyle.transparentButton;
    }
    classes += ' ' + props.className;
    return classes;
  };

  const styleObj = {
    borderRadius: props.borderRadius ? props.borderRadius : '',
    margin: props.margin ? props.margin : '0',
    position: props.position ? props.position : 'relative',
    zIndex: props.zIndex !== undefined ? props.zIndex : 1,
    height: props.height && props.height,
    width: props.width && props.width,
    ...props.style,
  };

  return (
    <button
      data-testid={props.dataTestid}
      className={getClassName()}
      onClick={props.disabled || props.readOnly ? null : props.onClick}
      readOnly={props.readOnly ? props.readOnly : false}
      disabled={props.disabled ? props.disabled : false}
      style={styleObj}
      title={props.title ? props.title : ''}
      data-tip={props.dataTip || ''}
    >
      {!!props.loading && <div className={buttonStyle.loader}></div>}
      {props.children && !props.loading ? props.children : ''}
      {!props.loading ? props.label : ''}
      {props.upload && (
        <div className={buttonStyle.uploadIcon}>
          <ArrowDown size={20} />
        </div>
      )}
      {props.download && (
        <div className={buttonStyle.arrowDown}>
          <ArrowDown size={20} />
        </div>
      )}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  confirm: PropTypes.bool,
  neutral: PropTypes.bool,
  transparent: PropTypes.bool,
  disabled: PropTypes.bool,
  round: PropTypes.bool,
  remove: PropTypes.bool,
  margin: PropTypes.string,
  position: PropTypes.string,
  className: PropTypes.string,
  zIndex: PropTypes.number,
  style: PropTypes.object,
  title: PropTypes.string,
  dataTestid: PropTypes.string,
  loading: PropTypes.bool,
  refs: PropTypes.any
};

export default Button;
