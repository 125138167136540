import MainService from './main.service';
import RoundService from '../rounds/round.service';

export default class SampleAppService {
  mainService = null;

  constructor() {
    this.mainService = new MainService();
    this.roundService = new RoundService();
    this.path = '/v1/app';
    this.body = { dat_last: '1991-04-01T15:52:47.000Z' };
  }

  listRoundSample = () => {
    return this.mainService.post(this.path + '/list', this.body);
  };

  flavorSaveComments = body => {
    return this.mainService.post(
      this.path + '/flavors_identification/comment',
      body,
    );
  };

  flavorDelComments = body => {
    return this.mainService.delete(
      this.path + '/delete_flavors_identification_comment/' + body,
    );
  };

  flavorSortComments = body => {
    return this.mainService.post(
      this.path + '/edit/flavors_identification_comment_order',
      body,
    );
  };

  updateRank = (uuid_round, uuid_sample, int_rank, uuid_user = null) => {
    return this.mainService.post(this.path + '/rank', {
      uuid_round: uuid_round,
      uuid_sample: uuid_sample,
      int_rank: int_rank,
      uuid_user: uuid_user,
    });
  };

  updateScore = score => {
    return new Promise((resolve, reject) => {
      this.mainService.post(this.path + '/score', score).then(
        res => {
          if (res.type === 'success' && res.results) {
            resolve(res.results[0]);
          }

          resolve(new Error(res.title));
        },
        error => {
          reject(error);
        },
      );
    });
  };

  updateCharacteristic(comment) {
    return new Promise((resolve, reject) => {
      //console.log(this.path + "/comment");
      this.mainService.post(this.path + '/comment', comment).then(
        res => {
          //ONLINE
          if (res.type === 'success' && res.results) {
            resolve(res.results[0]);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  }

  editCommentDiscussion(comment) {
    return new Promise((resolve, reject) => {
      this.mainService.post(this.path + '/edit/comment', comment).then(
        res => {
          //ONLINE
          if (res.type === 'success' && res.results) {
            resolve(res.results[0]);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  }

  editCommentOrder(comment) {
    return new Promise((resolve, reject) => {
      this.mainService.post(this.path + '/edit/comment_order', comment).then(
        res => {
          //ONLINE
          if (res.type === 'success' && res.results) {
            resolve(res.results[0]);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  }

  editScoreDiscussion = score => {
    return new Promise((resolve, reject) => {
      this.mainService.post(this.path + '/edit/score', score).then(
        res => {
          if (res.type === 'success' && res.results) {
            resolve(res.results[0]);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  };

  editScoreEditingPermission = score => {
    return new Promise((resolve, reject) => {
      this.mainService
        .post(this.path + '/edit/score_editing_permission', score)
        .then(
          res => {
            if (res.type === 'success' && res.results) {
              resolve(res.results[0]);
            }
          },
          error => {
            reject(error);
          },
        );
    });
  };

  editoknokEditingPermission = score => {
    return new Promise((resolve, reject) => {
      this.mainService
        .post(this.path + '/edit/oknok_editing_permission', score)
        .then(
          res => {
            if (res.type === 'success' && res.results) {
              resolve(res.results[0]);
            }
          },
          error => {
            reject(error);
          },
        );
    });
  };

  listVotes(uuid_round, uuid_sample) {
    return new Promise((resolve, reject) => {
      this.mainService.post(this.path + '/list_answers', this.body).then(
        res => {
          let characteristics = [];
          if (res.type === 'success' && res.results) {
            const answer = res.results.filter(answer => {
              return answer.dat_del === null;
            });
            this.mainService.post(this.path + '/list_comments', this.body).then(
              res => {
                if (res.type === 'success' && res.results) {
                  const comment = res.results.filter(comment => {
                    return comment.dat_del === null;
                  });

                  answer.map(answerTemp => {
                    comment.map(commentTemp => {
                      if (
                        answerTemp.uuid_answers_rounds_users_samples ===
                        commentTemp.uuid_answers_rounds_users_sample
                      ) {
                        characteristics.push({
                          uuid_answers_rounds_users_samples_comments:
                            commentTemp.uuid_answers_rounds_users_samples_comments,
                          uuid_degree: commentTemp.uuid_degree,
                          str_degree: commentTemp.str_degree,
                          uuid_comment: commentTemp.uuid_comment,
                          str_comment: commentTemp.str_comment,
                          uuid_round: answerTemp.uuid_round,
                          uuid_sample: answerTemp.uuid_sample,
                          flt_score: answerTemp.flt_score,
                          int_rank: answerTemp.int_rank,
                        });
                      }
                    });
                  });

                  let characteristicsTmp = characteristics.filter(
                    characteristic => {
                      return (
                        characteristic.uuid_round === uuid_round &&
                        (characteristic.uuid_sample === uuid_sample ||
                          uuid_sample === undefined)
                      );
                    },
                  );
                  resolve(characteristicsTmp);
                } else {
                  reject(res.type);
                }
              },
              error => {
                reject(error);
              },
            );
          } else {
            reject(res.type);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  }

  getScore(uuid_round, uuid_sample) {
    return new Promise((resolve, reject) => {
      this.mainService.post(this.path + '/list_answers', this.body).then(
        res => {
          if (res.type === 'success' && res.results) {
            const answer = res.results.filter(answer => {
              return answer.dat_del === null;
            });

            let score = 0;
            answer.map(answerTemp => {
              if (
                uuid_round === answerTemp.uuid_round &&
                uuid_sample === answerTemp.uuid_sample
              ) {
                score = answerTemp.flt_score;
              }
            });

            resolve(score);
          } else {
            reject(res.type);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  }

  deleteComment(uuid_answers_rounds_users_samples_comments) {
    return new Promise((resolve, reject) => {
      this.mainService
        .delete(
          `${this.path}/comment/${uuid_answers_rounds_users_samples_comments}`,
        )
        .then(
          res => {
            if (res.type === 'success' && res.results) {
              resolve(res.results[0]);
            } else {
              reject(res.type);
            }
          },
          error => {
            reject(error);
          },
        );
    });
  }

  listSamplesByRound(uuid_round) {
    return new Promise((resolve, reject) => {
      const params = { dat_last: '1991-04-01T15:52:47.000Z' };
      this.roundService.listSamples(params).then(res => {
        let links = [];
        //ONLINE
        if (res.type === 'success' && res.results) {
          const roundSample = res.results.filter(roundSample => {
            return roundSample.dat_del === null;
          });

          this.mainService.post(`${this.path}/list`, params).then(res => {
            //ONLINE
            if (res.type === 'success' && res.results) {
              const sample = res.results.filter(sample => {
                return sample.dat_del === null;
              });
              roundSample.map(roundSampleTemp => {
                sample.map(sampleTemp => {
                  if (sampleTemp.uuid_sample === roundSampleTemp.uuid_sample) {
                    links.push({
                      uuid_round_sample:
                        roundSampleTemp.uuid_round + sampleTemp.uuid_sample,
                      uuid_round: roundSampleTemp.uuid_round,
                      int_order: roundSampleTemp.int_order_app,
                      ...sampleTemp,
                    });
                  }
                });
              });
              resolve(
                links.filter(link => {
                  return link.uuid_round === uuid_round;
                }),
              );
            }
          });
        }
      });
    });
  }

  saveTastingOkNok = (sample, voting) => {
    return new Promise((resolve, reject) => {
      let votingTemp = {
        uuid_round: sample.uuid_round,
        uuid_category_option: sample.uuid_category_option,
        uuid_sample: sample.uuid_sample,
        ...voting,
      };

      this.mainService.post(`${this.path}/saveVote`, votingTemp).then(
        res => {
          if (res.type === 'success' && res.results) {
            resolve(res);
          } else {
            reject(res.type);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  };

  saveTastingOkNokComment(comment) {
    return new Promise((resolve, reject) => {
      this.mainService.post(this.path + '/categories/comment', comment).then(
        res => {
          //ONLINE
          if (res.type === 'success' && res.results) {
            resolve(res.results[0]);
          }
        },
        error => {
          reject(error);
        },
      );
    });
  }

  deleteCommentOkNok(uuid_answers_rounds_users_samples_categories_comments) {
    return new Promise((resolve, reject) => {
      this.mainService
        .delete(
          `${this.path}/delete_category_comment/${uuid_answers_rounds_users_samples_categories_comments}`,
        )
        .then(
          res => {
            if (res.type === 'success' && res.results) {
              resolve(res.results[0]);
            } else {
              reject(res.type);
            }
          },
          error => {
            reject(error);
          },
        );
    });
  }

  updateApproveReject(approveReject) {
    return new Promise((resolve, reject) => {
      const requestUrl = `${this.path}/approve_reject`;

      this.mainService.post(requestUrl, approveReject).then(
        res => {
          if (res.type === 'success' && res.results) {
            resolve(res.results[0]);
          } else {
            reject(res.type);
          }
        },
        error => {
          //OFFLINE
          this.offlineRequest('POST', requestUrl, approveReject);
          resolve('offline, queued for later');
        },
      );
    });
  }
}
