import React, { useState, useEffect, Fragment, useCallback } from 'react';
import tastingThreshold from './tastingThreshold.module.scss';
import { useHistory } from 'react-router-dom';
import {
  CardRow,
  CardColumn,
  Label,
  TextField,
  Spinner,
  Button,
  Modal,
  Text,
  RoundUpAndDown,
  CountDown
} from '../../../components';
import { useTranslation } from 'react-i18next';
import beer from '../../../assets/beer_threshold.svg';
import { useTasting } from '../../../contexts/Tasting';
import { ArrowLeft } from 'react-feather';
import _ from 'lodash';
import configs from '../../../configs';
import { HelpCircle, X, Check } from 'react-feather';
import ThresholdService from '../../services/threshold/threshold.service';
import arrow from '../../../assets/arrow.svg';
import tuli from '../../../assets/tuli.png';
import { timeRemaining } from '../../../utils/timeRemaining';

const options = ['A', 'B', 'C'];

const TastingDiscussionThreshold = ({ location }) => {
  const history = useHistory();
  const { taste } = useTasting();
  const [vertical, setVertical] = useState(true);
  const [code, setCode] = useState();
  const [codeFormat, setCodeFormat] = useState();
  const { t } = useTranslation();
  const [samples, setSamples] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [uuidRoundsThreshold, setUuidRoundsThreshold] = useState(null);
  const [modalShowInfo, setModalShowInfo] = useState(false);
  const [round, setRound] = useState(location.state.round);
  const thresholdService = new ThresholdService();
  const [goBack, setGoBack] = useState(false);
  const [samplesExample, setSamplesExample] = useState([
    {
      name: 'Set 1',
      value: 'A',
      uuid_set: 'example_str_answer_set_1',
    },
    {
      name: 'Set 2',
      value: 'B',
      uuid_set: 'example_str_answer_set_2',
    },
    {
      name: 'Set 3',
      value: 'C',
      uuid_set: 'example_str_answer_set_3',
    },
    {
      name: 'Set 4',
      value: 'A',
      uuid_set: 'example_str_answer_set_4',
    },
    {
      name: 'Set 5',
      value: 'B',
      uuid_set: 'example_str_answer_set_5',
    },
    {
      name: 'Set 6',
      value: 'C',
      uuid_set: 'example_str_answer_set_6',
    },
  ]);

  useEffect(() => {
    setLoading(true);
    handleSearchTasting(null);

    return () => {
      sessionStorage.removeItem('roundjump')
    }

  }, []);

  const delayedQuery = useCallback(
    _.debounce(text => {
      if (text.length > 2) {
        setLoading(true);
        setCode(text.replace(/^0+/, ''));

        let num = text.toString();
        const size = 3;

        while (num.length < size) num = '0' + num;

        setCodeFormat(num);
        handleSearchTasting(text.replace(/^0+/, ''));
      } else {
        setCodeFormat(text);
      }
    }, 10),
    [],
  );

  const handleSearchTasting = code => {
    thresholdService
      .listTasteThreshold({ uuid_round: taste.uuid_round, str_code: code })
      .then(
        res => {
          setLoading(false);
          if (res.type === 'success' && res.length > 0) {
            setDisabled(true);
            setUuidRoundsThreshold(res.results[0].uuid_rounds_threshold_set);
            setCode(res.results[0].str_code);
            let num = res.results[0].str_code.toString();
            const size = 3;

            while (num.length < size) num = '0' + num;

            setCodeFormat(num);
            setSamples([
              {
                name: 'Set 1',
                value: res.results[0].str_answer_set_1,
                option: res.results[0].str_option_set_1,
                uuid_set: 'str_answer_set_1',
              },
              {
                name: 'Set 2',
                value: res.results[0].str_answer_set_2,
                option: res.results[0].str_option_set_2,
                uuid_set: 'str_answer_set_2',
              },
              {
                name: 'Set 3',
                value: res.results[0].str_answer_set_3,
                option: res.results[0].str_option_set_3,
                uuid_set: 'str_answer_set_3',
              },
              {
                name: 'Set 4',
                value: res.results[0].str_answer_set_4,
                option: res.results[0].str_option_set_4,
                uuid_set: 'str_answer_set_4',
              },
              {
                name: 'Set 5',
                value: res.results[0].str_answer_set_5,
                option: res.results[0].str_option_set_5,
                uuid_set: 'str_answer_set_5',
              },
              {
                name: 'Set 6',
                value: res.results[0].str_answer_set_6,
                option: res.results[0].str_option_set_6,
                uuid_set: 'str_answer_set_6',
              },
            ]);
          } else {
            setSamples(null);
            setDisabled(false);
            alert(t('threshold_coder_wrong'));
          }
        },
        error => {
          setLoading(false);
          setDisabled(false);
          setSamples(null);
          console.log(error);
        },
      );
  };

  const SamplesSets = props => {
    // props.value = escolha do usuario
    // props.option = opção correta
    const isRightAnswer = props.option === props.value;

    return (
      <div
        className={
          props.vertical
            ? tastingThreshold.samplesCategorieOptions
            : tastingThreshold.samplesCategorieOptionsHorizontal
        }
      >
        <div className={tastingThreshold.optionSet}>
          <span>{props.name}</span>
        </div>

        {options.map(option => (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              className={
                props.value === option
                  ? tastingThreshold.optionSelect
                  : tastingThreshold.optionUnselect
              }
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img
                  src={beer}
                  alt={'unlocked'}
                  height={44}
                  width={40}
                  style={{ marginLeft: '-10px' }}
                />
                <span
                  style={{
                    marginTop: '-28.5px',
                    fontWeight: '700',
                    fontSize: '16px',
                    color: '#000',
                  }}
                >
                  {option}{' '}
                </span>
              </div>
            </div>
            {!props.isExample && option === props.option
              && (timeRemaining(props.round.dat_finish) <= 0 || !props.round.bol_is_countdown)
              && (
                <div
                  style={{
                    alignItems: 'center',
                    alignSelf: 'center',
                    color: '#389E1F',
                  }}
                >
                  <Check style={{ width: '50px', height: '90px' }} />
                </div>
              )}

            {!props.isExample && props.value === option && !isRightAnswer
              && (timeRemaining(props.round.dat_finish) <= 0 || !props.round.bol_is_countdown)
              && (
                <div
                  style={{
                    alignItems: 'center',
                    alignSelf: 'center',
                    color: '#B11F24',
                  }}
                >
                  <X style={{ width: '50px', height: '90px' }} />
                </div>
              )}

            {!props.isExample &&
              option !== props.option &&
              props.value !== option && (timeRemaining(props.round.dat_finish) <= 0 || !props.round.bol_is_countdown) && (
                <div style={{ width: '50px', height: '90px' }}></div>
              )}
          </div>
        ))}
      </div>
    );
  };

  const handleBack = () => {
    if (sessionStorage.getItem('roundjump')) {
      history.goBack();
    } else {
      history.replace({
        pathname: '/', state: {
          round: location.state.round,

        }
      });
    }
  }

  return (
    <Fragment>
      <Modal
        className={tastingThreshold.Modal}
        onClick={() => setModalShowInfo(false)}
        show={modalShowInfo}
      >
        <div>
          {taste.bol_layout_horizontal ? (
            <div>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  padding: '10px 15px',
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
              >
                <button
                  style={{
                    cursor: 'pointer',
                    height: '35px',
                    width: '35px',
                    color: '#fff',
                    border: '1px solid',
                    borderRadius: '4px',
                    fontSize: '18px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#666666',
                  }}
                  onClick={() => setModalShowInfo(false)}
                >
                  <X />
                </button>
              </div>
              <CardRow
                className={tastingThreshold.cardRowModal}
                style={{ marginTop: '-30px' }}
              >
                <Text className={tastingThreshold.h3Title}>
                  {t('threshold_aml_method')}
                </Text>
              </CardRow>
              <CardColumn style={{ marginTop: '-30px', marginBottom: '-30px' }}>
                <img src={arrow} style={{ width: '100%', padding: '20px' }} />
                <Text
                  className={tastingThreshold.threshold_component_concentration}
                >
                  {t('threshold_component_concentration')}
                </Text>
              </CardColumn>
              <CardRow
                transparent
                flexWrap="wrap"
                justifyContent="center"
                style={{ flexGrow: 1, marginTop: '20px' }}
              >
                {true ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text
                      style={{
                        color: '#707070',
                        textAlign: 'center',
                        opacity: 1,
                        paddingTop: '80px',
                      }}
                    >
                      {t('threshold_sample_different_help')}
                    </Text>
                    <div
                      style={{
                        marginTop: '145px',
                      }}
                    >
                      {options.map(option => (
                        <div className={tastingThreshold.optionLeft}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                marginTop: '-28.5px',
                                fontWeight: '700',
                                fontSize: '16px',
                                color: '#000',
                              }}
                            >
                              {option}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div style={{ display: 'flex' }}>
                      {samplesExample.map(set => (
                        <SamplesSets
                          clickable={false}
                          name={set.name}
                          uuid_set={set.uuid_set}
                          value={set.value}
                          setSamples={setSamplesExample}
                          samples={samplesExample}
                          vertical={vertical}
                          round={round}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '5px',
                    }}
                  >
                    <div>
                      {samplesExample.map(set => (
                        <SamplesSets
                          clickable={false}
                          name={set.name}
                          uuid_set={set.uuid_set}
                          value={set.value}
                          setSamples={setSamplesExample}
                          samples={samplesExample}
                          vertical={!vertical}
                          round={round}
                        />
                      ))}
                    </div>
                    <div
                      style={{
                        marginTop: '15px',
                        display: 'flex',
                        flexDirection: 'row',
                        paddingLeft: '10px',
                      }}
                    >
                      {options.map(option => (
                        <div className={tastingThreshold.optionTop}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                marginTop: '-28.5px',
                                fontWeight: '700',
                                fontSize: '16px',
                                color: '#000',
                              }}
                            >
                              {option}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </CardRow>
              <CardRow
                className={tastingThreshold.cardRowModal}
                style={{ marginTop: '-30px' }}
              >
                <Text
                  style={{ color: '#707070', textAlign: 'center', opacity: 1 }}
                >
                  {t('threshold_each_set_random_order')}
                </Text>
              </CardRow>
            </div>
          ) : (
            <div className={tastingThreshold.modalShowInfo}>
              <button
                className={tastingThreshold.button}
                onClick={() => setModalShowInfo(!modalShowInfo)}
              >
                <X />
              </button>
              <img src={tuli} className={tastingThreshold.tuli}></img>
            </div>
          )}
        </div>
      </Modal>

      <CardRow flexWrap="wrap" transparent>
        <CardColumn
          className={tastingThreshold.header}
          style={{ justifyContent: 'flex-start' }}
        >
          <Button
            label={''}
            onClick={handleBack}
            className={tastingThreshold.back}
          >
            <ArrowLeft />
          </Button>
          <RoundUpAndDown round={location.state.round} setGoBack={setGoBack} />
        </CardColumn>
        <CardColumn padding="5px">
          <Label
            style={{
              textAlign: 'center',
              color: '#F49B00',
              fontSize: '1.2em',
            }}
            label={taste.int_round}
            bold
            neutral
          />
        </CardColumn>
        <CardColumn>
          <CardColumn className={tastingThreshold.countDownColumn}>
            <div className={tastingThreshold.countDownDiv}>
              {round.bol_is_countdown
                ? (
                  <CountDown
                    round={round}
                    setRound={setRound}
                    save={() => { setRound(round) }}
                  />
                ) : null}
            </div>
          </CardColumn>
        </CardColumn>
      </CardRow>
      <CardRow
        transparent
        flexWrap="wrap"
        style={{ flexGrow: 1, marginTop: '20px' }}
      >
        <CardColumn padding="5px" flex={8}>
          <TextField
            name="flt_concentration"
            label={t('code')}
            margin="10px 10px 0px 0px"
            value={codeFormat}
            onChange={evt => {
              setLoading(true);
              setCode(evt.target.value);
              delayedQuery(evt.target.value);
            }}
            text="number"
            disabled={disabled}
          />
        </CardColumn>
        <CardColumn
          style={{ alignSelf: 'center', marginTop: '30px', color: '#f49b00' }}
          onClick={() => setModalShowInfo(true)}
        >
          <HelpCircle className={{ color: '#f49b00' }} />
        </CardColumn>
      </CardRow>
      {!loading && code ? (
        samples &&
        code && (
          <>
            {round.bol_is_countdown && timeRemaining(round.dat_finish) > 0 ? (
              <CardRow transparent className={tastingThreshold.alertRow}>
                {
                  <div>
                    {
                      <span className={'iconfont ' + tastingThreshold.alert}>
                        &#xe6e4;
                      </span>
                    }
                    <span className={tastingThreshold.alertText}>
                      {' '}
                      {t('check_the_answer_at_the_end_of_the_countdown')}
                    </span>
                  </div>
                }
              </CardRow>
            ) : null}
            <CardRow
              transparent
              flexWrap="wrap"
              justifyContent="center"
              style={{ flexGrow: 1, marginTop: '20px' }}
            >
              {
                taste.bol_layout_horizontal ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        marginTop: '145px',
                      }}
                    >
                      {options.map(option => (
                        <div className={tastingThreshold.optionLeft}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                marginTop: '-28.5px',
                                fontWeight: '700',
                                fontSize: '16px',
                                color: '#000',
                              }}
                            >
                              {option}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div style={{ display: 'flex' }}>
                      {samples.map(set => (
                        <SamplesSets
                          clickable={true}
                          name={set.name}
                          uuid_set={set.uuid_set}
                          value={set.value}
                          option={set.option}
                          setSamples={setSamples}
                          samples={samples}
                          vertical={vertical}
                          round={round}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '5px',
                    }}
                  >
                    <div>
                      {samples.map(set => (
                        <SamplesSets
                          clickable={true}
                          name={set.name}
                          uuid_set={set.uuid_set}
                          value={set.value}
                          option={set.option}
                          setSamples={setSamples}
                          samples={samples}
                          vertical={!vertical}
                          round={round}
                        />
                      ))}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginLeft: '132px',
                      }}
                    >
                      {options.map(option => (
                        <div
                          style={{
                            fontWeight: '700',
                            fontSize: '16px',
                            color: '#000',
                            width: '153px',
                          }}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </CardRow>
            <CardRow
              transparent
              flexWrap="wrap"
              justifyContent="end"
              style={{ flexGrow: 1, marginTop: '20px' }}
            >
              <Button
                label={'Close Session'}
                confirm
                onClick={() => {
                  history.replace({ pathname: `/` });
                }}
              />
            </CardRow>
          </>
        )
      ) : loading ? (
        <Spinner />
      ) : (
        <CardRow
          transparent
          flexWrap="wrap"
          justifyContent="center"
          style={{ flexGrow: 1, marginTop: '20px' }}
        >
          {t('threshold_didnt_enter_the_code')}
        </CardRow>
      )}
    </Fragment>
  );
};

export default TastingDiscussionThreshold;
