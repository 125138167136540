export default {
  sample_no_production_date_message:
    'È obbligatorio selezionare una data di produzione.',
  free_comment: 'Commento libero',
  round_list_rounds: 'Round',
  round_edit_round: 'Modifica Round',
  round_new_round: 'Nuovo Round',
  round_remove_round: 'Sei sicuro di voler eliminare il round',
  round_schedule: 'Pianificazione',
  round_tasters_unselect_all: 'Deseleziona tutti i degustatori',
  round_tasters_select_all: 'Seleziona tutti i degustatori',
  round_close: 'Chiudi Round',
  round_close_message: 'Sei sicuro di voler finire il round',
  round_create_site_message: 'Stai creando un round che non è del tuo sito!',
  round_closed_message: 'I round chiusi non possono essere modificati',
  round_status_created: 'Creato',
  round_status_samples: 'Campioni',
  round_status_scheduled: 'Pianificato',
  round_status_tasters: 'Degustatori',
  round_status_in_progress: 'In corso',
  round_status_finished: 'Finito',
  round_status_closed: 'Chiuso',
  round_schedule_start_finish_date:
    'La data di inizio non può essere maggiore della data di fine!',
  round_schedule_finish_date_no_start_date:
    'Devi selezionare prima una data di inizio!',
  round_disabled_discussion_tooltip:
    'Non puoi accedere a una Discussione di un round non terminato',
  round_delete_other_users_rounds:
    'Non hai il permesso di eliminare un round che non è stato creato da te',
  round_randomize_samples_order_button: 'Mescola',
  round_block_results_tooltip:
    'Blocca i risultati di questo round in modo che non possano essere scaricati nella schermata dei report. Se il pulsante è disabilitato, non hai il privilegio di bloccare i risultati dei round da questa posizione',
  round_block_results_confirm:
    'Sei sicuro di voler bloccare/sbloccare i risultati del round',
  round_block_results_no_privilege:
    'Non hai il privilegio di bloccare/sbloccare i risultati dei round da questa posizione.',
  sample_list_samples: 'Campioni',
  sample_edit_sample: 'Modifica campione',
  sample_new_sample: 'Nuovo campione',
  sample_remove_sample: 'Rimuovi campione',
  sample_upload_document: 'Carica documenti',
  sample_download_documents: 'Documenti campione',
  sample_tasted_dates: 'Data degustazione',
  sample_upload_message: 'Per caricare documenti multipli, aggiungili a',
  sample_not_for_kpi: 'Non per KPI',
  sample_package: 'Confezione',
  sample_volume: 'Volume',
  sample_production_date: 'Data di produzione',
  sample_expire_date: 'Data di scadenza',
  sample_trial_identifier: 'Identificativo di prova',
  sample_project_name: 'Nome progetto',
  sample_linked: 'Collegato a un round',
  sample_expire_bigger_than_production:
    'La data di produzione non può essere maggiore della data di scadenza!',
  sample_no_site_message:
    'È obbligatorio selezionare una ZoneCountry e un sito per creare un campione.',
  sample_no_brand_message: 'È obbligatorio selezionare un brand.',
  sample_duplicated_kpi_message:
    "C'è già un KPI per il mese e il brand e il sito che hai selezionato e non sarai in grado di salvare",
  sample_duplicated_project_message:
    "C'è già un campione con questo nome di progetto e identificatore di prova per questo sito e brand e non sarai in grado di salvare",
  sample_project_no_name_or_trial:
    'Nome progetto e Identificativo di prova sono obbligatori quando si crea un campione di tipo Progetto',
  sample_production_to_project_confirm_message:
    'Stai cambiando il tipo di questo campione da PRODUZIONE a PROGETTO. Vuoi continuare?',
  sample_project_to_production_confirm_message:
    'Stai cambiando il tipo di questo campione da PROGETTO a PRODUZIONE. Vuoi continuare?',
  sample_no_volume_message:
    'Confezione e volume sono obbligatori quando si crea/modifica un campione.',
  sample_id_code_message: 'Includi il numero di lotto completo, per favore',
  sample_show_all_samples_hint:
    'Vengono mostrati i campioni creati fino a sei mesi fa. Seleziona questa casella se vuoi vedere campioni più vecchi',
  sample_show_all_samples_label: 'Mostra tutti i campioni',
  package_duplicate: "C'è già una confezione con questo nome",
  common_production: 'Produzione',
  common_project: 'Progetto',
  group_list_groups: 'Gruppi',
  group_edit_group: 'Modifica gruppo',
  group_new_group: 'Nuovo gruppo',
  group_remove_group: 'Rimuovi gruppo',
  group_close_modal: 'Chiudi',
  group_duplicated_user: 'Questo utente è già stato aggiunto a questo gruppo!',
  group_no_name_message: 'È necessario inserire un nome di gruppo',
  group_no_zone_message: 'È necessario selezionare una zona',
  group_no_users_message: 'È necessario selezionare almeno un assaggiatore',
  family_list_families: 'Stili',
  family_edit_family: 'Modifica stile',
  family_new_family: 'Nuovo stile',
  family_remove_family: 'Rimuovi stile',
  family_has_no_english_name:
    'Attenzione: è obbligatorio avere il nome dello stile in inglese',
  brand_list_brands: 'Brand',
  brand_edit_brand: 'Modifica Marca',
  brand_new_brand: 'Nuova Marca',
  brand_remove_brand: 'Rimuovi Marca',
  brand_profile_picture: 'Carica Foto Profilo Birra',
  brand_upload_brand_logo: 'Carica Logo Marca',
  brand_duplicate: "Attenzione: C'è una birra con questo nome",
  brand_has_no_english_name:
    'È necessario avere almeno un nome di marca e una corrispondenza di profilo di marca con esso.',
  brand_upload_help_message: 'Solo file PNG e JPG',
  brand_has_no_product_type: 'Devi selezionare un tipo di prodotto',
  package_list_packages: 'Confezioni',
  package_edit_package: 'Modifica Confezione',
  package_new_package: 'Nuova Confezione',
  package_remove_package: 'Rimuovi Confezione',
  package_has_no_english_name:
    'Attenzione: è obbligatorio avere il nome della Confezione in inglese',
  package_volumes: 'Volumi',
  package_new_volume: 'Nuovo Volume',
  package_remove_volume: 'Rimuovi Volume',
  degree_list_degrees: 'Intensità',
  degree_edit_degree: 'Modifica Intensità',
  degree_new_degree: 'Nuova Intensità',
  degree_remove_degree: 'Rimuovi Intensità',
  category_list_categories: 'Categorie',
  category_edit_category: 'Modifica Categoria Aroma',
  category_new_category: 'Nuova Categoria Aroma',
  category_remove_category: 'Rimuovi Categoria Aroma',
  comment_list_comments: 'Attributi',
  comment_edit_comment: 'Modifica Attributo Aroma',
  comment_new_comment: 'Nuovo Attributo Aroma',
  comment_remove_comment: 'Rimuovi Attributo Aroma',
  user_list_users: 'Utenti',
  user_edit_user: 'Modifica Utente',
  user_new_user: 'Nuovo Utente Terzo',
  user_export_users: 'Esporta Utenti',
  user_list_profiles: 'Profili',
  user_profile_name: 'Nome Profilo',
  user_profile_select: 'Seleziona un Profilo',
  user_profile_remove: 'Rimuovi Profilo',
  user_profile_save: 'Salva Profilo',
  user_remove_user: 'Rimuovi Utente',
  user_use_terms: 'Termini di utilizzo accettati',
  report_list_report: 'Report',
  report_end_date: 'Data di fine',
  common_privileges: 'Privilegi',
  common_abi_name: 'Nome ABI',
  common_id: 'ID',
  common_ranked: 'Classificato',
  common_week: 'Settimana',
  common_weeks: 'Settimane',
  common_days: 'Giorni',
  common_category: 'Categoria Aroma',
  common_category_sample: 'Categoria',
  common_degree: 'Intensità',
  common_comment: 'Attributo Aroma',
  common_status: 'Stato',
  common_zone: 'Zona',
  common_country: 'Paese',
  common_site: 'Sito',
  common_start_date: "Data d'inizio",
  common_finish_date: 'Data di fine',
  common_round_type: 'Tipo di Test',
  common_round: 'Round',
  common_round_id: 'ID Round',
  common_tasters: 'Degustatori',
  common_taster: 'Degustatore',
  common_sample_performance: 'Performance campione',
  common_taste: 'Gusto',
  common_key_taster: 'Degustatore chiave',
  common_sample: 'Campione',
  common_samples: 'Campioni',
  common_sample_type: 'Tipo Campione',
  common_family: 'Stile',
  common_brand: 'Marca',
  common_product_type: 'Tipo di prodotto',
  common_temperature: 'Condizione di conservazione',
  common_storage_times: 'Tempo di conservazione',
  common_sku: 'SKU',
  common_batch: 'Codice ID',
  common_kpi_month: 'KPI Mese',
  common_logo: 'Logo',
  common_name: 'Nome',
  common_notes: 'Note',
  common_email: 'E-mail',
  common_language: 'Lingua',
  common_terms_of_use: "Termini d'uso",
  common_new: 'Nuovo',
  common_profile: 'Profilo',
  common_filter: 'Filtra Utenti',
  common_download: 'Scarica',
  common_save: 'Salva',
  common_cancel: 'Cancella',
  common_search_users: 'Cerca Utenti',
  common_search_group: 'Cerca Gruppo',
  common_search_taster: 'Cerca Degustatore',
  common_no_data: 'Nessun dato da mostrare',
  common_loading: 'Caricamento in corso',
  common_cannot_be_empty: '{{field}} non può essere vuoto',
  common_select_option: 'Seleziona Opzione...',
  common_per_page: 'Righe per pagina',
  common_attributes: 'Attributi',
  common_not_started: 'Non Iniziato',
  common_in_progress: 'In Corso',
  common_finished: 'Finito',
  common_start: 'Inizia',
  common_detail: 'Dettagli',
  common_position: 'Posizione',
  common_average_key_score: 'Media punteggio chiave',
  common_score: 'Punteggio',
  common_score_scale: 'Scala punteggio',
  common_intensity: 'Intensità',
  common_report_not_have_data: 'I round selezionati non hanno voti',
  month_0: 'Gennaio',
  month_1: 'Febbraio',
  month_2: 'Marzo',
  month_3: 'Aprile',
  month_4: 'Maggio',
  month_5: 'Giugno',
  month_6: 'Luglio',
  month_7: 'Agosto',
  month_8: 'Settembre',
  month_9: 'Ottobre',
  month_10: 'Novembre',
  month_11: 'Dicembre',
  dropdown_change_picture: 'Cambia Immagine',
  dropdown_language: 'Lingua',
  dropdown_logout: 'Esci',
  round_not_started: 'Non Iniziato',
  round_in_progress: 'In Corso',
  round_finished: 'Finito',
  round_filter_start: 'Inizia',
  round_round_type_in_profile: 'In-Profilo',
  tasting_list_rounds: 'Elenco Round',
  menu_tasting_session: 'Sessione Degustazione',
  menu_panel_management: 'Gestione Pannello',
  menu_process_management: 'Gestione Processi',
  menu_product_management: 'Gestione Prodotto',
  menu_attributes_management: 'Gestione Attributi',
  tasting_finish_sample: 'Finisci Campione',
  tasting_comments: 'Commenti',
  tasting_beer_profile: 'Profilo Birra',
  tasting_need_select_score_comments:
    'Devi impostare un punteggio e aggiungere almeno un commento per continuare...',
  tasting_finish_taste: 'Finisci Gusto',
  common_no_comments: 'Nessun commento',
  review_submit_title:
    'Sei sicuro di essere pronto per inviare i tuoi risultati?',
  review_submit_description: 'Non sarai in grado di modificare in seguito',
  review_finish_taste: 'Finito Gusto',
  review_warning_sample_title: 'Hai campioni non degustati!',
  review_warning_sample_description: 'Desideri continuare senza assaggiarli?',
  review_warning_sample_review: 'Recensione',
  review_warning_sample_ok: 'Continua',
  review_warning_tie_sample_title: 'Hai campioni a pari merito!',
  review_warning_tie_sample_description:
    'Non puoi continuare senza classificarli!',
  review_warning_tie_sample_review: 'Recensione',
  common_submit: 'Invia',
  common_discussion: 'Discussione',
  common_not_informed: 'Non informato',
  discussion_order_number: 'Ordina per Posizione',
  discussion_order_brewery: 'Ordina per Birrificio',
  discussion_order_ranking: 'Ordina per Classifica',
  discussion_order_brand: 'Ordina per Marca',
  discussion_order_score: 'Ordina per Punteggio',
  discussion_order_key_score: 'Ordina per Punteggio Chiave',
  discussion_order_overall_rank: 'Ordina per Classifica Totale',
  discussion_order_key_rank: 'Ordina per Classifica Chiave',
  discussion_export_error: 'Il file non può essere salvato!',
  discussion_ranking: 'Classifica',
  discussion_overall_ranking: 'Classifica Totale',
  discussion_key_ranking: 'Classifica Chiave',
  common_brewery: 'Birrificio',
  common_trial_identifier: 'ID Prova',
  common_key_score: 'Punteggio Chiave',
  round_report: 'Esporta in Excel',
  common_comments: 'Commenti',
  message_you_cannot_delete_all_comments:
    'Non puoi eliminare tutti i commenti durante la modifica di un voto',
  common_message_empty_profile:
    'Il nome del profilo non è consentito vuoto o in bianco',
  common_message_out_score_range: 'Punteggio fuori scala',
  tasting_need_select_score: 'Devi prima impostare un punteggio!',
  common_category_name: 'Categoria',
  categories_new_category: 'Nuova categoria',
  categories_remove_category: 'Vuoi davvero rimuovere la categoria',
  categories_list_categories: 'Categorie',
  categories_list_all_categories: 'Tutte le categorie',
  common_category_sample_name: 'Campione',
  categories_new_category_sample: 'Nuovo campione',
  categories_remove_category_sample: 'Vuoi davvero rimuovere il campione',
  categories_list_categories_samples: 'Campioni',
  tasting_help: 'Aiuto alla degustazione',
  common_back: 'Indietro',
  common_next: 'Avanti',
  common_sample_id: 'ID campione',
  common_sample_date: 'Data campione',
  common_duplicate: 'Duplica',
  tasting_raw_materials: 'Materie prime',
  tasting_example_sensory_risk:
    'Esempio di principale rischio sensoriale: non conformità con il gusto previsto',
  tasting_procedure: 'Processo',
  tasting_sample_preparation: 'Preparazione del campione',
  tasting_help_comments: 'Commenti',
  common_collection_point: 'Punto di raccolta',
  common_collection_date: 'Data di raccolta',
  common_brew_number: 'Numeri di Cotta/MES',
  common_need_explain_nok: 'Non puoi avere campioni NOK senza un commento!',
  common_percent_ok: 'Percentuale OK',
  common_show_all: 'Mostra tutto',
  common_import: 'Importazione',
  common_download_template: 'Scarica il modello',
  sample_import_message: 'xlsx',
  common_template: 'Modello',
  common_yes: 'Sì',
  common_no: 'No',
  common_need_volte_first:
    'Non puoi commentare i campioni senza prima assegnarli un voto!',
  common_name_template: 'Nome del modello',
  interaction_log: 'Registro di interazione',
  common_message_zone_empty: 'Zona non consentita vuota o in bianco',
  common_message_country_empty: 'Paese non consentito vuoto o in bianco',
  common_message_site_empty: 'Sito non consentito vuoto o in bianco',
  user_new_profile_save: 'Nuovo profilo creato',
  common_in_privileges: 'in privilegi',
  common_download_files: 'Scarica file',
  pdf_viewer: 'Visualizzatore PDF',
  common_all: 'Tutti',
  common_continue: 'Continua',
  common_minimum_score: 'Min',
  common_maximum_score: 'Max',
  common_average_score: 'Media',
  file_upload_fail:
    'Impossibile caricare il file. Si sono verificati i seguenti errori:',
  file_upload_success_with_error:
    'Caricamento avvenuto con successo tranne che per il seguente:',
  missing_fields: 'campi mancanti',
  column_error: 'errore di colonna',
  common_line: 'Linea',
  file_no_samples: 'Nessun campione trovato nel file!',
  file_success_upload: 'File caricato con successo!',
  file_duplicated_kpi_project: 'KPI o Progetto duplicati',
  round_schedule_maximum_length:
    'La lunghezza massima del nome del turno è di 40 caratteri.',
  sample_malt_varieties: 'Varietà di malto',
  error_sample_already_in_round: 'Il campione è già nel turno',
  malt_varieties_has_no_english_name:
    'Devi avere almeno un nome di varietà di malto.',
  malt_varieties_duplicate:
    "Attenzione: c'è una varietà di malto con questo nome",
  malt_varieties_remove: 'Rimuovi varietà',
  malt_varieties_new: 'Nuova varietà',
  reports_no_dates: 'Data di inizio e data di fine obbligatorie!',
  reports_date_range_message:
    "L'intervallo di date non può essere più grande di 1 anno!",
  round_save_select_site: 'Per salvare un turno devi selezionare un sito',
  common_error: 'Oops! Qualcosa è andato storto, riprova per favore.',
  common_horizontal: 'Layout orizzontale',
  common_unit: 'Unità',
  common_supplier: 'Fornitore',
  common_flavor: 'Sapore',
  concentration_of_the_base_beer: 'Concentrazione della birra base',
  threshold_aml_method: 'Metodo AML (Metodo di limiti ascendenti)',
  threshold_component_concentration: 'Concentrazione del componente',
  threshold_sample_different_help: 'Quale campione è diverso?',
  threshold_each_set_random_order: 'In ogni insieme ordine casuale.',
  threshold_select_base_beer: 'Seleziona Birra di Base',
  common_unity: 'Unità',
  common_finish_session: 'Finisci sessione',
  review_submit_threshold: 'Sei sicuro di voler salvare?',
  threshold_coder_wrong:
    'Il codice inserito è errato. Inserire il codice corretto.',
  threshold_didnt_enter_the_code: 'Si prega di inserire il codice',
  code: 'Codice',
  common_batch_code: 'Codice lotto',
  threshold_samples_required: 'Birra di base e sapore sono richiesti',
  threshold_information_helper_sample:
    'Per salvare le informazioni sul campione di soglia limite fare clic sul pulsante Salva',
  code_digits: 'Inserisci il codice di 3 cifre che ti è stato assegnato',
  threshold_sample_spiked_with:
    'In ogni set scegliere il campione che è stato addizzionato con',
  threshold_code_already_tasted:
    'Questo codice è già stato assaggiato da un altro utente. Inserisci un altro codice.',
  common_kpi_brands: 'KPI Brands',
  common_year: 'Anno',
  common_new_kpi_brand: 'Nuovo brand KPI',
  common_action: 'Azione',
  common_comment_by_category: 'Commenti per categoria',
  common_alphabetic_comment: 'Commenti alfabetici',
  common_name_pts_code: 'Codice PTS',
  common_name_pts_code_placeholder: 'Codice',
  common_name_process_item: 'Elemento di processo',
  common_name_process_item_placeholder: 'Elemento',
  common_global_brands: 'Brand globali',
  common_global_brand: 'Brand globale',
  common_target_global_brand: 'target per il brand globale',
  common_focus_brand: 'Brand di focus',
  common_target_focus_brand: 'target per il brand di focus',
  common_strategic_brand: 'Brand strategico',
  common_target_strategic_brand: 'target per il brand strategico',
  common_maximun_sites: 'Il numero massimo di siti da confrontare è',
  common_state_reason_rejecting:
    'Indicare il motivo del rifiuto per questo sito',
  common_rejected_note: 'Nota di rifiuto',
  report_list_report_kpi: 'Rapporti KPI',
  report_list_report_taste: 'Rapporti di degustazione',
  select_brand_to_filter: 'Seleziona un brand globale!',
  select_year_to_filter: 'Seleziona un anno!',
  kpi_only_numbers_warning: 'Accettare solo numeri sui valori Target',
  kpi_max_number_range_warning:
    'Il tuo brand target deve essere compreso tra 1 e 9',
  kpi_already_selected_brand_warning:
    'Hai questo brand selezionato, per favore scegli un altro brand',
  kpi_no_kpi_informed_warning:
    'Non è stato inserito alcun valore KPI. Aggiungere il valore.',
  kpi_select_year_warning: 'Seleziona un anno',
  kpi_select_site_warning: 'Seleziona un sito',
  kpi_existing_configuration_warning: 'Hai valori kpi dallo stesso sito e anno',
  kpi_target_empty_warning: 'Un target di uno dei tuoi brand è vuoto',
  kpi_saved_successfully: 'I tuoi valori sono stati salvati con successo!',
  kpi_submitted_successfully: 'I tuoi valori sono stati inviati con successo!',
  kpi_max_decimals_warning:
    'Il tuo valore target deve essere fino a 3 decimali',
  kpi_count_as_kpi_sample: 'Contare come KPI',
  samples_uncheck_kpi_sample_tooltip:
    'Deseleziona questa casella se non vuoi che questo campione conti nel KPI',
  reports_mandatory_zone_filter: 'Selezionare almeno una zona',
  common_approve_reject: 'Approvare / Rifiutare',
  common_approve: 'Approvare',
  submission_type: 'Tipo di invio',
  custom_scale_title: 'Scala personalizzata',
  custom_scale_grid_header_score: 'Punteggio',
  custom_scale_grid_header_ageind_scale: 'Scala di invecchiamento forzata',
  the_value_cannot_be_null: 'Il valore non può essere nullo!',
  unique_id: 'ID univoco',
  report_filter_rounds_description:
    'Devi selezionare i campi obbligatori per caricare le opzioni dei round.',
  reports_mandatory_test_type: 'Seleziona il tipo di test.',
  round_reports_month_warning:
    'Il periodo tra la data di inizio e la data di fine deve essere al massimo di 6 mesi.',
  zone_can_not_empty: 'La zona non può essere vuota!',
  error_loading_data:
    'Errore durante il caricamento dei dati. Si prega di controllare la connessione e ricaricare.',
  flavor_identification: 'Riconoscimento del sapore',
  beer_batch: 'Lotto di birra',
  list_of_beers: 'Elenco delle birre',
  random: 'Casuale',
  preview: 'Anteprima',
  select_the_flavor: 'Seleziona il sapore',
  flavor_threshold: 'Soglia di percezione del sapore',
  input_digital_number: 'Inserisci il numero digitale...',
  concentration: 'Concentrazione',
  flavor_order: 'Ordine sapori:',
  last: 'Ultimo',
  tasting: 'Degustazione',
  confirmation: 'Conferma',
  do_you_confirm_the_evaluation_of_this_sample:
    'Confermi la valutazione di questo campione?',
  confirm: 'Confermare',
  evaluate: 'Valutare',
  correct_answer: 'Risposta corretta',
  threshold: 'Soglia di percezione',
  let_a_comment_about_the_tasting: 'Lascia un commento sulla degustazione',
  send: 'Invia',
  right_now: 'Adesso',
  minute_ago: 'min fa',
  hour_ago: 'h fa',
  day_before: 'giorni fa',
  year_ago: 'anni fa',
  add_a_flavor: 'Aggiungi un sapore',
  soft_drinks: 'Bevande analcoliche',
  soft_drinks_brand: 'Marca di bevande analcoliche',
  soft_drinks_brand_target: 'Target di marca di bevande analcoliche',
  column_options: 'Opzioni di colonna',
  select_which_of_the_columns_do_you_want_to_be_displayed:
    'Seleziona quali colonne vuoi visualizzare.',
  countdown: 'Conto alla rovescia',
  less_than_five_minutes:
    "Questa sessione di degustazione terminerà tra 5 minuti, se devi procedere, contatta l'amministratore per posticipare la data di fine.",
  the_countdown_to_the_evaluation_has_ended:
    'Non puoi accedere poiché il conto alla rovescia di questa sessione di degustazione è terminato',
  counting_how_much_time_does_you_have_to_finish_tasting_the_samples:
    'Conteggio del tempo rimasto per finire la degustazione dei campioni',
  only_six_flavors_can_be_added_at_most:
    'Possono essere aggiunti al massimo 6 sapori!',
  concentration_of_the_flavor: 'Concentrazione del sapore',
  input_value: 'Per favore inserisci',
  Concentration_of_the_flavor_is_required:
    'La concentrazione del sapore è richiesta',
  previous_round: 'Round precedente',
  next_round: 'Prossimo Round',
  the_end_time_is_greater_than_the_current_time:
    "L'ora di fine deve essere maggiore dell'ora attuale, se selezioni lo stesso giorno di oggi, seleziona prima l'ora e i minuti!",
  check_the_answer_at_the_end_of_the_countdown:
    'Per favore aspetta che il conto alla rovescia raggiunga lo zero per verificare il risultato.',
  incremental_step: 'Passo incrementale',
  set3_concentration: 'Set3 Concentrazione',
  concentration_increments_between_levels:
    'Incremento di concentrazione tra i livelli',
  incremental_set3_concentration_required:
    'Passo incrementale e concentrazione Set3 sono richiesti',
  sprint: 'Sprint',
  sprint_number_greater_than_zero:
    'Il numero di sprint deve essere maggiore di 0',
  sprint_notes_at_least_one_note:
    'Le note di sprint devono avere almeno una nota',
  sprint_patch_notes_success_update:
    'Note di patch di sprint aggiornate con successo.',
  sprint_patch_notes_error_update: 'Non è stato possibile salvare le note.',
  update_sprint_patch_notes: 'Aggiorna le note di patch dello sprint',
  sprint_number: 'Numero di sprint',
  sprint_patch_notes: 'Note di patch dello sprint',
  note: 'Nota',
  concentration_increments_between_levels:
    'Incremento di concentrazione tra i livelli',
  incremental_set3_concentration_required:
    'Passo incrementale e concentrazione Set3 sono richiesti',
  select_all: 'Seleziona tutto',
  please_add_samples_first: 'Per favore aggiungi prima i campioni',
  concentration_increments_between_levels:
    'Incremento di concentrazione tra i livelli',
  incremental_set3_concentration_required:
    'Passo incrementale e concentrazione Set3 sono richiesti',
  reverse: 'Inverti',
  score_scale: 'Scala Ab InBev',
  description: 'Descrizione',
  aditional_criteria: 'Criteri aggiuntivi',
  new_test: 'Nuovo test',
  triangle_test: 'Test triangolare',
  triangle_test_name: 'Nome del test triangolare',
  you_can_not_set_three_identical_samples:
    'Non puoi impostare 3 campioni identici',
  its_mandatory_to_input_test_name: 'È obbligatorio inserire il nome del test.',
  its_mandatory_to_input_sample_information:
    'È obbligatorio inserire le informazioni sul campione.',
  attention: 'Attenzione',
  test_number: 'Numero del test',
  you_selected_the: 'Hai selezionato il',
  next_sample: 'Prossimo campione',
  remove_triangle_test: 'Rimuovi il test triangolare',
  test: 'Test',
  confirm_remove: 'Confermi di eliminare questo test?',
  you_can_only_select_up_to_five: 'Puoi selezionare solo fino a 5 varietà.',
  reports_mandatory_zone_country_site_filter:
    'Zona Paese Sito sono obbligatorie!',
  reports_mandatory_brand_filter: 'Seleziona almeno un brand',
  download_label: 'Scarica etichetta',
  reports_by_producing_site: 'Report per sito di produzione',
  standard: 'Standard',
  input_test: 'Inserisci Test',
  detecting: 'Rilevamento···',
  connected: 'Connesso',
  current_taster: 'Degustatore corrente',
  alcohol_concentration: 'Concentrazione di alcol',
  your_alcohol_test_result_exceeds_standard_please_test_again_later:
    'Il tuo risultato del test di alcol supera lo standard. Riprova più tardi!',
  alcohol_test_qualified: 'Test di alcol qualificato',
  configuration: 'Configurazione',
  input_result: 'Inserisci risultato',
  maximum_alcohol_concentration: 'Concentrazione alcolica in eccesso',
  breathalyzer: 'Alcolometro',
  please_input_a_positive_number_up_to_n_decimal_places:
    'Inserisci un numero positivo fino a {{field}} cifre decimali',
  you_must_input: 'Devi inserire',
  driving: 'Guida',
  test_time: 'Tempo di prova',
  input_type: 'Tipo di input',
  list_breathalyzer: 'Alcolometro',
  Manual: 'Manuale',
  Breathalyzer: 'Alcolometro',
  you_do_not_have_privilege_to_export_this_report:
    'Non hai il privilegio di esportare questo rapporto',
  competitor_beer: 'Birra concorrente',
  split_round: 'Dividere il Round',
  reset: 'ripristina',
  how_many_rounds_splitting_do_you_want_to_make:
    'Quanti round di divisione vuoi effettuare',
  you_cannot_split_a_round_that_has_already_been_split_or_in_progress:
    'Non puoi dividere un round che è già stato diviso o è in corso di svolgimento',
  hide_sample_details: 'Nascondi Dettagli Campione',
  data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id:
    'I dati saranno nascosti agli assaggiatori: birreria, Data di produzione, marca, varietà di malto e identificazione del campione',
  you_can_not_add_duplicate_attributes:
    'Non è possibile aggiungere gli attributi duplicati',
  menu: 'MENU',
  link_breathalyzer: 'Connesso',
  not_link_breathalyzer: 'Non collegato',
  breathalyzer_to_link: 'Collega il respiratore',
  breathalyzer_can_not_to_link_for_this_browser:
    'La capacità del respiratore è sviluppata per funzionare su Google Chrome, utilizzando dispositivi PC.',
  reopen: 'Riaprire',
  round_reopen_message: 'Sei sicuro di voler riaprire il Round',
  common_production_line: 'Linea di produzione',
  common_packaged_beer_data_code: 'Codice dati sulla birra confezionata',
  common_masking_process_for_bottles: 'Processo di mascheramento per bottiglie',
  common_str_number_days_over_aged: 'Numero di giorni di età superiore',
  common_over_aged_beer: 'Birra invecchiata',
  sample_successfully_swapped: 'Campione scambiato con successo.',
  scoring: 'Punteggi',
  configure_available_flavors: 'Configura gli aromi disponibili',
  process_check: 'Controllo processo',
  new_process_check: 'Nuovo controllo del processo',
  edit_process_check: 'Modifica controllo processo',
  remove_process_check: 'Rimuovi controllo processo',
  common_package: 'Pacchetto',
  common_department: 'Dipartimento',
  common_shift: 'Shift',
  common_tank: 'Serbatoio',
  common_oknok: 'OKNOK',
  common_remark: 'Osservazione',
  show_all_process_check: 'Mostra tutti i controlli di processo',
  show_all_process_check_help_circle:
    'Per visualizzare i dati creati in un anno e mezzo a partire da oggi, è opportuno scegliere questa casella',
  the_sample_date_cannot_be_earlier_than_the_current_time:
    "La data del campione non può essere anteriore all'ora corrente!",
  common_job_position: 'Posizione lavorativa',
  decimal_sep: 'separatore decimale',
  is_swapped_to: 'Sont échangés pour',
  review_warning_comment_title: 'Avete campioni non commentati!',
  continuous_typing: 'Tipizzazione continua',
  continuous_typing_tips:
    'Digitare continuamente "Attributi" dopo aver selezionato/digitato "Intensità".',
  BSSI_Reports: 'BSSI relazioni',
  data_analysis_sip: 'Analisi dei dati - SIP',
  select_the_color: 'Seleziona il colore',
  Color: 'colore',
  download_document: 'Download file',
  Month_of_Discontinued_Production: 'Mesi di sospensione della produzione',
  Suspension_List: 'Elenco degli arresti di produzione',
  New_Suspension: 'Nuove domande di cessazione della produzione',
  new_suspension_page: 'Nuove informazioni sull’interruzione della produzione',
  edit_suspension_page:
    'Redazione di informazioni sull’interruzione della produzione',
  Brand_name: 'Nome del marchio',
  List_Brewery_Production_Suspension:
    'Elenco degli stabilimenti esclusi dalla produzione',
  Upload_successfully: 'Il successo del caricamento di file',
  training_materials: 'Fai clic per vedere i materiali di allenamento.',
  Remove_Suspension: 'Rimuovi sospensione: ',
  Add_Absence_Reason: 'Aggiungi motivo di assenza',
  Reason: 'motivo',
  You_have_duplicate_absence_dates: 'Le date di assenza sono doppie',
  Remove_Absence: 'Rimuovi assenza: ',
  Absence_Title: 'Seleziona il tempo e il motivo dell’assenza di assaggio',
  Shipping_Site: 'Luogo di spedizione',
  shipping_received: 'Spedizione ricevuta',
  receive_sample_alert: 'Siete sicuri di aver ricevuto il campione?',
  receive_uncheck_alert: 'Sei sicuro di volerlo deselezionare?',
  all_received: 'Tutti ricevuti',
  not_all_received: 'Non tutti ricevuti',
  not_for_shipping: 'Non per navigazione',
  receive_hint: 'Selezionare tutti i siti che riceveranno questo campione',
  absence_reason: 'Motivo dell’assenza',
  Page1: 'Pagina {{field}}',
  Search: 'cerca',
  page_total: 'Numero totale',
  start_date: "Data d'inizio",
  end_date: 'Tipo di Test',
  ok_for_all: 'OK per tutti',
  its_mandatory_to_select_sample_A_and_sample_B:
    'È obbligatorio selezionare il campione A e il campione B.',
  its_mandatory_to_select_sample_A: 'È obbligatorio selezionare il campione A.',
  its_mandatory_to_select_sample_B: 'È obbligatorio selezionare il campione B.',
  triangle_comment: 'commenti',
  select_sample: 'selezionare il campione',
  sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration:
    'Filtro Data di esempio, ultimi 7 giorni di default, esportazione in Excel in base a questa durata filtrata.',
  select_all_flavor: 'Seleziona tutto',
  deselect_all: 'Deseleziona tutto',
  the_score_entered_is_invalid:
    'Il punteggio inserito non è valido. Si prega di fare riferimento alla scala di punteggio e inserire nuovamente il punteggio.',
  service_now:
    'Fai clic per creare un biglietto incidente per segnalare un problema e chiedere aiuto.',
  service_now_tips:
    'Ehi, se riscontri qualche problema o vuoi chiedere supporto puoi cliccare qui e segnalarlo direttamente in serviceNow, il nostro team di supporto ti contatterà il prima possibile.',
};
