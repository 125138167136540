import React, { useState } from 'react';
import CodeFlavorStyle from './CodeFlavor.module.scss';
import { useHistory } from 'react-router-dom';
import { Button, TextField, CardColumn } from '../../../components';
import { useTranslation } from 'react-i18next';
import { ArrowLeft } from 'react-feather';
import RoundAppService from '../../services/rounds/roundApp.service';
import { timeRemaining } from '../../../utils/timeRemaining';
import moment from 'moment';

export default function CodeFlavor(props) {
  const { t } = useTranslation();
  const roundAppService = new RoundAppService();
  const [code, setCode] = useState('');
  const history = useHistory();
  const changeCode = e => {
    setCode(e.target.value);
  };
  const timeNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  const Save = () => {
    roundAppService
      .flovarCode({
        uuid_round: props.location.state.round.uuid_round,
        code: code - 0,
      })
      .then(res => {
        if (res.type === 'success') {
          if (!props.location.state.round.bol_started) {
            roundAppService.updateStart(props.location.state.round, timeNow);
          }
          history.replace({
            pathname: `/tasting_flavor_session`,
            state: {
              round: props.location.state.round,
              code: code,
            },
          });
        } else {
          window.alert(res.message);
        }
      });
  };
  const element = () => {
    return document.querySelector('input[name="code_number"]')?.value.length;
  };

  const formatCodeInputValue = value => {
    return value.replace(/^(1-9)|[^\d]+/g, '');
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className={CodeFlavorStyle.main}>
      <CardColumn
        className={CodeFlavorStyle.header}
        style={{ justifyContent: 'flex-start' }}
      >
        <Button
          label={''}
          onClick={() => handleBack()}
          className={CodeFlavorStyle.back}
        >
          <ArrowLeft />
        </Button>
      </CardColumn>
      <div className={CodeFlavorStyle.date}>
        {props.location.state.round.int_round}
      </div>
      <div>
        <TextField
          name="code_number"
          labelStyle={{ fontWeight: 600, fontSize: '18px', marginLeft: '15px' }}
          label={t('code')}
          style={{ height: '45px', width: '85%', marginLeft: '15px' }}
          value={code}
          onHintEnter={Save}
          placeholder={t('code_digits')}
          onChange={e => changeCode(e)}
          onkeyup={true}
          formatValue={formatCodeInputValue}
        />
      </div>
      <div className={CodeFlavorStyle.button}>
        <Button
          onClick={Save}
          label={t('common_save')}
          style={
            element() === 3
              ? {
                  color: '#fff',
                  background: '#F49B01',
                  width: '130px',
                  height: '40px',
                }
              : {
                  color: '#eee',
                  background: '#ccc',
                  width: '130px',
                  height: '40px',
                }
          }
          disabled={element() === 3 ? false : true}
        ></Button>
      </div>
    </div>
  );
}
