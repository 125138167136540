import React, { useState, useEffect } from 'react';
import listReportsStyle from './listReportsProfile.module.scss';
import {
  Label,
  Card,
  CardRow,
  DropdownMultiField,
  Button,
  CardColumn,
  DatePickerField,
} from '../../../components';
import Spinner from '../../../components/spinner/Spinner';

import _ from 'lodash';
import useListReportTasting from '../listReportsTasting/hooks.js';
import { useUser } from '../../../contexts/User';

const ListReportsProfile = props => {
  const { user } = useUser();

  const {
    t,
    privilege,
    rounds,
    setRounds,
    brands,
    filter,
    setFilter,
    loading,
    setLoading,
    getPrivilegies,
    loadingRounds,
    handleChange,
    handleChangeDates,
    handleDownload,
    loadFamilies,
    loadBrands,
    loadRoundTypes,
    lastChange,
    setDisabledDownload,
    loadProductRound,
    LoadDecimalSymbol,
    listDecimalSymbol,
  } = useListReportTasting();

  useEffect(() => {
    if (user.uuid_zone) {
      setFilter({
        ...filter,
        arr_zones: [
          {
            str_name: user.str_name_zone,
            uuid_zone: user.uuid_zone,
          },
        ],
      });
    }
    if (user.uuid_zone && user.uuid_country) {
      setFilter({
        ...filter,
        arr_zones: [
          {
            str_name: user.str_name_zone,
            uuid_zone: user.uuid_zone,
          },
        ],
        arr_countries: [
          {
            str_name: user.str_name_country,
            uuid_country: user.uuid_country,
            uuid_zone: user.uuid_zone,
          },
        ],
      });
    }
    if (user.uuid_zone && user.uuid_country && user.uuid_site) {
      setFilter({
        ...filter,
        arr_zones: [
          {
            str_name: user.str_name_zone,
            uuid_zone: user.uuid_zone,
          },
        ],
        arr_countries: [
          {
            str_name: user.str_name_country,
            uuid_country: user.uuid_country,
            uuid_zone: user.uuid_zone,
          },
        ],
        arr_sites: [
          {
            str_name: user.str_name_site,
            uuid_site: user.uuid_site,
            uuid_country: user.uuid_country,
          },
        ],
      });
    }
    LoadDecimalSymbol();
  }, []);
  useEffect(() => {
    if (
      filter.arr_zones &&
      filter.dat_end &&
      filter.dat_start &&
      filter.arr_sites &&
      filter.arr_countries &&
      filter.arr_brands &&
      !rounds.length
    ) {
      setDisabledDownload(true);
    } else {
      setDisabledDownload(false);
    }
  }, [filter, rounds]);

  useEffect(() => {
    setLoading(true);
    getPrivilegies();
    loadFamilies();
    loadBrands();
    loadRoundTypes();
  }, []);

  useEffect(() => {
    if (
      filter.arr_zones &&
      filter.dat_end &&
      filter.dat_start &&
      filter.arr_sites &&
      filter.arr_countries &&
      filter.arr_brands
    ) {
      if (lastChange !== 'arr_rounds') {
        if (filter?.arr_rounds?.length) {
          setFilter({ ...filter, arr_rounds: [] });
        }
        setRounds([]);
        loadProductRound(props?.state);
      }
    }
  }, [filter, lastChange]);

  return (
    <div className={listReportsStyle.pageContainer}>
      <Label label={t('report_list_report')} bold neutral />

      <Card bordered>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <CardRow flexWrap="wrap" padding="0px 0px 10px 0px">
              <CardColumn padding="5px">
                <CardRow flexWrap="wrap">
                  <DropdownMultiField
                    name="arr_zones"
                    options={privilege.arr_zones ? privilege.arr_zones : []}
                    valueField="uuid_zone"
                    labelField="str_name"
                    label={t('common_zone')}
                    margin="10px 10px 0px 0px"
                    value={filter.arr_zones}
                    onChange={value => {
                      filter.arr_countries = null;
                      filter.arr_sites = null;
                      handleChange(value);
                    }}
                    autoSelect={false}
                    isRequired={true}
                  />
                </CardRow>
                <CardRow flexWrap="wrap">
                  <DatePickerField
                    name="dat_start"
                    type={'date'}
                    label={t('common_start_date')}
                    margin="10px 10px 0px 0px"
                    value={filter.dat_start}
                    onChange={handleChangeDates}
                    isRequired={true}
                    showHint
                    hintText={t('round_reports_month_warning')}
                  />
                </CardRow>
              </CardColumn>

              <CardColumn padding="5px">
                <CardRow flexWrap="wrap">
                  <DropdownMultiField
                    name="arr_countries"
                    options={
                      privilege.arr_countries && filter.arr_zones
                        ? privilege.arr_countries.filter(country => {
                            return filter.arr_zones.some(zone => {
                              return country.uuid_zone === zone.uuid_zone;
                            });
                          })
                        : []
                    }
                    valueField="uuid_country"
                    labelField="str_name"
                    label={t('common_country')}
                    margin="10px 10px 0px 0px"
                    value={filter.arr_countries}
                    onChange={value => {
                      filter.arr_sites = null;
                      handleChange(value);
                    }}
                    autoSelect={false}
                    isRequired={true}
                  />
                </CardRow>
                <CardRow flexWrap="wrap">
                  <DatePickerField
                    name="dat_end"
                    type={'date'}
                    label={t('report_end_date')}
                    margin="10px 10px 0px 0px"
                    value={filter.dat_end}
                    onChange={handleChangeDates}
                    isRequired={true}
                    showHint
                    hintText={t('round_reports_month_warning')}
                  />
                </CardRow>
              </CardColumn>

              <CardColumn padding="5px">
                <CardRow flexWrap="wrap">
                  <DropdownMultiField
                    name="arr_sites"
                    options={
                      privilege.arr_sites && filter.arr_countries
                        ? privilege.arr_sites.filter(site => {
                            return filter.arr_countries.some(country => {
                              return site.uuid_country === country.uuid_country;
                            });
                          })
                        : []
                    }
                    valueField="uuid_site"
                    labelField="str_name"
                    label={t('common_site')}
                    margin="10px 5px 0px 0px"
                    value={filter.arr_sites}
                    onChange={handleChange}
                    autoSelect={false}
                    isRequired={true}
                  />
                </CardRow>
                <CardRow flexWrap="wrap">
                  <DropdownMultiField
                    name="arr_brands"
                    options={
                      !_.isEmpty(filter.arr_families)
                        ? brands.filter(brand => {
                            return filter.arr_families.some(family => {
                              return (
                                brand.uuid_brand_family ===
                                family.uuid_brand_family
                              );
                            });
                          })
                        : brands
                    }
                    valueField="uuid_brand"
                    labelField="str_name"
                    label={t('common_brand')}
                    margin="10px 10px 0px 0px"
                    value={filter.arr_brands}
                    onChange={handleChange}
                    autoSelect={false}
                    isRequired={true}
                  />
                </CardRow>
              </CardColumn>
            </CardRow>
            <div>
              <CardRow flexWrap="wrap">
                <DropdownMultiField
                  name="arr_rounds"
                  options={rounds}
                  valueField="uuid_round"
                  labelField="str_int_name_round"
                  label={t('common_round')}
                  margin="10px 10px 0px 0px"
                  value={filter.arr_rounds}
                  onChange={handleChange}
                  bolOrder={false}
                  autoSelect={false}
                  selsctAll={true}
                  showHint
                  hintText={t('report_filter_rounds_description')}
                  isLoading={loadingRounds}
                  disabled={!rounds.length}
                />
              </CardRow>
              <CardRow justifyContent="flex-end" flexWrap="wrap">
                <Button
                  style={{ zIndex: 0 }}
                  label={t('common_download')}
                  onClick={() => {
                    handleDownload('profile');
                  }}
                  confirm
                  disabled={
                    (filter.arr_round_types ===
                      '0d55fca0-e7a2-11ec-a8bc-000000000000' &&
                      user.arr_privileges.some(item => {
                        return item === 'FLAVORID_REPORT_LIST';
                      })) ||
                    filter.arr_round_types !==
                      '0d55fca0-e7a2-11ec-a8bc-000000000000'
                      ? false
                      : true
                  }
                  margin="35px 10px 0px 0px"
                />
              </CardRow>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default ListReportsProfile;

