import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { CardRow } from '../';
import sliderStyle from './slider.module.scss';
import _ from 'lodash';
import configs from '../../configs';

const Slider = props => {
  const [range, setRange] = useState([]);
  const [fillRange, setFillange] = useState({});
  const [currentValue, setCurrentValue] = useState(
    props.currentScore ? props.currentScore : props?.min,
  );

  const handleOnClick = objScore => {
    props.onClick(objScore);
  };

  //const delayedQuery = useRef(_.debounce(objScore => handleOnClick(objScore), configs.defaultDebounce)).current;
  const delayedQuery = useRef(
    _.debounce(objScore => handleOnClick(objScore), 250),
  ).current;

  useEffect(() => {
    refreshState();
  }, [props.currentScore]);

  const refreshState = () => {
    let arrayTemp = [];
    for (let i = props.min; i <= props.max; i = i + props.step) {
      arrayTemp.push({
        value: i,
      });
    }
    setRange(arrayTemp);
    setFillange(
      handleCalcValuesToGradient(
        props.currentScore ? props.currentScore : props.min,
      ),
    );
    setCurrentValue(props.currentScore ? props.currentScore : props.min);
  };

  const handleChangeScore = e => {
    let score = Number(e.target.value);
    setFillange(handleCalcValuesToGradient(score));
    setCurrentValue(score);
    let objScore = {
      score: score,
      min: props.min,
      max: props.max,
      step: props.step,
      ...props.funcData,
    };
    delayedQuery(objScore);
  };

  const handleCalcValuesToGradient = score => {
    let variation = Number((props.max - props.min) / props.step);
    let scoreVariation = Number((score - props.min) / props.step);
    let max,
      min = 0;
    if (variation / 2 < scoreVariation) {
      min = Number((scoreVariation * 100) / variation);
      max = Number((scoreVariation * 100) / variation + 1);
    } else {
      min = Number((scoreVariation * 100) / variation + 0.5);
      max = Number((scoreVariation * 100) / variation + 1.5);
    }
    return {
      min: min,
      max: max,
    };
  };

  const getStyleBack = () => {
    let style = '';
    if (fillRange.min == 100) {
      style =
        '-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(1, rgb(244, 156, 0)), color-stop(1, rgb(247, 247, 247)))';
    } else {
      style = `-webkit-gradient(linear,left top,right top,color-stop(${fillRange.min}%, #F49C00),color-stop(${fillRange.max}%, #F7F7F7))`;
    }
    return style;
  };

  return (
    <Fragment>
      <CardRow style={{ width: '100%', flexDirection: 'column' }}>
        <input
          id="typeinp"
          data-testid="sliderInput"
          type="range"
          min={props.min ? props.min : 1}
          max={props.max ? props.max : 10}
          value={currentValue}
          step={props.step ? props.step : 1}
          onChange={e => handleChangeScore(e)}
          list="steplist"
          style={{
            backgroundImage: getStyleBack(),
          }}
        />
        {props.datalist ? (
          <p className={sliderStyle.datalist} id="steplist">
            {range.map(step => (
              <span
                className={sliderStyle.option}
                data-testid="optionList"
                key={step.value}
                label={step.value % 1 === 0 ? step.value : ''}
                value={step.value}
              >
                {step.value % 1 === 0 ? step.value : ''}
              </span>
            ))}
          </p>
        ) : (
          ''
        )}
      </CardRow>
    </Fragment>
  );
};

Slider.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.number,
  datalist: PropTypes.bool,
  step: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  currentScore: PropTypes.number,
  funcData: PropTypes.object,
};

export default Slider;

