import React, { useEffect } from 'react';
import editRoundTastersStyle from './editroundtasters.module.scss';
import PropTypes from 'prop-types';
import {
  Label,
  Card,
  CardRow,
  Checkbox,
  UserImage,
  AutoComplete,
  Button,
} from '../../../components';
import UserService from '../../services/users/user.service';
import GroupService from '../../services/groups/group.service';
import RoundService from '../../services/rounds/round.service';
import { useTranslation } from 'react-i18next';
import { Key } from 'react-feather';
import { useUser } from '../../../contexts/User';

const EditRoundTasters = props => {
  const { t } = useTranslation();

  const userService = new UserService();
  const groupService = new GroupService();
  const roundService = new RoundService();
  const { user } = useUser();
  const isZhOrKr = JSON.parse(
    user.str_language === 'zh' || user.str_language === 'kr',
  );

  useEffect(() => {
    roundService.listRoundUsers(props.round.uuid_round).then(
      res => {
        if (res.type === 'success' && res.results) {
          props.setRound({
            ...props.round,
            arr_users: res.results.map(taster => {
              return { ...taster, bol_taster: 1 };
            }),
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  }, []);

  const handleSearchGroups = text => {
    return new Promise((resolve, reject) => {
      groupService.findGroup({ str_name: text }).then(
        res => {
          if (res.type === 'success' && res.results) {
            resolve(res.results);
          } else {
            window.alert(res.message);
            reject(res.message);
          }
        },
        error => {
          //console.log(error);
          reject(error);
        },
      );
    });
  };

  const handleSearchTasters = text => {
    return new Promise((resolve, reject) => {
      userService.findUser({ str_name: text }).then(
        res => {
          if (res.type === 'success' && res.results) {
            resolve(res.results);
          } else {
            window.alert(res.message);
            reject(res.message);
          }
        },
        error => {
          //console.log(error);
          reject(error);
        },
      );
    });
  };

  const handleSelectGroup = evt => {
    if (
      evt.target &&
      evt.target.value &&
      evt.target.value.arr_taste_groups_users &&
      evt.target.value.uuid_taste_group
    ) {
      const roundUsers = evt.target.value.arr_taste_groups_users;
      props.setRound({
        ...props.round,
        arr_users: roundUsers,
        uuid_taste_group: evt.target.value.uuid_taste_group,
        str_name_taste_group: evt.target.value.str_name,
      });
    }
  };

  const handleSelectTaster = evt => {
    if (evt.target && evt.target.value) {
      const hasUser = props.round.arr_users?.find(
        u => u.uuid_user === evt.target.value.uuid_user,
      );

      if (hasUser) return;

      const newUser = evt.target.value;
      newUser.bol_key_taster = 0;

      const roundUsers = props.round.arr_users
        ? props.round.arr_users.concat([newUser])
        : [newUser];

      props.setRound({
        ...props.round,
        arr_users: roundUsers,
      });
    }
  };

  const handleCheckTaster = (evt, index) => {
    let usersTemp = props.round.arr_users;
    if (
      typeof props.round.arr_status === 'object' &&
      props.round.arr_status.length >= 4 &&
      props.round.arr_status[3] === 'IN_PROGRESS' &&
      props.round.str_round_type === 'Flavor ID'
    ) {
      usersTemp[index].bol_taster = true;
    } else {
      usersTemp[index].bol_taster = !usersTemp[index].bol_taster;
    }
    props.setRound({
      ...props.round,
      arr_users: usersTemp,
    });
  };

  const selectOrUnselectAll = isSelectAll => {
    let usersTemp = props.round.arr_users;
    for (let user of usersTemp) user.bol_taster = isSelectAll;
    props.setRound({
      ...props.round,
      arr_users: usersTemp,
    });
  };

  const handleCheckAllTasters = () => {
    selectOrUnselectAll(true);
  };

  const handleUnselectAllTasters = () => {
    selectOrUnselectAll(false);
  };

  const handleSetUserAsKeyTaster = user => {
    const { uuid_user } = user;
    roundService.setUserAsKeyTaster(props.round.uuid_round, uuid_user).then(
      res => {
        if (res.type === 'success' && res.results) {
          const tempUsers = props.round.arr_users;
          props.setRound({
            ...props.round,
            arr_users: tempUsers.map(u => {
              if (u.uuid_user == uuid_user) {
                return { ...u, bol_key_taster: 1 };
              } else {
                return u;
              }
            }),
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  const handleUnsetUserAsKeyTaster = user => {
    const { uuid_round, uuid_user } = user;
    roundService.unsetUserAsKeyTaster(uuid_round, uuid_user).then(
      res => {
        if (res.type === 'success' && res.results) {
          const tempUsers = props.round.arr_users;
          props.setRound({
            ...props.round,
            arr_users: tempUsers.map(u => {
              if (u.uuid_user == uuid_user) {
                return { ...u, bol_key_taster: 0 };
              } else {
                return u;
              }
            }),
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  const handleTasterList = (user, index) => {
    return (
      <div key={index} className={editRoundTastersStyle.tasterCard}>
        <Checkbox
          label=""
          name="bol_taster"
          value={user.bol_taster}
          margin="2px 0px 0px 15px"
          onChange={evt => {
            handleCheckTaster(evt, index);
          }}
        />
        <div
          className={editRoundTastersStyle.userImage}
          style={{ minWidth: '50px', marginRight: '10px' }}
        >
          <UserImage
            name="str_user_image"
            src={user.str_image}
            margin="0px 6px 0px 0px"
            size={50}
          />
        </div>
        <div className={editRoundTastersStyle.tasterCardText}>
          <div className={editRoundTastersStyle.tasterNameLabel}>
            <Label
              label={user.str_name}
              bold
              neutral
              size={14}
              style={{ maxWidth: '245px' }}
              hidenText
              dataTip={user.str_name}
              dataForId="userName"
            />
            {user.bol_key_taster ? (
              <Key
                onClick={() => handleUnsetUserAsKeyTaster(user)}
                className={editRoundTastersStyle.keyIcon}
                size={24}
              />
            ) : (
              <Key
                onClick={() => handleSetUserAsKeyTaster(user)}
                className={editRoundTastersStyle.keyIconInactive}
                size={24}
              />
            )}
          </div>
          <Label label={user.str_abi_id} neutral size={12} />
          <Label label={user.str_email} neutral size={12} />
        </div>
      </div>
    );
  };

  return (
    <Card>
      <Label
        label={
          props.round.str_name_taste_group
            ? props.round.str_name_taste_group
            : 'Search a Group...'
        }
        bold
        neutral
        margin="-10px 0px 20px 0px"
      />
      <CardRow flexWrap="wrap">
        <AutoComplete
          useDebounce
          minimalToSearch={isZhOrKr ? 2 : 3}
          disabled={
            typeof props.round.arr_status === 'object' &&
            props.round.arr_status.length >= 4 &&
            props.round.arr_status[3] === 'IN_PROGRESS' &&
            props.round.str_round_type === 'Flavor ID'
          }
          name="group"
          label={t('common_search_group')}
          margin="10px 10px 0px 0px"
          padding="11px 5px 11px 34px"
          optionKeys={['str_name']}
          onSearch={handleSearchGroups}
          onSelect={handleSelectGroup}
        />
        <AutoComplete
          useDebounce
          minimalToSearch={isZhOrKr ? 2 : 3}
          name="tasters"
          label={t('common_search_taster')}
          margin="10px 10px 0px 0px"
          optionKeys={['str_abi_id', 'str_name', 'str_email']}
          onSearch={handleSearchTasters}
          onSelect={handleSelectTaster}
        />
      </CardRow>
      <CardRow justifyContent="flex-end" style={{ marginRight: '10px' }}>
        <Button
          label={t('round_tasters_unselect_all')}
          margin="10px 10px 0px 0px"
          onClick={handleUnselectAllTasters}
          disabled={
            typeof props.round.arr_status === 'object' &&
            props.round.arr_status.length >= 4 &&
            props.round.arr_status[3] === 'IN_PROGRESS' &&
            props.round.str_round_type === 'Flavor ID'
          }
          style={{
            borderRadius: '6px',
            width: '160px',
            backgroundColor: '#bbbdbf',
            color: 'white',
          }}
        />
        <Button
          label={t('round_tasters_select_all')}
          margin="10px 0px 0px 0px"
          onClick={handleCheckAllTasters}
          neutral
          style={{
            borderRadius: '6px',
            width: '160px',
          }}
        />
      </CardRow>
      <CardRow flexWrap="wrap">
        {props.round && props.round.arr_users
          ? props.round.arr_users.map((user, index) =>
              handleTasterList(user, index),
            )
          : ''}
      </CardRow>
    </Card>
  );
};

EditRoundTasters.propTypes = {
  privilege: PropTypes.any,
  round: PropTypes.any,
  setRound: PropTypes.any,
};

export default EditRoundTasters;
