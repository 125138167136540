import React, { useState, useEffect } from 'react';
import tastingDiscussionTriangle from './tastingTriangle.module.scss';
import { useHistory } from 'react-router-dom';
import {
  CardColumn,
  CardRow,
  Button,
  TextField,
  Card,
  RoundUpAndDown,
  CountDown,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import beer from '../../../assets/beer_threshold.svg';
import { X, Check, ArrowLeft } from 'react-feather';
import TriangleService from '../../services/triangleTest/triangle.service';
import { timeRemaining } from '../../../utils/timeRemaining';
import EditRoundSamplesSample from '../../Rounds/editRoundSamplesSample/EditRoundSamplesSample';

export default function TastingDiscussionTriangle (props) {
  const history = useHistory();
  const triangleService = new TriangleService();
  const [code, setCode] = useState('');
  const { t } = useTranslation();
  const [round, setRound] = useState(props.location.state.round);
  const [listSet, setListSet] = useState([]);
  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = async () => {
    const codeRes = await triangleService.getTriangleCode({
      uuid_round: round.uuid_round,
    });
    if (codeRes && codeRes.results[0].round_sp_se_triangle_test_current_code) {
      setCode('00' + codeRes.results[0].round_sp_se_triangle_test_current_code);
      const resSample = await triangleService.listTriangleSample({
        uuid_round: round.uuid_round,
      });
      setListSet(resSample.results[0]?.user_sample);
    }
  };

  const handleBack = () => {
    history.replace('/');
  };

  const saveCallback = async () => {
    setRound(round);
    const resSample = await triangleService.listTriangleSample({
      uuid_round: round.uuid_round,
    });
    setListSet(resSample.results[0]?.user_sample);
  };

  return (
    <div>
      <CardRow transparent>
        <CardColumn className={tastingDiscussionTriangle.header}>
          <Button
            label={''}
            onClick={() => handleBack()}
            className={tastingDiscussionTriangle.back}
          >
            <ArrowLeft />
          </Button>
          <RoundUpAndDown
            round={props.location.state.round}
            className={tastingDiscussionTriangle.upAndDown}
          />
        </CardColumn>
        <CardColumn>
          <div className={tastingDiscussionTriangle.cardLabelTitle}>
            <div className={tastingDiscussionTriangle.topLabel}>
              {props.location.state.round.int_round}
            </div>
          </div>
        </CardColumn>
        <CardColumn>
          <CardColumn className={tastingDiscussionTriangle.countDownColumn}>
            <div className={tastingDiscussionTriangle.countDownDiv}>
              {round.bol_is_countdown ? (
                <CountDown
                  round={round}
                  setRound={setRound}
                  save={saveCallback}
                />
              ) : null}
            </div>
          </CardColumn>
        </CardColumn>
      </CardRow>

      <CardRow transparent flexWrap="wrap" padding="0px 0px 0px 0px">
        <CardColumn padding="5px">
          <TextField
            name="flt_concentration"
            label={t('code')}
            margin="10px 10px 0px 0px"
            value={code}
            text="number"
            disabled={true}
          />
        </CardColumn>
      </CardRow>
      <CardRow
        transparent
        flexWrap="wrap"
        justifyContent="flexEnd"
        padding="5px 0px 0px"
      >
        <CardColumn flex={1}></CardColumn>
        <CardColumn flex={2}>
          {round.bol_is_countdown && timeRemaining(round.dat_finish) > 0 ? (
            <CardRow transparent>
              {
                <div>
                  {
                    <span
                      className={'iconfont ' + tastingDiscussionTriangle.alert}
                    >
                      &#xe6e4;
                    </span>
                  }
                  <span className={tastingDiscussionTriangle.alertText}>
                    {' '}
                    {t('check_the_answer_at_the_end_of_the_countdown')}
                  </span>
                </div>
              }
            </CardRow>
          ) : null}
        </CardColumn>
        <CardColumn>
          {' '}
          <div className={tastingDiscussionTriangle.closeBtnContainer}>
            <Button
              className={tastingDiscussionTriangle.closeButton}
              label={t('group_close_modal')}
              remove
              onClick={() => history.replace('/')}
            ></Button>
          </div>
        </CardColumn>
      </CardRow>
      <CardRow
        transparent
        flexWrap="wrap"
        className={tastingDiscussionTriangle.listContainer}
      >
        {listSet?.map((item, index) => {
          console.log(item, 'item');
          let sampleAObj = {
            str_sample: item.str_sample_a,
            str_brand_name: item.str_brand_name_a,
            str_site_name: item.str_site_name_a,
            dat_production: item.dat_production_a,
            dat_expire: item.dat_expire_a,
            str_batch: item.str_batch_a,
          }
          let sampleBObj = {
            str_sample: item.str_sample_b,
            str_brand_name: item.str_brand_name_b,
            str_site_name: item.str_site_name_b,
            dat_production: item.dat_production_b,
            dat_expire: item.dat_expire_b,
            str_batch: item.str_batch_b,
          }
          return (
            <Card key={index}>
              <div>
                <div className={tastingDiscussionTriangle.triangleTitle}>
                  <div className={tastingDiscussionTriangle.triangleTitleSpan1}>
                    {`Triangle Test${index + 1}:`}
                  </div>
                  <div className={tastingDiscussionTriangle.triangleTitleSpan}>
                    {' '}
                    {item.str_triangle_test_name}
                  </div>
                </div>
                <div className={tastingDiscussionTriangle.sampleContainer}>
                  <CardColumn>
                    <div className={tastingDiscussionTriangle.cardTitle}>
                      {'Sample A'}
                    </div>
                    <EditRoundSamplesSample
                      key={sampleAObj}
                      hideDelButton
                      hideOder
                      state={props.state}
                      sample={sampleAObj}
                      commentsName={'str_sample_a'}
                      comments={sampleAObj.str_sample}
                      round={round}
                    />
                  </CardColumn>
                  <CardColumn>
                    <div className={tastingDiscussionTriangle.cardTitle}>
                      {'Sample B'}
                    </div>
                    <EditRoundSamplesSample
                      key={sampleBObj}
                      hideDelButton
                      hideOder
                      state={props.state}
                      sample={sampleBObj}
                      commentsName={'str_sample_b'}
                      comments={sampleBObj.str_sample}
                      round={round}
                    />
                  </CardColumn>
                </div>
                <div className={tastingDiscussionTriangle.setContainer}>
                  {item?.arr_sample.map((item, index) => {
                    return (
                      <div
                        className={
                          item.str_sample && +item?.str_sample === index
                            ? tastingDiscussionTriangle.selectedCard
                            : tastingDiscussionTriangle.setCard
                        }
                      >
                        <div className={tastingDiscussionTriangle.optionSet}>
                          <div>
                            {timeRemaining(round.dat_finish) <= 0 ||
                              !round.bol_is_countdown ? (
                              item.answer === '0' ? (
                                <X
                                  className={tastingDiscussionTriangle.imgWrong}
                                ></X>
                              ) : (
                                <Check
                                  className={tastingDiscussionTriangle.imgRight}
                                />
                              )
                            ) : (
                              ''
                            )}
                          </div>
                          <div>Sample {item.str_option_set}</div>
                        </div>
                        <div className={tastingDiscussionTriangle.optionSelect}>
                          <div
                            className={
                              tastingDiscussionTriangle.optionContainer
                            }
                          >
                            <img
                              src={beer}
                              alt={'unlocked'}
                              height={44}
                              width={30}
                            />
                          </div>
                        </div>
                        <div className={tastingDiscussionTriangle.answer}>
                          {t('common_comments')}
                        </div>
                        <div
                          className={tastingDiscussionTriangle.answerContainer}
                        >
                          {item.sample_comment?.length > 0 ? (
                            <div
                              className={tastingDiscussionTriangle.commentList}
                            >
                              {item.sample_comment?.map((item, ii) => {
                                return item.str_comment ||
                                  item.str_comment_name ? (
                                  <button
                                    key={ii}
                                    className={
                                      item.uuid_comment
                                        ? tastingDiscussionTriangle.showComments
                                        : tastingDiscussionTriangle.noComment
                                    }
                                  >
                                    {item.str_degree
                                      ? item.str_degree + ' - '
                                      : ''}
                                    {item.str_comment_name || item.str_comment}
                                  </button>
                                ) : (
                                  ''
                                );
                              })}
                            </div>
                          ) : (
                            <li
                              className={tastingDiscussionTriangle.commentList}
                            ></li>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Card>
          );
        })}
      </CardRow>
    </div>
  );
}
