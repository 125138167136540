import React, { useEffect, Fragment, useRef, useState } from 'react';
import listReviews from './listreviews.module.scss';
import {
  Modal,
  CardRow,
  Text,
  CardColumn,
  Button,
  Label,
  Spinner,
  NotificationBar,
} from '../../../components';
import configs from '../../../configs';
import { AlertTriangle, ArrowLeft } from 'react-feather';
import useListReviews from './hooks';
import { useTasting } from '../../../contexts/Tasting';
import { GetSocket } from '../../../utils/socketIo';
import _ from 'lodash';

const ListReviews = ({ location }) => {
  const {
    showWarningEmptySamples,
    setShowWarningEmptySamples,
    showWarningTieSamples,
    setShowWarningTieSamples,
    samples,
    loading,
    setLoading,
    modalShow,
    setModalShow,
    round,
    setRound,
    taste,
    history,
    t,
    handleAutoRank,
    getOptions,
    handleClickSample,
    handleValidateForm,
    handleSubmit,
    handleValidateRank,
    handleComments,
    handleCleanRanks,
  } = useListReviews();
  const { loadTaste } = useTasting();
  const [notificationArr, setNotificationArr] = useState([]);
  const bubbleArr = useRef([]);
  const nottificationRef = useRef({});
  const [order, setOrder] = useState('int_order_app');
  const [optionsFilter, setFilters] = useState([]);

  useEffect(() => {
    bubbleArr.current = notificationArr;
  }, [notificationArr]);

  const handleSocketOn = (socketName, res) => {
    const resUuidRound = res.uuid_round !== undefined ? res.uuid_round : '';

    let nowRound = resUuidRound === location.state.round.uuid_round;
    let sampleNameStr = '';
    if (socketName === 'addUpdateSample') {
      for (let i = 0; i < res.updateSampleArr.length; i++) {
        const lastDom = i + 1 !== res.updateSampleArr.length ? ', ' : '';
        sampleNameStr += res.updateSampleArr[i].str_brand_name + lastDom;
      }
      nowRound = nowRound && !!sampleNameStr;
    }

    if (nowRound) {
      let oldName = null;
      let newName = null;
      let title = null;
      if (socketName === 'updateSampleID') {
        oldName = res.str_old_name ? res.str_old_name : '';
        newName = res.str_name ? res.str_name : '';
        title = 'is_swapped_to';
      }
      if (socketName === 'addUpdateSample') {
        title = 'sample_new_sample';
        newName = sampleNameStr;
      }
      bubbleArr.current.push({
        oldName,
        title,
        newName,
      });
      setNotificationArr([...bubbleArr.current]);
      loadTaste(location.state.round);
      if (
        nottificationRef?.current &&
        bubbleArr.current &&
        bubbleArr.current.length > 4
      ) {
        nottificationRef.current?.scrollBottom &&
          nottificationRef.current.scrollBottom();
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      GetSocket().on('updateSampleID', function (res) {
        if (window.location.pathname === '/tasting_review') {
          handleSocketOn('updateSampleID', res);
        }
      });
      GetSocket().on('addUpdateSample', async function (res) {
        if (window.location.pathname === '/tasting_review') {
          const resUuidRound = res.uuid_round ? res.uuid_round : '';
          if (resUuidRound === location.state.round.uuid_round) {
            let sampleNameStr = '';
            for (let i = 0; i < res.updateSampleArr.length; i++) {
              const lastDom = i + 1 !== res.updateSampleArr.length ? ', ' : '';
              sampleNameStr += res.updateSampleArr[i].str_brand_name + lastDom;
            }
            if (!!sampleNameStr) {
              bubbleArr.current.push({
                title: 'sample_new_sample',
                newName: sampleNameStr,
              });
              setNotificationArr([...bubbleArr.current]);
              loadTaste(location.state.round);
            }

            if (
              nottificationRef?.current &&
              bubbleArr.current &&
              bubbleArr.current.length > 4
            ) {
              nottificationRef.current?.scrollBottom &&
                nottificationRef.current.scrollBottom();
            }
          }
        }
      });
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (taste.bol_finished) {
      history.replace({
        pathname: `/tasting_discussion`,
        state: { round: location.state.round },
      });
    }

    setRound(taste);
    if (taste.bol_ranked) {
      handleAutoRank(taste.arr_samples);
    } else {
      handleCleanRanks(taste.arr_samples);
    }
    setLoading(false);

    setFilters(
      taste.bol_ranked
        ? [
            { label: t('discussion_order_number'), value: 'int_order_app' },
            { label: t('discussion_order_ranking'), value: 'int_rank' },
            { label: t('discussion_order_score'), value: 'flt_score' },
          ]
        : [
            { label: t('discussion_order_number'), value: 'int_order_app' },
            { label: t('discussion_order_score'), value: 'flt_score' },
          ],
    );
  }, []);

  const ASC_DESC = {
    int_order_app: 'asc',
    int_rank: 'asc',
    flt_score: 'asc',
  };

  const handleBack = () => {
    if (location.state !== undefined && location.state?.from === 'discussion') {
      history.replace({ pathname: '/' });
    } else {
      history.goBack();
    }
  };

  const handleChange = value => {
    setOrder(value.target.value);
  };

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          <th>
            <CardColumn padding="0" className={listReviews.cardFilter}>
              <select className={listReviews.filter} onChange={handleChange}>
                {optionsFilter?.map((option, i) => (
                  <option key={option.label + i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </CardColumn>
          </th>
        </tr>
      </thead>
    );
  };

  return (
    <Fragment>
      <CardRow
        transparent
        padding="0"
        className={listReviews.container}
        style={{ justifyContent: 'flex-end' }}
      >
        <CardColumn
          className={listReviews.header}
          style={{ justifyContent: 'flex-start' }}
        >
          <Button
            label={''}
            onClick={() => handleBack()}
            className={listReviews.back}
          >
            <ArrowLeft />
          </Button>
          <div className={listReviews.itemListing}>
            <table className={listReviews.listTable}>
              {renderHeader()}
              <tbody></tbody>
            </table>
          </div>
        </CardColumn>

        <CardColumn flex={2}>
          <Label
            label={round.int_round}
            bold
            style={{
              textAlign: 'center',
              color: '#F49B00',
              fontSize: '1.2em',
            }}
          ></Label>
        </CardColumn>
        <CardColumn flex={0} style={{ maxWidth: '220px' }}>
          <Button
            className={listReviews.finishButton}
            onClick={() => {
              handleValidateForm(location.state.round);
            }}
            label={t('tasting_finish_taste')}
          ></Button>
        </CardColumn>
      </CardRow>
      <Modal
        className={listReviews.Modal}
        show={modalShow || showWarningEmptySamples || showWarningTieSamples}
      >
        {showWarningEmptySamples ? (
          <div className={listReviews.container}>
            <CardRow className={listReviews.cardRowModal}>
              <Text className={listReviews.h1Title}>
                {taste.arr_samples.some(
                  sampleTemp => sampleTemp.flt_score === null,
                )
                  ? t('review_warning_sample_title')
                  : taste.arr_samples.some(
                      sampleTemp => sampleTemp.arr_comments.length === 0,
                    ) && t('review_warning_comment_title')}
              </Text>
            </CardRow>
            <CardRow className={listReviews.cardRowModal}>
              <Text className={listReviews.h3Title}>
                {t('review_warning_sample_description')}
              </Text>
            </CardRow>
            <CardRow
              className={[
                listReviews.buttonsWrapper,
                listReviews.cardRowModal,
              ].join(' ')}
            >
              <Button
                label={t('review_warning_sample_review')}
                remove
                onClick={() => setShowWarningEmptySamples(null)}
              ></Button>
              <Button
                label={t('review_warning_sample_ok')}
                confirm
                onClick={() => {
                  setShowWarningEmptySamples(false);
                  handleValidateForm(location.state.round);
                }}
              ></Button>
            </CardRow>
          </div>
        ) : (
          ''
        )}
        {showWarningTieSamples ? (
          <div className={listReviews.container}>
            <CardRow className={listReviews.cardRowModal}>
              <Text className={listReviews.h1Title}>
                {t('review_warning_tie_sample_title')}
              </Text>
            </CardRow>
            <CardRow className={listReviews.cardRowModal}>
              <Text className={listReviews.h3Title}>
                {t('review_warning_tie_sample_description')}
              </Text>
            </CardRow>
            <CardRow
              className={[
                listReviews.buttonsWrapper,
                listReviews.cardRowModal,
              ].join(' ')}
            >
              <Button
                label={t('review_warning_tie_sample_review')}
                confirm
                onClick={() => setShowWarningTieSamples(false)}
              ></Button>
            </CardRow>
          </div>
        ) : (
          ''
        )}
        {modalShow ? (
          <div>
            <CardRow className={listReviews.cardRowModal}>
              <Text className={listReviews.h1Title}>
                {t('review_submit_title')}
              </Text>
            </CardRow>
            <CardRow className={listReviews.cardRowModal}>
              <Text className={listReviews.h3Title}>
                {t('review_submit_description')}
              </Text>
            </CardRow>
            <CardRow
              className={[
                listReviews.buttonsWrapper,
                listReviews.cardRowModal,
              ].join(' ')}
            >
              <Button
                label={t('common_cancel')}
                remove
                onClick={() => setModalShow(false)}
              ></Button>
              <Button
                label={t('common_submit')}
                confirm
                onClick={() => handleSubmit(location.state.round)}
              ></Button>
            </CardRow>
          </div>
        ) : (
          ''
        )}
      </Modal>

      {loading ? (
        <CardRow transparent padding="80px">
          <Spinner />
        </CardRow>
      ) : (
        <CardRow
          transparent
          padding="0"
          flexWrap
          className={listReviews.cardScroll}
        >
          {_.orderBy([...samples], order, ASC_DESC[order]).map(sample => {
            const hasSameScore =
              taste.bol_ranked &&
              [...samples].some(
                x =>
                  x.flt_score == sample.flt_score &&
                  x.uuid_sample !== sample.uuid_sample &&
                  x.flt_score > 0,
              );
            const hasSameRank = hasSameScore
              ? sample.int_rank > 0 &&
                [...samples].some(
                  x =>
                    x.int_rank == sample.int_rank &&
                    x.uuid_sample !== sample.uuid_sample &&
                    x.flt_score > 0,
                )
              : false;
            const cardTieClass = hasSameRank && listReviews.cardTie;
            return (
              <CardColumn
                key={'sample_' + sample.uuid_sample}
                padding="0px"
                margin="5px"
                className={[listReviews.card, cardTieClass].join(' ')}
              >
                <CardRow bordered={true} className={listReviews.cardRevier}>
                  <CardColumn
                    padding="10px 5px"
                    onClick={() =>
                      handleClickSample(sample, location.state.round)
                    }
                  >
                    <img
                      className={listReviews.beerImage}
                      src={configs.file_api_url + '/v1/' + sample.str_img_brand}
                      alt="brand"
                    />
                  </CardColumn>
                  <CardColumn flex={5} padding="10px 5px">
                    <Text
                      className={listReviews.textTitle}
                      onClick={() =>
                        handleClickSample(sample, location.state.round)
                      }
                    >
                      {sample.int_order_app + 1} - {sample.str_name}
                    </Text>
                    <Text className={listReviews.textSubTitle}>
                      {t('common_score')}:{' '}
                      {sample?.flt_score ? parseFloat(sample.flt_score) : 'N/A'}
                      {round.bol_ranked ? (
                        <div className={listReviews.rankTitles}>
                          <select
                            className={listReviews.rank}
                            value={sample?.int_rank}
                            onChange={value => {
                              handleValidateRank(sample, value.target.value);
                            }}
                            name="int_rank"
                            id="int_rank"
                          >
                            {getOptions(sample).map(option => {
                              return (
                                <option
                                  key={
                                    'int_rank_' +
                                    option +
                                    '_' +
                                    sample.uuid_sample
                                  }
                                  value={option}
                                >
                                  {option}
                                </option>
                              );
                            })}
                          </select>
                          {hasSameRank && (
                            <AlertTriangle size={18} color="red" />
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                    </Text>
                  </CardColumn>
                </CardRow>
                <div
                  onClick={() => {
                    handleClickSample(sample, location.state.round);
                  }}
                >
                  <CardRow
                    transparent
                    padding="5px"
                    flexWrap="none"
                    display="block"
                    className={listReviews.cardBody}
                  >
                    {handleComments(sample.arr_comments)}
                  </CardRow>
                </div>
              </CardColumn>
            );
          })}
        </CardRow>
      )}
    </Fragment>
  );
};

export default ListReviews;
