import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { UserProvider } from './contexts/User';
import Routes from './routes/Routes';
import { checkVersion } from './utils/checkVersion';
import Alerts from './components/alerts/Alerts';
import { datadogRum } from '@datadog/browser-rum';

const App = () => {
  datadogRum.init({
    clientToken:
      process.env.REACT_APP_STAGE === 'prod'
        ? 'pub28b0ffd72bf254a71863b24326de892e'
        : 'pub9b55a102614c99899cc50a0af2dca2d9',
    applicationId:
      process.env.REACT_APP_STAGE === 'prod'
        ? 'de49bb2f-58a1-43d9-ab71-90e76465c2ea'
        : '707aaa76-7b2b-4fdd-8de7-bb3c27a4821b',
    site: 'datadoghq.com',
    service:
      process.env.REACT_APP_STAGE === 'prod'
        ? 'sensory-one-[prod]'
        : 'sensory-one-[qa]',
    env: process.env.REACT_APP_STAGE === 'prod' ? 'PROD' : 'qa',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.3.6',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  useEffect(() => {
    caches.keys().then(names => {
      names.forEach(name => {
        caches.delete(name);
      });
    });
  }, []);

  Modal.setAppElement('#root');
  checkVersion();

  return (
    <UserProvider>
      <Routes />
      <Alerts />
    </UserProvider>
  );
};

export default App;
