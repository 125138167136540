import configs from './configs';
import { OptionsHeader } from './modules/services/headers'; 

export default class AppService {
  user = null;

  constructor() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

  get(url, secure) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'get',
        credentials: 'include',
        headers: OptionsHeader,
      })
        .then(res => {
          resolve(res.json());
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  post(url, body, secure) {
    return new Promise((resolve, reject) => {
      try {
        fetch(url, {
          method: 'post',
          credentials: 'include',
          headers: OptionsHeader,
          body: JSON.stringify(body),
        })
          .then(res => {
            resolve(res.json());
          })
          .catch(error => {
            //console.log(error);
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  delete(url, secure) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'delete',
        credentials: 'include',
        headers: OptionsHeader,
      })
        .then(res => {
          resolve(res.json());
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  listPrivileges = str_code => {
    return this.get(
      configs.auth_api_url + '/v1/auth/list_privileges/' + str_code,
      true,
    );
  };

  listPrivilegesReports = () => {
    return this.get(
      configs.auth_api_url + '/v1/auth/list_privileges_reports',
      true,
    );
  };

  uploadFile = obj_file => {
    return this.post(
      configs.file_api_url + '/v1/uploadbase64',
      obj_file,
      false,
    );
  };

  uploadSample = (name, file) => {
    let data = new FormData();
    data.append('file', file);
    data.append('name', name);

    return new Promise((resolve, reject) => {
      fetch(configs.file_api_url + '/v1/upload/samples', {
        method: 'post',
        credentials: 'include',
        body: data,
      })
        .then(res => {
          resolve(res.json());
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  uploadSampleOkNok = (uuid_round, file) => {
    let data = new FormData();
    data.append('file', file);
    data.append('uuid_round', uuid_round);

    return new Promise((resolve, reject) => {
      fetch(configs.file_api_url + '/v1/upload/samples/ok-nok', {
        method: 'post',
        credentials: 'include',
        body: data,
      })
        .then(res => {
          resolve(res.json());
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  login = obj_login => {
    return this.post(configs.auth_api_url + '/v1/auth/login', obj_login, false);
  };

  checkCookies = () => {
    return this.post(configs.auth_api_url + '/v1/auth/validate_session', {
      str_token: this.user.str_token,
    });
  };

  updateDocument = obj_document => {
    return this.post(configs.file_api_url + '/v1/documents', obj_document);
  };

  removeDocument = uuid_document => {
    //console.log(configs.file_api_url + `/v1/documents/${uuid_document}`);
    return this.delete(configs.file_api_url + `/v1/documents/${uuid_document}`);
  };

  getFileBase64 = document => {
    return this.get(configs.file_api_url + `/v1/base64/${document}`);
  };

  validateVersion = () => {
    return this.get(configs.auth_api_url + '/v1/auth/validate_version_web');
  };
}

