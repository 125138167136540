import React, { createContext, useContext } from 'react';
import { useState } from 'react';
import RoundAppService from '../modules/services/rounds/roundApp.service';
import AppService from '../../src/app.service';
import SampleAppService from '../modules/services/samples/sampleApp.service';
import { XCircle } from 'react-feather';

const TastingContext = createContext({});

const handleGetStorage = () => {
  let taste = JSON.parse(localStorage.getItem('taste'));
  return taste ? taste : null;
};

const handleSaveStorage = taste => {
  let tasteToSave = taste;
  if (tasteToSave == null) {
    tasteToSave = '';
  }
  localStorage.setItem('taste', JSON.stringify(tasteToSave));
};

const handleGetStorageSample = () => {
  let sample = JSON.parse(localStorage.getItem('sample'));
  return sample ? sample : null;
};

const handleSaveStorageSample = sample => {
  localStorage.setItem('sample', JSON.stringify(sample));
};

export const TastingProvider = ({ children }) => {
  const roundAppService = new RoundAppService();
  const appService = new AppService();
  const sampleAppService = new SampleAppService();
  const [taste, setTaste] = useState(handleGetStorage());
  const [sample, setSample] = useState(handleGetStorageSample());

  const handleTaste = change => {
    setTaste(change);
    handleSaveStorage(change);
  };

  const handleTasteSamples = change => {
    setTaste({ ...taste, arr_samples: change });
    handleSaveStorage({ ...taste, arr_samples: change });
  };

  const handleSample = change => {
    return new Promise((resolve, reject) => {
      setSample(change);
      handleSaveStorageSample(change);
      resolve(true);
    });
  };

  const loadTaste = round => {
    return new Promise((resolve, reject) => {
      switch (round.uuid_round_type) {
        case 'be229b18-2eb4-11eb-ba1d-0229531a6f14':
          roundAppService.getRoundOkNok(round).then(
            res => {
              handleTaste(res.results);
              resolve(res);
            },
            error => {
              //console.log(error);
            },
          );
          break;
        default:
          roundAppService.getRound(round).then(
            res => {
              setTaste(res.results[0]);
              handleTaste(res.results[0]);
              resolve(res);
            },
            error => {
              //console.log(error);
            },
          );
          break;
      }
    });
  };

  const loadTasteSample = round => {
    return new Promise((resolve, reject) => {
      if (round.uuid_round_type != 'be229b18-2eb4-11eb-ba1d-0229531a6f14') {
        roundAppService.getRound(round).then(
          res => {
            resolve(res);
          },
          error => { },
        );
      }
    });
  };

  const loadSplitTaste = round => {
    return new Promise((resolve, reject) => {
      roundAppService.getRound(round).then(
        res => {
          resolve(res);
        },
        error => {
          //console.log(error);
        },
      );
    });
  };

  const loadTasteDiscussion = round => {
    return new Promise((resolve, reject) => {
      switch (round?.uuid_round_type) {
        case 'be229b18-2eb4-11eb-ba1d-0229531a6f14':
          roundAppService.getRoundOkNokDiscussion(round).then(
            res => {
              handleTaste(res.results);
              resolve(res);
            },
            error => {
              //console.log(error);
            },
          );
          break;
        default:
          roundAppService.getRoundDiscussion(round).then(
            res => {
              handleTaste(res.results[0]);
              resolve(res);
            },
            error => {
              //console.log(error);
            },
          );
          break;
      }
    });
  };

  const updateScoreSample = score => {
    let sampleTemp = { ...sample };
    sampleTemp.flt_score = parseFloat(score);
    sampleTemp.int_rank = null;
    handleSample(sampleTemp);
  };

  const addCommentsSample = comment => {
    let sampleTemp = { ...sample };
    let comments = [
      ...sampleTemp.arr_comments,
      {
        ...comment,
      },
    ];
    sampleTemp.arr_comments = comments;
    handleSample(sampleTemp);
  };

  const addCommentsCategory = (comment, uuid_sample, uuid_user) => {
    let currentSample = sample.samples.find(x => x.uuid_sample === uuid_sample);
    let currentTaster = currentSample?.tasters.find(
      o => o.uuid_user === uuid_user,
    );

    let user = {
      str_name: '',
      uuid_user: uuid_user,
    };

    let voting =
      currentTaster?.bol_ok === 1 || currentTaster?.bol_ok === 0
        ? currentTaster.bol_ok
        : 2;

    let objUserVoting = {
      ...currentSample,
      bol_ok: voting,
      dat_sample: new Date(),
    };

    if (currentTaster === undefined) {
      sampleAppService
        .saveTastingOkNok(currentSample, objUserVoting)
        .then(res => {
          if (res.type === 'success') {
            updateTastingOkNok(
              currentSample.uuid_sample,
              objUserVoting,
              user,
            ).then(res => { });
          }
        });
    }

    if (currentSample && currentTaster?.arr_comments === undefined) {
      currentTaster = {
        uuid_user: uuid_user,
        arr_comments: [comment],
        ...objUserVoting,
      };
    } else {
      currentTaster.arr_comments = currentTaster.arr_comments
        ? [...currentTaster.arr_comments, comment]
        : [comment];
    }

    currentSample.tasters = [
      ...currentSample.tasters.filter(obj => obj.uuid_user !== uuid_user),
      currentTaster,
    ];

    handleSample(currentSample);
  };

  const removeCommentsCategory = (
    uuid_answers_rounds_users_samples_categories_comments,
    uuid_sample,
    uuid_user,
  ) => {
    let currentSample = sample.samples.find(x => x.uuid_sample === uuid_sample);
    let currentTaster = currentSample?.tasters.find(
      o => o.uuid_user === uuid_user,
    );

    if (currentTaster) {
      currentTaster.arr_comments = currentTaster?.arr_comments?.filter(
        x =>
          x.uuid_answers_rounds_users_samples_categories_comments !==
          uuid_answers_rounds_users_samples_categories_comments,
      );

      currentSample.tasters = [
        ...currentSample.tasters.filter(obj => obj.uuid_user !== uuid_user),
        currentTaster,
      ];

      handleSample(currentSample);
    }
  };

  const updateSampleComments = comments => {
    let sampleTemp = { ...sample };
    sampleTemp.arr_comments = comments;
    handleSample(sampleTemp);
  };

  const removeCommentSample = rmComment => {
    let sampleTemp = { ...sample };
    let arrComments = sampleTemp.arr_comments.filter(
      comment =>
        !(comment.uuid_answers_rounds_users_samples_comments === rmComment),
    );
    sampleTemp.arr_comments = arrComments;
    handleSample(sampleTemp);
  };

  const updateRound = () => {
    return new Promise((resolve, reject) => {
      let roundTemp = taste;
      let sampleTemp = sample;
      roundTemp.arr_samples = roundTemp.arr_samples.map(obj => {
        if (obj.uuid_sample === sampleTemp.uuid_sample) {
          return sampleTemp;
        } else {
          return obj;
        }
      });

      handleTaste(roundTemp);
      handleSample('');
      resolve(true);
    });
  };

  const updateFinishRound = () => {
    let roundTemp = taste;
    roundTemp.bol_finished = true;
    handleTaste(roundTemp);
  };

  const updateUserRankAndScoreSample = (rank, score, objSample) => {
    let tasteTemp = { ...taste };

    tasteTemp.arr_samples = tasteTemp.arr_samples.map(obj => {
      if (obj.uuid_sample === objSample.uuid_sample) {
        return {
          ...obj,
          int_rank: rank !== '' ? rank : null,
          flt_score: score !== null ? parseFloat(score) : obj.flt_score,
        };
      } else {
        return obj;
      }
    });

    handleTaste(tasteTemp);
  };

  const byOneKeyOk = (user) => {
    let categoryTemp = sample;
    categoryTemp.samples = categoryTemp.samples.map(sampleTemp => {
      if (
        !sampleTemp.tasters.find(taster => taster.uuid_user === user.uuid_user)
      ) {
        sampleTemp.tasters.push({
          str_name: user.str_name,
          uuid_category_option: sampleTemp.uuid_category_option,
          uuid_round: sampleTemp.uuid_round,
          uuid_user: user.uuid_user,
          dat_sample: new Date(),
        });
      }
      sampleTemp.tasters.forEach(tasterTemp => {
        if (tasterTemp.uuid_user === user.uuid_user) {
          tasterTemp.bol_ok = 1;
        }
      });
      return sampleTemp;
    });
    handleSample(categoryTemp).then(
      () => {
        updateCategoryOkNok();
      },
      error => {
      },
    );
  }

  const updateTastingOkNok = (uuid_sample, voting, user) => {
    return new Promise((resolve, reject) => {
      let categoryTemp = sample;

      categoryTemp.samples = categoryTemp.samples.map(sampleTemp => {
        if (sampleTemp.uuid_sample !== uuid_sample) {
          return sampleTemp;
        }

        if (
          !sampleTemp.tasters.find(
            taster => taster.uuid_user === user.uuid_user,
          )
        ) {
          sampleTemp.tasters.push({
            str_name: user.str_name,
            uuid_category_option: sample.uuid_category_option,
            uuid_round: sample.uuid_round,
            uuid_user: user.uuid_user,
          });
        }

        sampleTemp.tasters = sampleTemp.tasters.map(taster => {
          if (taster.uuid_user === user.uuid_user) {
            taster = {
              ...taster,
              ...voting,
            };
          }

          return taster;
        });

        return sampleTemp;
      });

      handleSample(categoryTemp).then(
        res => {
          updateCategoryOkNok();
        },
        error => {
          //console.log(error);
        },
      );

      resolve({ data: categoryTemp });
    });
  };

  const updateCategoryOkNok = () => {
    let tasteTemp = taste;
    tasteTemp.categories = tasteTemp.categories.map(categoryTemp => {
      if (categoryTemp.uuid_category !== sample.uuid_category) {
        return categoryTemp;
      }

      categoryTemp.samples = categoryTemp.samples.map(sampleTemp => {
        if (sampleTemp.uuid_sample === sample.uuid_sample) {
          return sample;
        }

        return sampleTemp;
      });

      return categoryTemp;
    });
    handleTaste({ ...tasteTemp });
  };

  const updateApproveSample = approve_reject => {
    let sampleTemp = { ...sample };
    sampleTemp.bol_user_approve_reject = approve_reject;

    let roundTemp = taste;
    roundTemp.arr_samples = roundTemp.arr_samples.map(obj => {
      if (obj.uuid_sample === sampleTemp.uuid_sample) {
        obj.bol_user_approve_reject = approve_reject;
        return obj;
      } else {
        return obj;
      }
    });

    handleSample(sampleTemp);
    handleTaste(roundTemp);
  };

  return (
    <TastingContext.Provider
      value={{
        loadTaste: loadTaste,
        loadTasteSample: loadTasteSample,
        taste: taste,
        handleTaste: handleTaste,
        sample: sample,
        handleSample: handleSample,
        updateScoreSample: updateScoreSample,
        addCommentsSample: addCommentsSample,
        removeCommentSample: removeCommentSample,
        updateUserRankAndScoreSample: updateUserRankAndScoreSample,
        updateSampleComments: updateSampleComments,
        updateFinishRound: updateFinishRound,
        updateTastingOkNok: updateTastingOkNok,
        updateRound: updateRound,
        loadTasteDiscussion: loadTasteDiscussion,
        addCommentsCategory: addCommentsCategory,
        removeCommentsCategory: removeCommentsCategory,
        updateApproveSample: updateApproveSample,
        handleTasteSamples: handleTasteSamples,
        loadSplitTaste: loadSplitTaste,
        byOneKeyOk: byOneKeyOk,
      }}
    >
      {children}
    </TastingContext.Provider>
  );
};

export const useTasting = () => {
  const {
    taste,
    handleTaste,
    handleTasteSamples,
    sample,
    handleSample,
    updateScoreSample,
    addCommentsSample,
    loadTaste,
    loadTasteSample,
    removeCommentSample,
    updateUserRankAndScoreSample,
    updateSampleComments,
    updateFinishRound,
    updateTastingOkNok,
    updateRound,
    loadTasteDiscussion,
    addCommentsCategory,
    removeCommentsCategory,
    updateApproveSample,
    handleSaveStorageSample,
    loadSplitTaste,
    byOneKeyOk,
  } = useContext(TastingContext);

  return {
    taste,
    handleTaste,
    sample,
    handleSample,
    updateScoreSample,
    addCommentsSample,
    loadTaste,
    loadTasteSample,
    removeCommentSample,
    updateUserRankAndScoreSample,
    updateSampleComments,
    updateFinishRound,
    updateTastingOkNok,
    updateRound,
    loadTasteDiscussion,
    addCommentsCategory,
    removeCommentsCategory,
    updateApproveSample,
    handleTasteSamples,
    handleSaveStorageSample,
    loadSplitTaste,
    byOneKeyOk,
  };
};

export default TastingContext;
