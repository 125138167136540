import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import uuid from 'react-uuid';
import listUsersUsersStyle from './listusersusers.module.scss';
import { GenericTable } from '../../../components';
import UserService from '../../services/users/user.service';
import AppService from '../../../app.service';
import ReportService from '../../services/reports/report.service';

import { useTranslation } from 'react-i18next';

const ListUsersUsers = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [privilege, setPrivilege] = useState([]);
  const [canExportUsers, setCanExportUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const reportService = new ReportService();
  const userService = new UserService();
  const appService = new AppService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('USER_MANAGEMENT_EDIT').then(
      res => {
        if (res.type === 'success' && res.results) {
          setPrivilege(res.results[0]);
          setLoading(false);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
    appService.listPrivileges('USER_MANAGEMENT_EXPORT').then(
      res => {
        if (res.type === 'success' && res.results) {
          //console.log(res.results[0]);
          setCanExportUsers(res.results[0]);
          setLoading(false);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  const handleNewUser = useCallback(() => {
    history.push({
      pathname: '/edit_user',
      state: {
        values: { uuid_user: uuid() },
        privilege: privilege,
        show: false,
      },
    });
  }, [privilege]);

  const handleEditUser = useCallback(
    values => () => {
      history.push({
        pathname: '/edit_user',
        state: { values: values, privilege: privilege, show: true },
      });
    },
    [privilege],
  );

  const handleRemoveUser = values => {
    return new Promise((resolve, reject) => {
      if (window.confirm(`${t('user_remove_user')}: ${values.str_name}`)) {
        userService.deleteUser(values.uuid_user).then(
          res => {
            if (res.type === 'success' && res.results) {
              resolve(true);
            } else {
              window.alert(res.message);
              resolve(true);
            }
          },
          error => {
            //console.log(error);
            reject(error);
          },
        );
      }
    });
  };

  const headers = [
    {
      description: t('common_id'),
      field: 'str_abi_id',
      cache: true,
      fieldCache: 'user.str_abi_id',
    },
    {
      description: t('common_name'),
      field: 'str_name',
      cache: true,
      fieldCache: 'user.str_name',
    },
    {
      description: t('common_email'),
      field: 'str_email',
      cache: true,
      fieldCache: 'user.str_email',
    },
    {
      description: t('common_profile'),
      field: 'str_profile_name',
      cache: true,
      fieldCache: 'user.str_profile_name',
    },
    {
      description: t('common_zone'),
      field: 'str_zone_name',
      cache: true,
      fieldCache: 'user.str_zone_name',
    },
    {
      description: t('common_country'),
      field: 'str_country_name',
      cache: true,
      fieldCache: 'user.str_country_name',
    },
    {
      description: t('common_site'),
      field: 'str_site_name',
      cache: true,
      fieldCache: 'user.str_site_name',
    },
    {
      description: t('common_job_position'),
      field: 'str_job_position_name',
      cache: true,
      fieldCache: 'user.str_job_position_name',
    },
    {
      description: t('common_language'),
      field: 'str_language',
      cache: true,
      fieldCache: 'user.str_language',
    },
    {
      description: t('common_terms_of_use'),
      field: 'dat_accept_terms_of_use',
      cache: true,
      fieldCache: 'user.dat_accept_terms_of_use',
    },
  ];

  return (
    <div className={listUsersUsersStyle.pageContainer}>
      <GenericTable
        arrHeader={headers}
        dataServer={userService.listUsersByServer}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditUser : null}
        onRemove={privilege.bol_access ? handleRemoveUser : null}
        newRegister={
          privilege.bol_access
            ? {
                onClick: handleNewUser,
                label: t('user_new_user'),
              }
            : null
        }
        exportService={
          canExportUsers.bol_access ? reportService.generateUsersReport : null
        }
        exportLabel={canExportUsers.bol_access ? t('user_export_users') : null}
        loading={loading}
      />
    </div>
  );
};

export default ListUsersUsers;
