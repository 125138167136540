export default {
  sample_no_production_date_message: 'Productiedatum moet worden geselecteerd',
  round_list_rounds: 'Ronde',
  round_edit_round: 'Bewerk ronde',
  round_new_round: 'Nieuwe ronde',
  round_remove_round: 'Weet u zeker dat u ronde wilt verwijderen',
  round_schedule: 'Schema',
  round_tasters_unselect_all: 'Deselecteer alle proevers',
  round_tasters_select_all: 'Selecteer alle proevers',
  round_close: 'Sluit ronde af',
  round_close_message: 'Weet je zeker dat je de ronde wilt afmaken',
  round_create_site_message: 'U creÃ«ert een ronde die niet voor uw site is!',
  round_closed_message: 'Afgesloten rondes kunnen niet bewerkt worden',
  round_status_created: 'Aangemaakt',
  round_status_samples: 'Stalen',
  round_status_scheduled: 'Gepland',
  round_status_tasters: 'Proevers',
  round_status_in_progress: 'In uitvoering',
  round_status_finished: 'BeÃ«indigd',
  round_status_closed: 'Afgesloten ',
  round_schedule_start_finish_date:
    'De startdatum kan niet groter zijn dan de einddatum',
  round_schedule_finish_date_no_start_date:
    'U moet eerst een startdatum selecteren',
  round_disabled_discussion_tooltip:
    'Je kunt niet naar een bespreking van een onafgemaakte ronde gaan',
  round_delete_other_users_rounds:
    'U bent niet geautoriseerd om een â€‹â€‹ronde te verwijderen die u niet heeft gemaakt',
  round_randomize_samples_order_button: 'Schudden',
  round_block_results_tooltip:
    'Vergrendel de resultaten van deze ronde zodat ze niet kunnen worden gedownload in het rapportscherm. Als de knop is uitgeschakeld, heeft u niet het recht om de resultaten van rondes vanaf deze locatie te vergrendelen',
  round_block_results_confirm:
    'Weet je zeker dat je de resultaten van de ronde wilt blokkeren/deblokkeren ',
  round_block_results_no_privilege:
    'U heeft niet het recht om de resultaten van rondes vanaf deze locatie te blokkeren/deblokkeren.',
  sample_list_samples: 'Stalen',
  sample_edit_sample: 'Bewerk staal',
  sample_new_sample: 'Nieuw staal',
  sample_remove_sample: 'Verwijder staal',
  sample_upload_document: 'Documenten opladen',
  sample_download_documents: 'Voorbeelddocumenten',
  sample_tasted_dates: 'Degustatie datum',
  sample_upload_message:
    'Om meerdere documenten op te laden, voegt u ze toe aan een .zip',
  sample_not_for_kpi: 'Niet voor KPI',
  sample_package: 'Verpakking',
  sample_volume: 'Volume',
  sample_production_date: 'Productie datum',
  sample_expire_date: 'Verval datum',
  sample_trial_identifier: 'Testcode Identificatie',
  sample_project_name: 'Project naam',
  sample_linked: 'Aan een ronde gekoppeld',
  sample_expire_bigger_than_production:
    'De productiedatum kan niet hoger zijn dan de vervaldatum',
  sample_no_site_message:
    'Het is verplicht om een Zone, Land en een Site te selecteren om een â€‹â€‹voorbeeld te maken',
  sample_no_brand_message: 'Het is verplicht om een â€‹â€‹merk te selecteren',
  sample_duplicated_kpi_message:
    'Er is al een KPI voor de maand, het merk en de site die je hebt geselecteerd en je kunt niet opslaan',
  sample_duplicated_project_message:
    'Er is al een voorbeeld met deze projectnaam en proef-ID voor deze site en dit merk, en u kunt niet opslaan',
  sample_project_no_name_or_trial:
    'Projectnaam en proefidentificatie zijn verplicht bij het maken van een voorbeeld van het projecttype',
  sample_production_to_project_confirm_message:
    'U wijzigt het type van dit monster van PRODUCTIE in PROJECT. Wilt u doorgaan?',
  sample_project_to_production_confirm_message:
    'U wijzigt het type van dit monster van PROJECT in PRODUCTIE. Wilt u doorgaan?',
  sample_no_volume_message:
    'Pakket en Volume zijn verplicht bij het maken/bewerken van een monster.',
  sample_id_code_message: 'Gelieve het volledige batchnummer te vermelden',
  sample_show_all_samples_hint:
    'Voorbeelden tonen die tot een jaar na vandaag zijn gemaakt. Vink dit vakje aan als je ook oudere voorbeelden wilt zien',
  sample_show_all_samples_label: 'Toon alle voorbeelden',
  package_duplicate: 'Er bestaat reeds een verpakking met deze naam',
  common_production: 'Productie',
  common_project: 'Project',
  group_list_groups: 'Groepen',
  group_edit_group: 'Bewerk groep',
  group_new_group: 'Nieuwe groep',
  group_remove_group: 'Verwijder groep',
  group_close_modal: 'Afsluiten',
  group_duplicated_user: 'Deze gebruiker is al aan deze groep toegevoegd!',
  group_no_name_message: 'Het is nodig om een â€‹â€‹groepsnaam in te voeren',
  group_no_zone_message: 'Het is noodzakelijk om een â€‹â€‹zone te selecteren',
  group_no_users_message:
    'Het is noodzakelijk om ten minste Ã©Ã©n proever te selecteren',
  family_list_families: 'Stijlen',
  family_edit_family: 'Bewerk stijl',
  family_new_family: 'Nieuwe stijl',
  family_remove_family: 'Verwijder stijl',
  family_has_no_english_name:
    'het is verplicht om de stijlnaam in het Engels te hebben',
  brand_list_brands: 'Merken',
  brand_edit_brand: 'Bewerk merk',
  brand_new_brand: 'Nieuw merk',
  brand_remove_brand: 'Verwijder merk',
  brand_profile_picture: 'Laad bier profiel afbeelding',
  brand_upload_brand_logo: 'Laad merklogo op',
  brand_duplicate: 'Opgepast: er is reeds een bier met deze naam',
  brand_has_no_english_name:
    'Je moet een merknaam en een bijbehorend bierprofiel invullen',
  brand_upload_help_message: 'Alleen PNG- en JPG-bestanden',
  brand_has_no_product_type: 'U moet een producttype selecteren',
  package_list_packages: 'Verpakkingen',
  package_edit_package: 'Bewerk verpakking',
  package_new_package: 'Nieuwe verpakking',
  package_remove_package: 'Verwijder verpakking',
  package_has_no_english_name:
    'het is verplicht om de naam van het verpakking in het Engels te hebben',
  package_volumes: 'Volumes',
  package_new_volume: 'Nieuwe volume',
  package_remove_volume: 'Verwijder Volume',
  degree_list_degrees: 'Intensiteiten',
  degree_edit_degree: 'Bewerk intensiteit',
  degree_new_degree: 'Nieuwe intensiteit',
  degree_remove_degree: 'Verwijder intensiteit',
  category_list_categories: 'Categories',
  category_edit_category: 'Bewerk smaak categorie',
  category_new_category: 'Nieuwe smaak categorie',
  category_remove_category: 'Verwijder smaak categorie',
  comment_list_comments: 'Attributen',
  comment_edit_comment: 'Bewerk smaakattribuut',
  comment_new_comment: 'Nieuw smaakattribuut',
  comment_remove_comment: 'Verwijder smaakattribuut',
  user_list_users: 'Gebruiker',
  user_edit_user: 'Bewerk gebruiker',
  user_new_user: 'Nieuwe externe gebruiker',
  user_export_users: 'Export Users',
  user_list_profiles: 'Profielen',
  user_profile_name: 'Profiel naam',
  user_profile_select: 'Selecteer profiel',
  user_profile_remove: 'Verwijder profiel',
  user_profile_save: 'Bewaar profiel',
  user_remove_user: 'Verwijder gebruiker',
  user_use_terms: 'Aanvaarde gebruikersvoorwaarden',
  report_list_report: 'Rapporten',
  report_end_date: 'Eind datum',
  common_privileges: 'Privileges',
  common_abi_name: 'ABI naam',
  common_id: 'ID',
  common_ranked: 'Gerangschikt',
  common_week: 'Week',
  common_weeks: 'Weken',
  common_days: 'Dagen',
  common_category: 'Smaak categorie',
  common_category_sample: 'Categorie',
  common_degree: 'Intensiteit',
  common_comment: 'Smaakattribuut',
  common_status: 'Status',
  common_zone: 'Zone',
  common_country: 'Land',
  common_site: 'Site',
  common_start_date: 'Begin datum',
  common_finish_date: 'Eind datum',
  common_round_type: 'Test type',
  common_round: 'Ronde',
  common_round_id: 'ID Ronde',
  common_tasters: 'Proevers',
  common_sample_performance: 'Voorbeeldprestaties',
  common_taste: 'Smaak',
  common_key_taster: 'Key proever',
  common_sample: 'Staal',
  common_samples: 'Stalen',
  common_sample_type: 'Type staal',
  common_family: 'Stijl',
  common_brand: 'Merk',
  common_product_type: 'product type',
  common_temperature: 'Opslagvoorwaarde',
  common_storage_times: 'Opslagtijd',
  common_sku: 'SKU',
  common_batch: 'ID code',
  common_kpi_month: 'KPI maand',
  common_logo: 'Logo',
  common_name: 'Naam',
  common_notes: "Nota's",
  common_email: 'E-mail',
  common_language: 'Taal',
  common_terms_of_use: 'Gebruiksvoorwaarden',
  common_profile: 'Profiel',
  common_filter: 'Filter gebruiker',
  common_download: 'Downloaden',
  common_save: 'Bewaar',
  common_cancel: 'Annuleer',
  common_search_users: 'Zoek gebruiker',
  common_search_group: 'Zoek groep',
  common_search_taster: 'Zoek proever',
  common_no_data: 'Geen gegevens om te tonen',
  common_loading: 'Laden',
  common_cannot_be_empty: '{{field}} mag niet leeg zijn',
  common_select_option: 'Selecteer optie ...',
  common_per_page: 'Rijen per pagina',
  common_attributes: 'Attributen',
  common_not_started: 'Niet begonnen',
  common_in_progress: 'Bezig',
  common_finished: 'Afgewerkt',
  common_start: 'Begin',
  common_detail: 'Details',
  common_position: 'Positie',
  common_score: 'Score',
  common_score_scale: 'Score schaal',
  common_intensity: 'Intensiteit',
  common_report_not_have_data: 'De geselecteerde rondes hadden geen stemmen',
  month_0: 'Januari',
  month_1: 'Februari',
  month_2: 'Maart',
  month_3: 'April',
  month_4: 'Mei',
  month_5: 'Juni',
  month_6: 'Juli',
  month_7: 'Augustus',
  month_8: 'September',
  month_9: 'Oktober',
  month_10: 'November',
  month_11: 'December',
  dropdown_change_picture: 'Wijzig afbeelding',
  dropdown_language: 'Taal',
  dropdown_logout: 'Afmelden',
  round_not_started: 'Niet begonnen',
  round_in_progress: 'Bezig',
  round_finished: 'Afgewerkt',
  round_filter_start: 'Begin',
  round_round_type_in_profile: 'In-Profile',
  tasting_list_rounds: 'Lijstrondes',
  menu_tasting_session: 'Proeverij',
  menu_panel_management: 'Paneelbeheer',
  menu_process_management: 'Procesmanagement',
  menu_product_management: 'Product management',
  menu_attributes_management: 'Attributenbeheer',
  tasting_finish_sample: 'BeÃ«indig het monster',
  tasting_comments: 'Opmerkingen',
  tasting_beer_profile: 'Bier profiel',
  tasting_need_select_score_comments:
    'U moet een score instellen en ten minste Ã©Ã©n opmerking toevoegen om door te gaan ...',
  tasting_finish_taste: 'Eindig de smaak',
  common_no_comments: 'Geen reacties',
  review_submit_title:
    'Weet u zeker dat u klaar bent om uw resultaten in te dienen?',
  review_submit_description: 'U kunt dit later niet meer bewerken',
  review_finish_taste: 'Einde degustatie',
  review_warning_sample_title: 'Je hebt niet geproefde stalen!',
  review_warning_sample_description: 'Wilt u doorgaan zonder ze te proeven?',
  review_warning_sample_review: 'Herzien',
  review_warning_sample_ok: 'Ga door',
  review_warning_tie_sample_title: 'Du har slipsprÃ¸ver!',
  review_warning_tie_sample_description:
    'Du kan ikke fortsette uten Ã¥ rangere dem!',
  review_warning_tie_sample_review: 'Anmeldelse',
  common_submit: 'Indienen',
  common_discussion: 'Bespreking',
  discussion_order_number: 'Sorteer op positie',
  discussion_order_brewery: 'Sorteer op brouwerij',
  discussion_order_ranking: 'Sorteer op ranking',
  discussion_order_brand: 'Sorteer op merk',
  discussion_order_score: 'Sorteer op score',
  discussion_order_key_score: 'Sorteer op sleutelscore',
  discussion_order_overall_rank: 'Sorteer op algemene rankingscore',
  discussion_order_key_rank: 'Orde op sleutelrang',
  discussion_key_ranking: 'Sleutelrangschikking',
  discussion_export_error: 'Het bestand kon niet worden opgeslagen!',
  discussion_ranking: 'Ranking',
  discussion_overall_ranking: 'Algemeen klassement',
  common_brewery: 'Brouwerij',
  common_trial_identifier: 'ID testcode',
  common_key_score: 'Key proever score',
  round_report: 'Exporteer naar Excel',
  common_comments: 'Commentaren',
  common_message_empty_profile:
    'Il nome del profilo non Ã¨ consentito vuoto o vuoto',
  common_message_out_score_range: 'score buiten bereik',
  tasting_need_select_score: 'U moet eerst een score instellen!',
  common_category_name: 'Categorie',
  categories_new_category: 'Nieuwe categorie',
  categories_remove_category: 'Wilt u de categorie echt verwijderen',
  categories_list_categories: 'Categorielijst',
  common_category_sample_name: 'Monsters',
  categories_new_category_sample: 'Nieuw voorbeeld',
  categories_remove_category_sample: 'Wilt u het voorbeeld echt verwijderen',
  categories_list_categories_samples: 'Voorbeeldvermelding',
  common_duplicate: 'Duplicaat',
  tasting_raw_materials: 'Grondstoffen',
  tasting_example_sensory_risk:
    'Voorbeeld van het belangrijkste sensorische risico: niet-overeenstemming met de verwachte smaak',
  tasting_procedure: 'Procedure',
  tasting_sample_preparation: 'Monstervoorbereiding',
  common_collection_point: 'Verzamel punt',
  common_collection_date: 'Verzameldatum',
  common_brew_number: 'Zet nummer/MES nummer',
  common_need_explain_nok:
    "Vous ne pouvez pas avoir d'Ã©chantillons NOK sans commentaire!",
  common_show_all: 'Toon alles',
  common_import: 'Importeren',
  common_download_template: 'Download sjabloon',
  common_need_volte_first:
    'U kunt geen opmerkingen plaatsen zonder eerst te stemmen!',
  common_name_template: 'Sjabloonnaam',
  common_all: 'Alle',
  common_continue: 'Doorgaan met',
  common_minimum_score: 'Minimumscore',
  common_maximum_score: 'Maximale score',
  common_average_score: 'Gemiddelde score',
  file_upload_fail:
    'Het uploaden van het bestand is mislukt, de volgende fouten zijn opgetreden: ',
  file_upload_success_with_error:
    'Het uploaden is gelukt, met uitzondering van het volgende: ',
  missing_fields: 'ontbrekende velden',
  column_error: 'kolomfout',
  common_line: 'Lijn',
  file_no_samples: 'Geen voorbeelden gevonden in het bestand!',
  file_success_upload: 'Upload bestand succesvol!',
  file_duplicated_kpi_project: 'Gedupliceerde KPI of Project',
  round_schedule_maximum_length:
    'De maximale lengte van de ronde naam is 40 tekens.',
  sample_malt_varieties: 'MoutvariÃ«teiten',
  malt_varieties_has_no_english_name:
    'U moet ten minste Ã©Ã©n naam van een moutvariÃ«teit hebben.',
  malt_varieties_duplicate:
    'Let op: er zijn verschillende soorten mout met deze naam',
  malt_varieties_remove: 'MoutvariÃ«teit verwijderen',
  common_back: 'Rug',
  common_next: 'De volgende',
  malt_varieties_new: 'Nieuwe variÃ«teit',
  reports_no_dates: 'Startdatum en Einddatum zijn verplicht!',
  reports_date_range_message: 'Het datumbereik mag niet groter zijn dan 1 jaar',
  round_save_select_site:
    'Om een â€‹â€‹ronde op te slaan moet je een locatie selecteren',
  common_horizontal: 'Horizontaal',
  common_unit: 'Eenheid',
  common_supplier: 'Leverancier',
  common_flavor: 'Smaak',
  concentration_of_the_base_beer: 'Concentratie van het basisbier',
  threshold_aml_method: 'AML-methode (oplopende methode van limieten)',
  threshold_component_concentration: 'Componentconcentratie',
  threshold_sample_different_help: 'Welk monster is anders?',
  threshold_each_set_random_order: 'In elke set, willekeurige volgorde.',
  threshold_select_base_beer: 'Selecteer basisbier',
  common_unity: 'Eenheid',
  common_finish_session: 'Sessie beÃ«indigen',
  review_submit_threshold: 'Weet u zeker dat u wilt opslaan?',
  threshold_coder_wrong: 'Je code klopt niet. Geef de juiste code door!',
  threshold_didnt_enter_the_code: 'Voer uw stoelnummer in',
  code: 'Code',
  threshold_samples_required: 'Basisbier en smaak zijn vereist',
  threshold_information_helper_sample:
    'Om informatie over drempelwaarden op te slaan, klikt u op de knop Opslaan om uw wijzigingen te bewaren',
  code_digits: 'Voer de 3-cijferige code in die aan u is toegewezen',
  common_batch_code: 'Batchcode',
  threshold_sample_spiked_with:
    'Kies op elke set het monster dat is verrijkt met ',
  threshold_code_already_tasted:
    'Deze code is al geproefd door een andere gebruiker. Voer een andere code in.',
  common_kpi_brands: 'KPI-merken',
  common_year: 'Jaar',
  common_action: 'Actie',
  common_comment_by_category: 'Opmerkingen per categorie',
  common_alphabetic_comment: 'Alfabetische opmerkingen',
  common_name_pts_code: 'PTS code',
  common_name_pts_code_placeholder: 'Code',
  common_name_process_item: 'Procesitem',
  common_name_process_item_placeholder: 'Item',
  kpi_only_numbers_warning: 'Accepteer alleen getallen op Streefwaarden',
  kpi_max_number_range_warning: 'Uw doelmerk moet tussen 1 en 9 . zijn',
  kpi_already_selected_brand_warning:
    'Je hebt dit merk geselecteerd, kies een ander merk',
  kpi_no_kpi_informed_warning: 'Je hebt geen toegevoegde waarde',
  kpi_select_year_warning: 'Selecteer een jaar',
  kpi_select_site_warning: 'Selecteer een site',
  kpi_existing_configuration_warning:
    'Je hebt kpi-waarden van dezelfde site en hetzelfde jaar',
  kpi_target_empty_warning: 'Een target van een van uw merken is leeg',
  kpi_saved_successfully: 'Uw waarden zijn succesvol opgeslagen!',
  kpi_submitted_successfully: 'Uw waarden zijn succesvol ingediend!',
  kpi_max_decimals_warning: 'Uw doelwaarde moet tot 3 decimalen zijn',
  kpi_count_as_kpi_sample: 'Tel als KPI',
  report_list_report_kpi: 'Rapporteert KPI',
  report_list_report_taste: 'Rapporten Smaak',
  select_brand_to_filter: 'Selecteer een wereldwijd merk!',
  select_year_to_filter: 'Kies een jaar!',
  samples_uncheck_kpi_sample_tooltip:
    'Schakel dit selectievakje uit als u niet wilt dat dit monster meetelt in de KPI',
  reports_mandatory_zone_filter: 'Selecteer ten minste Ã©Ã©n zone',
  submission_type: 'Tipo de confirmaÃ§Ã£o',
  custom_scale_title: 'Aangepaste schaal',
  custom_scale_grid_header_score: 'Scoren',
  custom_scale_grid_header_ageind_scale: 'Gedwongen verouderingsschaal',
  the_value_cannot_be_null: 'De waarde mag niet leeg zijn!',
  unique_id: 'Unieke identificatie',
  report_filter_rounds_description:
    'U moet de verplichte velden selecteren om ronde-opties te laden.',
  reports_mandatory_test_type: 'Selecteer het testtype.',
  round_reports_month_warning:
    'De periode tussen de startdatum en de einddatum mag maximaal 6 maanden bedragen.',
  zone_can_not_empty: 'Regio mag niet leeg zijn',
  error_loading_data:
    'Fout bij het laden van gegevens. Controleer uw verbinding en laad opnieuw.',
  flavor_identification: 'Smaak identificatie',
  beer_batch: 'Bier batch',
  list_of_beers: 'Bier lijst',
  random: 'Willekeurig',
  preview: 'Preview',
  select_the_flavor: 'Selecteer de smaak',
  flavor_threshold: 'Smaakdrempel',
  input_digital_number: 'Digitaal nummer invoeren',
  concentration: 'Concentratie',
  flavor_order: 'Smaak volgorde',
  last: 'Laatste',
  tasting: 'Degustatie',
  confirmation: 'Bevestiging',
  do_you_confirm_the_evaluation_of_this_sample:
    'Bevestigt u de evaluatie van dit monster?',
  confirm: 'Bevestig',
  evaluate: 'Evalueert',
  correct_answer: 'Juist antwoord',
  threshold: 'Drempel',
  let_a_comment_about_the_tasting: 'Geef een commentaar over de degustatie',
  send: 'Verzenden',
  right_now: 'Onmiddellijk',
  minute_ago: 'Minuut geleden',
  hour_ago: 'Uur geleden',
  day_before: 'Dag voordien',
  year_ago: 'Jaar geleden',
  add_a_flavor: 'Voeg een smaak toe',
  soft_drinks: 'Frisdrank',
  soft_drinks_brand: 'Frisdrank merk',
  soft_drinks_brand_target: 'Merkdoel frisdrank',
  column_options: 'Kolomopties',
  select_which_of_the_columns_do_you_want_to_be_displayed:
    'Selecteer welke kolommen u wilt weergeven.',
  countdown: 'aftellen',
  less_than_five_minutes:
    'Deze proeverij eindigt over 5 minuten, als u verder moet gaan, neem dan contact op met de beheerder om de einddatum uit te stellen.',
  the_countdown_to_the_evaluation_has_ended:
    'Het aftellen voor de beoordeling van het artikel is voorbij en er is geen toegang tot de beoordeling van het artikel.',
  counting_how_much_time_does_you_have_to_finish_tasting_the_samples:
    'Bereken hoeveel tijd je nodig hebt om deze monsters te proeven',
  only_six_flavors_can_be_added_at_most:
    'Er kunnen maximaal zes smaken worden toegevoegd!',
  concentration_of_the_flavor: 'Concentratie van de smaak',
  input_value: 'Invoerwaarde',
  Concentration_of_the_flavor_is_required:
    'Concentratie van de smaak is vereist',
  previous_round: 'Vorige ronde',
  next_round: 'Volgende ronde',
  the_end_time_is_greater_than_the_current_time:
    'De eindtijd moet groter zijn dan de huidige tijd, als u dezelfde dag als de huidige dag selecteert, selecteert u eerst het uur en de minuut!',
  check_the_answer_at_the_end_of_the_countdown:
    'Het aftellen is nog niet voorbij, u kunt de antwoorden na het aftellen bekijken',
  incremental_step: 'Incrementele stap',
  set3_concentration: 'Set3 Concentratie',
  concentration_increments_between_levels:
    'Concentratieverhogingen tussen niveaus',
  incremental_set3_concentration_required:
    'Incrementele stap en Set3 concentratie zijn vereist',
  sprint: 'Sprint',
  sprint_number_greater_than_zero: 'Sprintnummer moet groter zijn dan 0',
  sprint_notes_at_least_one_note:
    'Sprintnotities moeten ten minste één notitie hebben',
  sprint_patch_notes_success_update:
    'Sprint patch-notities bijgewerkt met succes.',
  sprint_patch_notes_error_update:
    'Het was niet mogelijk om de notities op te slaan.',
  update_sprint_patch_notes: 'Update Sprint Patch Notes',
  sprint_number: 'Sprintnummer',
  sprint_patch_notes: 'Sprint patch-opmerkingen',
  note: 'Opmerking',
  concentration_increments_between_levels:
    'Concentratieverhogingen tussen niveaus',
  incremental_set3_concentration_required:
    'Incrementele stap en Set3 concentratie zijn vereist',
  select_all: 'Alles selecteren',
  please_add_samples_first: 'Voeg eerst monster toe',
  concentration_increments_between_levels:
    'Concentratieverhogingen tussen niveaus',
  incremental_set3_concentration_required:
    'Incrementele stap en Set3 concentratie zijn vereist',
  reverse: 'Terugdraaien',
  new_test: 'Toegevoegde tests',
  triangle_test_name: 'Naam triangulatietest',
  triangle_test: 'Triangulatietest',
  you_can_not_set_three_identical_samples:
    'U kunt niet dezelfde drie opties instellen',
  its_mandatory_to_input_test_name: 'Gelieve de naam van de test in te vullen',
  its_mandatory_to_input_sample_information:
    'Gelieve de voorbeeldinformatie in te vullen',
  attention: 'prompt',
  test_number: 'Serienummer van de triangulatietest',
  you_selected_the: 'U heeft gekozen',
  next_sample: 'Deze test verwijderen',
  remove_triangle_test: 'Elimina questo test',
  you_can_only_select_up_to_five: 'Maximaal vijf selecties zijn toegestaan',
  test: 'Test',
  confirm_remove: 'Bevestigt u de verwijdering van de test?',
  reports_mandatory_zone_country_site_filter:
    "Regio's, landen, fabrieken zijn vereist!",
  reports_mandatory_brand_filter: 'Selecteer ten minste één merk',
  reports_by_producing_site: 'Volledig analyserapport',
  download_label: 'Knop Downloaden',
  standard: 'Standaardwaarde',
  input_test: 'Invoer van resultaten',
  detecting: 'Opsporen...',
  connected: 'Verbonden',
  current_taster: 'Huidige reviewers',
  alcohol_concentration: 'Alcohol concentratie',
  your_alcohol_test_result_exceeds_standard_please_test_again_later:
    'Uw alcoholconcentratie in het bloed is te hoog. Gelieve later opnieuw te testen!',
  alcohol_test_qualified: 'Slaag voor de alcoholtest',
  configuration: 'Aanleg',
  input_result: 'Invoer van resultaten',
  maximum_alcohol_concentration: 'Overmatige alcoholconcentratie',
  breathalyzer: 'Alcohol detector',
  please_input_a_positive_number_up_to_n_decimal_places:
    'Voer een positief getal in tot {{field}} decimalen',
  you_must_input: 'U moet invoeren',
  driving: 'Rijden',
  test_time: 'Testtijd',
  input_type: 'Soort invoer',
  list_breathalyzer: 'Ademhalingsalyser',
  Manual: 'Handmatig',
  Breathalyzer: 'Ademhalingsalyser',
  you_do_not_have_privilege_to_export_this_report:
    'U hebt geen bevoegdheid om dit rapport te exporteren',
  competitor_beer: 'Bier van de concurrent',
  split_round: 'Ronde splitsen',
  reset: 'Reset',
  how_many_rounds_splitting_do_you_want_to_make:
    'Hoeveel rondes splitsen wil je maken',
  you_cannot_split_a_round_that_has_already_been_split_or_in_progress:
    'U kunt een ronde die al is gesplitst of in uitvoering is niet splitsen',
  hide_sample_details: 'Voorbeeldgegevens verbergen',
  data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id:
    'Gegevens worden verborgen voor de proever: Brouwerij, productiedatum, merk, moutsoort en monster ID',
  you_can_not_add_duplicate_attributes:
    'U kunt geen dubbele attributen toevoegen.',
  menu: 'MENU',
  link_breathalyzer: 'Verbonden',
  not_link_breathalyzer: 'Onverbonden',
  breathalyzer_to_link: 'Breathalyzer verbinden',
  breathalyzer_can_not_to_link_for_this_browser:
    'De ademanalysefunctie is ontwikkeld om te werken op Google Chrome, met behulp van pc-apparaten.',
  reopen: 'Opnieuw openen',
  round_reopen_message: 'Weet je zeker dat je de ronde wilt heropenen',
  common_production_line: 'Productielijn',
  common_packaged_beer_data_code: 'Code voor verpakte biergegevens',
  common_masking_process_for_bottles: 'Maskerproces voor flessen',
  common_str_number_days_over_aged: 'Aantal dagen bij overmatige leeftijd',
  common_over_aged_beer: 'Oververouderd bier',
  sample_successfully_swapped: 'De uitwisseling van monsters was succesvol.',
  scoring: 'Scoring',
  configure_available_flavors: 'Beschikbare smaken instellen',
  process_check: 'Procescontrole',
  new_process_check: 'Nieuwe procescontrole',
  edit_process_check: 'Procescontrole bewerken',
  remove_process_check: 'Procescontrole verwijdere',
  common_package: 'Pakket',
  common_department: 'Afdeling',
  common_shift: 'Shift',
  common_tank: 'Tank',
  common_oknok: 'OKNOK',
  common_remark: 'Opmerkingen',
  show_all_process_check: 'Alle procescontrole tonen',
  show_all_process_check_help_circle:
    'Toon de gegevens die binnen een half jaar na vandaag zijn gemaakt. Vink dit vakje aan als u nog gegevens wilt zien die binnen een jaar na vandaag zijn gemaakt',
  the_sample_date_cannot_be_earlier_than_the_current_time:
    'De steekproefdatum kan niet eerder zijn dan de huidige tijd!',
  common_job_position: 'werkpositie',
  decimal_sep: 'Decimaal scheidingsteken',
  is_swapped_to: 'Sont échangés pour',
  review_warning_comment_title: 'Je hebt voorbeelden zonder commentaar!',
  continuous_typing: 'Continue invoer',
  continuous_typing_tips:
    "Begin met typen van 'Attribute' na het selecteren/typen van 'Intensiteit'.",
  BSSI_Reports: 'BSSI verslag',
  data_analysis_sip: 'Gegevensanalyse - SIP',
  select_the_color: 'Selecteer de kleur',
  Color: 'kleur',
  download_document: 'Bestanden downloaden',
  Month_of_Discontinued_Production: 'Aantal maanden',
  Suspension_List: 'Lijst van uit produktie genomen produkten',
  New_Suspension: 'Nieuwe aanvraag voor stopzetting van de productie',
  new_suspension_page: 'Nieuwe informatie uit productie',
  edit_suspension_page: 'Bewerken van de beeindigde informatie',
  Brand_name: 'Naam van het merk',
  List_Brewery_Production_Suspension: 'Lijst van stilgelegde fabrieken',
  Upload_successfully: 'Succesvolle upload van bestanden',
  training_materials: 'Klik voor het trainingsmateriaal.',
  Remove_Suspension: 'Verwijderen van de schorsing: ',
  Add_Absence_Reason: 'Redenen voor verzuim toevoegen',
  Reason: 'oorzaken',
  You_have_duplicate_absence_dates:
    'U heeft een aantal dagen van herhaalde afwezigheid',
  Remove_Absence: 'Geschrapt zonder: ',
  Absence_Title:
    'Gelieve het tijdstip en de reden van de afwezigheid van de proeverij te kiezen',
  Shipping_Site: 'Website voor verzending',
  shipping_received: 'verzending',
  receive_sample_alert: 'Weet u zeker dat u de monsters hebt ontvangen?',
  receive_uncheck_alert: 'Weet u zeker dat u het vinkje wilt verwijderen?',
  all_received: 'totaal',
  not_all_received: 'Niet volledig ontvangen',
  not_for_shipping: 'Niet bestemd voor vervoer',
  receive_hint: 'Selecteer alle sites die dit voorbeeld ontvangen',
  absence_reason: 'Redenen voor afwezigheid',
  Page1: 'BLZ. {{field}}',
  Search: 'zoeken',
  page_total: 'Totaal aantal',
  start_date: 'Begin datum',
  end_date: 'Eind datum',
  ok_for_all: 'Goed voor iedereen',
  its_mandatory_to_select_sample_A_and_sample_B:
    'Monsters A en B moeten worden genomen.',
  its_mandatory_to_select_sample_A: 'Monster A moet worden gekozen.',
  its_mandatory_to_select_sample_B: 'Monster B moet worden gekozen.',
  triangle_comment: 'Commentaar',
  select_sample: 'Selectie van de monsters',
  sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration:
    'Voorbeelddatumfilter, standaard laatste zeven dagen, exporteer naar Excel op basis van deze gefilterde duur.',
  select_all_flavor: 'Selecteer alles',
  deselect_all: 'Alles annuleren',
  the_score_entered_is_invalid:
    'De ingevoerde score is ongeldig. Raadpleeg de scoreschaal en voer uw score opnieuw in.',
  service_now:
    'Klik om een evenementticket aan te maken om een probleem te melden en hulp te krijgen.',
  service_now_tips:
    'Hey, als je een probleem ziet of om ondersteuning wilt vragen, klik dan hier en meld het direct in serviceNow, ons supportteam neemt zo snel mogelijk contact met je op.',
};
