import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import tastingVoting from './tastingVoting.module.scss';
import { useHistory } from 'react-router-dom';
import {
  Label,
  CardColumn,
  CardRow,
  Slider,
  Button,
  TextField,
  ButtonList,
  AttributesCategory,
  AutoComplete,
  ImageZoom,
  Modal,
  ScoreScale,
  PdfViewerModal,
  Checkbox,
  Accordion,
  FreshnessFiveScoreScale,
  Spinner,
  NotificationBar,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import RoundAppService from '../../services/rounds/roundApp.service';
import UserService from '../../services/users/user.service';

import { X, ArrowLeft, HelpCircle } from 'react-feather';
import uuid from 'react-uuid';
import { useTasting } from '../../../contexts/Tasting';
import { useUser } from '../../../contexts/User';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Switch from 'react-switch';
import useTastingVoting from './hooks';
import { GetSocket } from '../../../utils/socketIo';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import configs from '../../../configs';

const fuzzysort = require('fuzzysort');

const TastingVoting = ({ location }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    setCurrentQueue,
    sampleAppService,
    setLoading,
    loading,
    currentReqs,
    loadingComments,
    setLoadingComments,
    error,
    setError,
  } = useTastingVoting();

  const roundAppService = useMemo(() => new RoundAppService(), []);
  const userService = new UserService();

  const {
    taste,
    sample,
    updateScoreSample,
    removeCommentSample,
    updateRound,
    updateSampleComments,
    updateApproveSample,
    handleTasteSamples,
    handleSample,
    loadTaste,
  } = useTasting();

  const { user } = useUser();

  const [score, setScore] = useState(
    sample.flt_score !== null && sample.flt_score !== ''
      ? Number(sample.flt_score)
      : null,
  );
  const [degrees, setDegrees] = useState([]);
  const [attrs, setAttrs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loadingRanks, setLoadingRanks] = useState(false);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [round, setRound] = useState();
  const [countComment, setCountComment] = useState(0);
  const inputText = useRef(null);
  const [imgPdf, setImgPdf] = useState(null);
  const [open, setOpen] = useState(false);
  const [isApprove, setIsApprove] = useState(true);
  const [isRoundApprove, setIsRoundApprove] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [fullButton, setFullButton] = useState('');
  const [fullHeight, setHeight] = useState('');
  const [isContinuous, setIsContinuous] = useState(false);
  const isZhOrKr = JSON.parse(
    user.str_language === 'zh' || user.str_language === 'kr',
  );
  const [notificationArr, setNotificationArr] = useState([]);
  const bubbleArr = useRef([]);
  const nottificationRef = useRef({});
  const sampleRef = useRef(sample);
  const [temporaryScore, setTemporaryScore] = useState(sample.flt_score !== null && sample.flt_score !== ''
    ? Number(sample.flt_score)
    : null,)

  useEffect(() => {
    refreshState();
  }, [taste]);

  useEffect(() => {
    bubbleArr.current = notificationArr;
  }, [notificationArr]);

  const handleSampleToNew = () => {
    sampleRef.current = {
      ...JSON.parse(localStorage.getItem('sample')),
    };
  };

  const handleSocketOn = async (socketName, res) => {
    handleSampleToNew();
    const resUuidRound = res.uuid_round ? res.uuid_round : '';
    let nowRound = resUuidRound === location.state.round.uuid_round;

    let sampleNameStr = '';
    if (socketName === 'addUpdateSample') {
      for (let i = 0; i < res.updateSampleArr.length; i++) {
        const lastDom = i + 1 !== res.updateSampleArr.length ? ', ' : '';
        sampleNameStr += res.updateSampleArr[i].str_brand_name + lastDom;
      }
      nowRound = nowRound && !!sampleNameStr;
    }

    if (nowRound) {
      if (socketName === 'updateTasteSampleID') {
        const resUuidOldSample = res.uuid_old_sample ? res.uuid_old_sample : '';
        const resStrName = res.str_name ? res.str_name : '';
        const resUuidSample = res.uuid_sample ? res.uuid_sample : '';
        if (sampleRef.current.uuid_sample === resUuidOldSample) {
          await handleSample({
            ...sampleRef.current,
            uuid_sample: resUuidSample,
            str_name: resStrName,
          });
        }
        loadTaste({
          uuid_round: location.state.round.uuid_round,
          uuid_round_type: '',
        });
      }
      if (
        nottificationRef.current &&
        bubbleArr.current &&
        bubbleArr.current.length > 4
      ) {
        nottificationRef.current.scrollBottom &&
          nottificationRef.current.scrollBottom();
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      GetSocket().on('updateTasteSampleID', async function (res) {
        if (window.location.pathname === '/tasting_voting') {
          handleSocketOn('updateTasteSampleID', res);
        }
      });
      GetSocket().on('addUpdateSample', async function (res) {
        if (window.location.pathname === '/tasting_voting') {
          handleSocketOn('addUpdateSample', res);
        }
      });
    });
    userService
      .getUserContinuousTyping()
      .then(res => {
        if (res.results.length == 0) {
          setIsContinuous(false);
        } else {
          console.log(1)
          setIsContinuous(res.results[0].bol_continuous_type);
        }
      })
      .catch(error => { });
  }, []);

  const handleListComments = useCallback(() => {
    setLoading(true);
    setLoadingComments(true);
    roundAppService
      .listComments()
      .then(res => {
        const reduceArr = res.reduce((accumulator, currentValue) => {
          const existingObject = accumulator.find(
            obj => obj.uuid_comment === currentValue.uuid_comment,
          );
          if (!existingObject) {
            accumulator.push(currentValue);
          }
          return accumulator;
        }, []);
        setAttrs(reduceArr);
        setLoading(false);
        setLoadingComments(false);
      })
      .catch(error => {
        setLoading(false);
        setLoadingComments(false);
        setError(true);
      });
  }, [roundAppService, setLoading, setLoadingComments, setError]);

  const handleListDegrees = useCallback(() => {
    setLoading(true);
    roundAppService
      .listDegrees()
      .then(res => {
        setDegrees(res);
        setLoading(false);
      })
      .catch(error => {
        setError(true);
        setLoading(false);
      });
  }, [roundAppService, setLoading, setDegrees, setError]);

  const handleListCategories = useCallback(() => {
    setLoading(true);
    roundAppService
      .listCommentsCategory()
      .then(res => {
        setCategories(res);
        setLoading(false);
      })
      .catch(error => {
        setError(true);
        setLoading(false);
      });
  }, [roundAppService, setLoading, setCategories, setError]);

  const refreshState = () => {
    setLoading(true);
    setIsRoundApprove(
      taste.bol_is_approve_reject &&
      taste.uuid_round_type === '4ed984d9-021d-4d06-a992-991a24b3d7ce',
    );
    setIsApprove(
      sample &&
        (sample.bol_user_approve_reject ||
          sample.bol_user_approve_reject === null)
        ? true
        : false,
    );
    setRound(taste);
    setScore(score !== '' && score !== null ? score : null);
    setComments(sample && sample.arr_comments ? sample.arr_comments : []);
    setCountComment(
      sample && sample.arr_comments ? sample.arr_comments.length : 0,
    );

    setImgPdf(
      sample &&
        sample.str_img_profile &&
        sample.str_img_profile.indexOf('.') > 1
        ? sample.str_img_profile.split('.').pop()
        : null,
    );
    handleListComments();
    handleListDegrees();
    handleListCategories();
  };

  const handleSubmitRank = useCallback(
    (sample, rank) => {
      return sampleAppService
        .updateRank(sample.uuid_round, sample.uuid_sample, rank)
        .then(
          res => {
            if (res.type === 'success') {
            } else {
              window.alert(res.message);
            }
            setLoading(false);
          },
          error => {
            setLoading(false);
          },
        );
    },
    [sampleAppService, setLoading],
  );

  const getRangeOfChange = useCallback(
    (from, to) => {
      const reduceIntRank = from >= to;
      const changeRange = [...taste.arr_samples].filter(item => {
        if (reduceIntRank && item.flt_score <= from && item.flt_score >= to) {
          return item;
        }
        if (!reduceIntRank && item.flt_score >= from && item.flt_score <= to) {
          return item;
        }
      });
      const changeRangeMapped = changeRange.map(item => item.flt_score);
      return { reduceIntRank, changeRange: changeRangeMapped };
    },
    [taste.arr_samples],
  );

  const handleRankSamples = useCallback(
    saveScoreObj => {
      if (score !== '' && score !== null) {
        setLoading(true);
        setLoadingRanks(true);
        sampleAppService
          .updateScore(saveScoreObj)
          .then(async res => {
            updateScoreSample(
              score !== '' && score !== null ? Number(score) : null,
            );
            handleSubmitRank(sample, null);
            if (
              sample &&
              parseFloat(score || 0) !== parseFloat(sample.flt_score || 0)
            ) {
              setLoadingRanks(true);
              let tempTaste = { ...taste };
              const { reduceIntRank, changeRange } = getRangeOfChange(
                parseFloat(sample.flt_score || 0),
                parseFloat(score || 0),
              );
              const newTaste = tempTaste.arr_samples.map(currentSample => {
                if (
                  currentSample.flt_score === parseFloat(score || 0) ||
                  currentSample.flt_score === parseFloat(sample.flt_score || 0)
                ) {
                  return {
                    ...currentSample,
                    flt_score:
                      currentSample.uuid_sample === sample.uuid_sample
                        ? Number(score)
                        : currentSample.flt_score !== '' &&
                          currentSample.flt_score !== null
                          ? Number(currentSample.flt_score)
                          : null,
                    int_rank: null,
                  };
                } else if (
                  reduceIntRank &&
                  changeRange.includes(currentSample.flt_score)
                ) {
                  return {
                    ...currentSample,
                    int_rank: currentSample.int_rank - 1,
                  };
                } else if (
                  !reduceIntRank &&
                  changeRange.includes(currentSample.flt_score)
                ) {
                  return {
                    ...currentSample,
                    int_rank: currentSample.int_rank + 1,
                  };
                } else {
                  return currentSample;
                }
              });
              Promise.all(
                newTaste.map(currentTaste =>
                  handleSubmitRank(currentTaste, currentTaste.int_rank),
                ),
              ).then(() => {
                setLoadingRanks(false);
              });
              handleTasteSamples(newTaste);
            } else {
              setLoadingRanks(false);
            }
            setLoading(false);
          })
          .catch(error => {
            setLoading(false);
            setLoadingRanks(false);
            console.log(error);
          });
      }
      if (score === '') {
        setLoading(true);
        sampleAppService
          .updateScore(saveScoreObj)
          .then(res => {
            updateScoreSample(
              score !== '' && score !== null ? Number(score) : null,
            );
            setLoadingRanks(true);
            handleSubmitRank(sample, null);
            let tempTaste = { ...taste };
            const newTaste = tempTaste.arr_samples.map(currentSample => {
              if (
                currentSample.flt_score <= sample.flt_score &&
                currentSample.uuid_sample !== sample.uuid_sample
              ) {
                return {
                  ...currentSample,
                  int_rank: currentSample.int_rank - 1,
                };
              } else {
                return currentSample;
              }
            });
            Promise.all(
              newTaste.map(currentTaste =>
                handleSubmitRank(currentTaste, currentTaste.int_rank),
              ),
            ).then(() => {
              setLoadingRanks(false);
            });
            handleTasteSamples(newTaste);
            setLoading(false);
          })
          .catch(error => {
            setLoading(false);
            setLoadingRanks(false);
            console.log(error);
          });
      }
    },
    [
      handleTasteSamples,
      getRangeOfChange,
      handleSubmitRank,
      sample,
      sampleAppService,
      score,
      taste,
      setLoading,
      updateScoreSample,
    ],
  );

  useEffect(
    prev => {
      const saveScoreObj = {
        uuid_round: taste?.uuid_round,
        uuid_sample: sample?.uuid_sample,
        int_order_app: sample?.int_order_app,
        flt_score: score !== '' && score !== null ? Number(score) : null,
      };
      handleRankSamples(saveScoreObj);
    },
    [score],
  );

  const handleTypingChange = () => {
    setIsContinuous(!isContinuous);
    userService.updateUserContinuousTyping({
      bol_continuous_type: !isContinuous,
    });
  };

  const handleFirstComment = () => {
    const saveScoreObj = {
      uuid_round: round?.uuid_round,
      uuid_sample: sample?.uuid_sample,
      flt_score: null,
    };

    sampleAppService
      .updateScore(saveScoreObj)
      .then(res => { })
      .catch(error => { });
  };

  const delayedQuery = useCallback(
    _.debounce(objScore => handleScoreChange(objScore), configs.defaultDebounce),
    [],
  );

  const handleScoreChange = objScore => {
    let value = objScore
      ? objScore.score
        .toString()
        .replace(/[^0-9\,.]+/g, '')
        .replace(/[,]+/g, '.')
      : null;

    if (value) {
      if (objScore.step % 1 !== 0) {
        if (value.trim() !== '.') {
          value = objScore.step === 0.25 ? value.match(/^-?\d+(?:\.\d{0,2})?/)[0] : value.match(/^-?\d+(?:\.\d{0,1})?/)[0];
        }
        let decimal = value.split('.');

        if (decimal.length > 1) {
          if (
            Number(objScore.step === 0.25 && decimal[1].length === 1 ? decimal[1] + '0' : decimal[1]) %
            Number(objScore.step.toString().split('.')[1]) !==
            0
          ) {
            alert(t('the_score_entered_is_invalid'))
            value = `${decimal[0]}.`;
          }
        }
      } else {
        value = value.match(/^-?\d+(?:.\\d{0,0})?/)[0];
      }
    }

    if (Number(value) >= objScore.min && Number(value) <= objScore.max) {
      setTemporaryScore(value);
      setScore(value);
    } else {
      setScore('');
      setTemporaryScore('');
      if (objScore.score) {
        window.alert(
          `"${objScore.score}" ${t('common_message_out_score_range')}`,
        );
      }
    }
  };

  const handleClickDegrees = e => {
    if (!score) {
      handleFirstComment();
    }

    if (e.target.value) {
      let degreeSelected = degrees.filter(
        degree => degree.str_name === e.target.value,
      );
      setComment({
        str_degree: degreeSelected[0].str_name,
        uuid_degree: degreeSelected[0].uuid_degree,
      });
    }
  };

  const handleClickAttrs = (e, current) => {
    if (!score) {
      handleFirstComment();
    }

    if (e.target.value) {
      const res = {
        target: {
          value: attrs.filter(attr => attr.str_name === e.target.value)[0],
          name: 'comments',
        },
      };
      handleSelectComments(res);
    }
  };

  const handleSearchComments = text => {
    return new Promise((resolve, reject) => {
      let searchArray = [];
      const keyword = text.toString().trim().toLowerCase();
      if (!comment?.str_degree && keyword) {
        searchArray = [...degrees, ...attrs];
      } else {
        searchArray = [...attrs];
      }

      let returnedArray = [];

      returnedArray = fuzzysort
        .go(keyword, searchArray, { key: 'str_name' })
        .map(a => a.obj);

      if (!isZhOrKr) {
        if (keyword.length <= 2) {
          returnedArray = [];
        }
      }

      if (!comment?.str_degree && keyword.length == 0) {
        resolve([]);
      } else {
        resolve(returnedArray);
      }
    });
  };

  const handleSelectComments = evt => {
    let selectedComment = evt.target.value;
    if (typeof evt.target.value == 'string' && !evt.target.value.trim()) return;

    if (evt.target && selectedComment) {
      if (
        comment ||
        selectedComment.uuid_comment ||
        typeof selectedComment === 'string'
      ) {
        if (
          (selectedComment.str_name && selectedComment.uuid_comment) ||
          typeof selectedComment === 'string'
        ) {
          let hasComment = comments.find(
            commentFilter =>
              commentFilter.uuid_comment === selectedComment.uuid_comment ||
              (!comment &&
                commentFilter.uuid_comment === selectedComment.uuid_comment) ||
              (!comment && commentFilter.str_comment === selectedComment) ||
              (commentFilter.str_comment === selectedComment &&
                comment?.uuid_degree === commentFilter.uuid_degree),
          );

          if (!!!hasComment) {
            let uuid_answer = uuid();
            let newComment = {
              ...comment,
              str_comment: selectedComment.str_name
                ? selectedComment.str_name
                : selectedComment,
              uuid_comment: selectedComment.uuid_comment
                ? selectedComment.uuid_comment
                : uuid(),
              uuid_round: round.uuid_round,
              uuid_sample: sample.uuid_sample,
              uuid_answers_rounds_users_samples_comments: uuid_answer,
              int_order: countComment,
              int_order_app: sample?.int_order_app,
            };

            if (!newComment.uuid_degree && !newComment.str_degree) {
              newComment.uuid_degree = null;
              newComment.str_degree = null;
            }

            if (typeof selectedComment === 'string') {
              newComment.uuid_comment = null;
            }

            setComments([...comments, newComment]);

            setCountComment(prevState => {
              return prevState + 1;
            });
            setCurrentQueue(prev => [...prev, newComment]);
          } else {
            window.alert(t('you_can_not_add_duplicate_attributes'));
          }
          setComment(null);
        }
      } else {
        if (selectedComment.str_name && selectedComment.uuid_degree) {
          setComment({
            str_degree: selectedComment.str_name,
            uuid_degree: selectedComment.uuid_degree,
          });
        }
      }
    }
  };

  const handleRemoveComment = rmComment => {
    if (!rmComment.uuid_answers_rounds_users_samples_comments) {
      setComment(null);
      return;
    }

    const newComments = comments.filter(
      commentfilter =>
        !(
          commentfilter.uuid_answers_rounds_users_samples_comments ===
          rmComment.uuid_answers_rounds_users_samples_comments
        ),
    );
    setComments(newComments);
    setCountComment(prevState => {
      return prevState - 1;
    });

    if (rmComment.uuid_answers_rounds_users_samples_comments) {
      sampleAppService.deleteComment(
        rmComment.uuid_answers_rounds_users_samples_comments,
      );
      removeCommentSample(
        rmComment.uuid_answers_rounds_users_samples_comments,
        user,
      );
    }
  };

  const handleShowImage = show => {
    setShowModal(!show);
  };

  const handleFinishSample = () => {
    updateRound().then(() => {
      history.replace({
        pathname: `/tasting_review`,
        state: {
          from: 'discussion',
          round: location.state.round,
        },
      });
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const move = arrayMove(comments, oldIndex, newIndex);

    move.map((comment, index) => {
      let newComment = {
        ...comment,
        int_order: index,
      };

      sampleAppService
        .editCommentOrder(newComment)
        .then(res => {
          ////console.log(newComment);
        })
        .catch(error => {
          //console.log(error);
        });
    });

    const newItems = move.map((comment, index) => {
      comment.int_order = index;
      return comment;
    });
    setComments(move);
    updateSampleComments(newItems);
  };
  const SortableItem = SortableElement(({ comment }) => (
    <div
      key={comment.uuid_comment + comment.uuid_degree}
      style={{ backgroundColor: comment.uuid_comment ? '#F49C2D' : '#95c511' }}
      className={tastingVoting.sortableDiv}
    >
      <span style={{ color: '#fff', marginRight: '5px' }}>
        {comment.uuid_degree
          ? comment.str_degree + ' - ' + comment.str_comment
          : comment.str_comment}
      </span>
      <Button
        onClick={() => handleRemoveComment(comment)}
        className={tastingVoting.sortableButton}
      >
        X
      </Button>
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className={tastingVoting.sortableListDiv}>
        {items.map((comment, index) => (
          <SortableItem
            //disabled
            key={comment.uuid_comment + comment.uuid_degree}
            comment={comment}
            index={index}
          />
        ))}
      </div>
    );
  });

  const handleClosePdf = () => {
    setOpen(false);
  };

  const handlePDF = () => {
    setOpen(true);
  };

  const handleApproveReject = () => {
    setIsApprove(!isApprove);
    updateApproveSample(!isApprove);

    const saveApproveRejectObj = {
      uuid_round: taste.uuid_round,
      uuid_sample: sample.uuid_sample,
      bol_user_approve_reject: !isApprove ? 1 : 0,
    };

    sampleAppService
      .updateApproveReject(saveApproveRejectObj)
      .then(res => { })
      .catch(error => {
        console.log(error);
      });
  };

  const handleBack = () => {
    if (currentReqs > 0 || loading || loadingRanks) return;
    if (location.state !== undefined && location.state?.from === 'discussion') {
      history.replace({ pathname: '/' });
    } else {
      history.goBack();
    }
  };

  return (
    <Fragment>
      <NotificationBar
        ref={nottificationRef}
        right="5px"
        notificationArr={notificationArr}
        setNotificationArr={row => {
          setNotificationArr([...row]);
        }}
      />
      {/* HEADER */}
      <CardRow transparent>
        <CardColumn
          className={tastingVoting.header}
          style={{ justifyContent: 'flex-start' }}
        >
          <Button
            label={''}
            onClick={() => handleBack()}
            className={tastingVoting.back}
          >
            <ArrowLeft />
          </Button>
        </CardColumn>
        <CardColumn>
          <Label
            bold
            label={`${sample?.int_order_app + 1} - ${sample?.str_name}`}
            style={{
              borderRadius: '5px',
              backgroundColor: '#fff',
              fontWeight: 'bold',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </CardColumn>
        <CardColumn>
          {imgPdf === 'pdf' ? (
            <PdfViewerModal
              label={t('tasting_beer_profile')}
              data={sample?.str_img_profile}
              onClick={handlePDF}
              closeForm={handleClosePdf}
              open={open}
              loading={false}
              downloadFileName={sample?.str_img_profile}
            />
          ) : (
            <>
              <Button
                label={t('tasting_beer_profile')}
                onClick={() => handleShowImage(showModal)}
                className={tastingVoting.buttonProfile}
              />
              <Modal
                fullButton={fullButton}
                fullHeight={fullHeight}
                show={showModal}
                className={showImg ? tastingVoting.buttonBackGround : ''}
                style={{ position: showImg ? 'fixed' : '' }}
              >
                <ImageZoom
                  className={showImg ? tastingVoting.showFull : ''}
                  srcImage={sample?.str_img_profile}
                  onClick={() => {
                    handleShowImage(showModal);
                  }}
                  setFullButton={setFullButton}
                  setHeight={setHeight}
                  onShow={() => {
                    setShowImg(!showImg);
                  }}
                  onClose={() => {
                    setShowImg(false);
                  }}
                />
              </Modal>
            </>
          )}
        </CardColumn>
        <CardColumn>
          <Button
            label={t('tasting_finish_sample')}
            onClick={() => handleFinishSample()}
            className={tastingVoting.buttonFinish}
            disabled={currentReqs > 0 || loading || loadingRanks}
            loading={currentReqs > 0 || loading || loadingRanks}
          />
        </CardColumn>
      </CardRow>
      {/* HEADER */}
      {error && (
        <div className={tastingVoting.errorContainer}>
          <h1>{t('error_loading_data')}</h1>
        </div>
      )}
      {!error && (
        <>
          {/* SCORE */}
          <CardRow>
            <CardColumn>
              <Checkbox
                label={`N/A`}
                name="bol_key_taster"
                value={score !== '' && score !== null ? false : true}
                onChange={() => {
                  score !== '' ? setScore('') : setScore('1');
                }}
                padding="8px 10px 8px 30px"
              />
            </CardColumn>
            <CardColumn>
              <Label bold label={t('common_score')} />
            </CardColumn>
            <CardColumn flex={10}>
              {round && (
                <Slider
                  min={round?.obj_score.min}
                  max={round?.obj_score.max}
                  step={round?.obj_score.step}
                  datalist
                  onClick={handleScoreChange}
                  currentScore={score ? Number(score) : null}
                ></Slider>
              )}
            </CardColumn>
            <CardColumn>
              <TextField
                name="score"
                value={temporaryScore}
                maxLength={round?.obj_score.step === 0.5 ? 3 : 4}
                onChange={e => {
                  setTemporaryScore(e.target.value)
                  delayedQuery({
                    score: e.target.value,
                    step: round?.obj_score.step,
                    min: round?.obj_score.min,
                    max: round?.obj_score.max,
                  })
                }}
              />
            </CardColumn>
          </CardRow>
          {/* SCORE */}

          {isRoundApprove ? (
            <CardRow style={{ marginTop: '10px' }}>
              <CardColumn flex={1}>
                <Label bold label={t('common_approve')} />
              </CardColumn>
              <CardColumn flex={10}>
                <Switch
                  onChange={handleApproveReject}
                  checked={isApprove}
                  offColor={'#ba182c'}
                  onColor={'#95C511'}
                />
              </CardColumn>
            </CardRow>
          ) : (
            <></>
          )}

          {/* SCORE SCALE*/}
          <CardRow style={{ marginTop: '10px' }}>
            <CardColumn>
              {round &&
                sample &&
                (round.uuid_round_type !==
                  'b8082a00-e1d1-11ec-97a3-000000000000' ? (
                  <ScoreScale
                    roundType={round?.uuid_round_type}
                    productType={sample?.uuid_product_type}
                  ></ScoreScale>
                ) : (
                  <Accordion label={t('common_score_scale')} loading={false}>
                    <FreshnessFiveScoreScale
                      uuid_brand={sample.uuid_brand}
                      isAdminEdit={false}
                    />
                  </Accordion>
                ))}
            </CardColumn>
          </CardRow>
          {/* SCORE SCALE*/}

          {/*COMMENTS*/}
          <CardRow style={{ marginTop: '10px' }}>
            <CardColumn>
              <Label label={t('tasting_comments')} bold></Label>
              {loadingComments ? (
                <Spinner />
              ) : (
                <div className={tastingVoting.commentsDiv}>
                  <SortableList
                    items={comments}
                    onSortEnd={onSortEnd}
                    axis="yx"
                  />
                  <div className={tastingVoting.commentDiv}>
                    {comment ? (
                      <Button
                        className={tastingVoting.commentButton}
                        transparent
                      >
                        <p>{comment.str_degree + ' - '}</p>
                        <AutoComplete
                          refFocus={inputText}
                          autoFocus={isContinuous}
                          freeComment
                          nonSearch
                          name="comments"
                          margin="0px"
                          style={{
                            margin: '0px',
                            padding: '5px 10px',
                            fontSize: '1em',
                          }}
                          styleList={{
                            marginTop: '0px',
                          }}
                          withouBorder
                          placeholder={t('common_attributes')}
                          optionKeys={['str_name']}
                          onSearch={handleSearchComments}
                          onSelect={handleSelectComments}
                          minimalToSearch={isZhOrKr ? 1 : 3}
                          rowComponent={
                            "<div style='display:flex; flex-direction: column; cursor: pointer;'> " +
                            "<div style='display:flex; flex-direction: row;'> " +
                            "<label style='cursor: pointer;'>${str_name}</label> " +
                            '</div> ' +
                            '</div>'
                          }
                        />
                        <X
                          style={{ marginLeft: '5px' }}
                          width="15px"
                          height="15px"
                          strokeWidth="5px"
                          onClick={() => handleRemoveComment(comment)}
                        ></X>
                      </Button>
                    ) : (
                      <AutoComplete
                        style={{ display: 'flex' }}
                        refFocus={inputText}
                        autoFocus={isContinuous}
                        freeComment
                        nonSearch
                        name="comments"
                        margin="0px"
                        padding="15px 0px"
                        withouBorder
                        placeholder={
                          (comment?.str_degree
                            ? comment.str_degree
                            : t('common_intensity')) +
                          ' - ' +
                          t('common_attributes')
                        }
                        optionKeys={['str_name']}
                        onSearch={handleSearchComments}
                        onSelect={handleSelectComments}
                        minimalToSearch={isZhOrKr ? 1 : 3}
                        rowComponent={
                          "<div style='display:flex; flex-direction: column; cursor: pointer;'> " +
                          "<div style='display:flex; flex-direction: row;'> " +
                          "<label style='cursor: pointer;'>${str_name}</label> " +
                          '</div> ' +
                          '</div>'
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </CardColumn>

            {!loadingComments && (
              <CardColumn flex={0}>
                <Label
                  style={{ whiteSpace: 'nowrap' }}
                  label={t('continuous_typing')}
                  bold
                ></Label>
                <view
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 10,
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isContinuous}
                    onChange={() => {
                      handleTypingChange();
                    }}
                    className={tastingVoting.checke}
                  />
                  <HelpCircle
                    data-tip={t('continuous_typing_tips')}
                    style={{ color: '#B11F24', marginLeft: 5 }}
                    size="20"
                    data-for="continuous_typing_tips"
                  />
                  <ReactTooltip
                    id="continuous_typing_tips"
                    effect="solid"
                    place="bottom"
                    type="warning"
                    style={{ whiteSpace: 'normal' }}
                  />
                </view>
              </CardColumn>
            )}
          </CardRow>
          {/*COMMENTS*/}

          {/*DEGREES*/}
          <CardRow>
            <CardColumn>
              <Label label={t('common_intensity')} bold></Label>
            </CardColumn>
          </CardRow>
          <CardRow>
            <CardColumn>
              <ButtonList
                style={{ width: '120px' }}
                zIndex={0}
                list={degrees}
                valueField="str_name"
                labelField="str_name"
                onClick={e => handleClickDegrees(e)}
                comment={comment?.str_degree}
              ></ButtonList>
            </CardColumn>
          </CardRow>

          {/*DEGREES*/}

          {/*ATTRS*/}
          <CardRow>
            <CardColumn>
              <Label label={t('common_attributes')} bold></Label>
            </CardColumn>
          </CardRow>
          <CardRow>
            <CardColumn>
              <AttributesCategory
                list={attrs}
                valueField="str_name"
                labelField="str_name"
                onClick={handleClickAttrs}
                categories={categories}
                freshness={
                  round?.uuid_round_type ===
                    '43b11c76-043c-11eb-b18d-026fdc1cc8f2'
                    ? true
                    : false
                }
              ></AttributesCategory>
            </CardColumn>
          </CardRow>
        </>
      )}
      {/*ATTRS*/}
    </Fragment>
  );
};

export default TastingVoting;
