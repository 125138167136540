export function timeRemaining(finish) {
  const date = finish.substr(-1) === 'Z' ? finish : finish + ':00.000Z';
  return (
    (new Date(date).getTime() -
      Date.UTC(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )) /
    1000
  );
}
