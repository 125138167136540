import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import imageStyle from './imageZoom.module.scss';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { X, Minus, Plus, Maximize } from 'react-feather';
import { CardImage } from '../';
const ImageZoom = props => {
  const [showPic, setShowPic] = useState(false);
  let showClose =
    typeof props.showClose !== 'undefined' ? props.showClose : true;
  const buttonZoomIn = {
    float: 'left',
    marginRight: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const buttonZoomOut = {
    float: 'left',
    marginRight: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const buttonClose = {
    float: 'right',
    display: showClose ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const handleClick = () => {
    props.onClick();
    props.onClose();
    setShowPic(false);
    props.setFullButton('70vw');
    props.setHeight('');
  };
  const handleFullScreen = () => {
    props.onShow();
    setShowPic(!showPic);
    if (showPic) {
      props.setFullButton('70vw');
      props.setHeight('');
    } else {
      props.setFullButton('96vw');
      props.setHeight('97vh');
    }
  };
  return (
    <div className={showPic ? imageStyle.showPic : null}>
      <TransformWrapper defaultScale={1}>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className={imageStyle.tools}>
              <button
                style={buttonClose}
                className={imageStyle.close}
                onClick={() => {
                  handleClick();
                }}
              >
                <X />
              </button>
              <button
                style={buttonZoomOut}
                className={imageStyle.zoomOut}
                onClick={zoomOut}
              >
                <Minus />
              </button>
              <button
                style={buttonZoomIn}
                className={imageStyle.zoomIn}
                onClick={zoomIn}
              >
                <Plus />
              </button>

              <button
                className={imageStyle.handleFullScreen}
                onClick={() => {
                  handleFullScreen();
                }}
              >
                <Maximize />
              </button>
            </div>
            <div className={imageStyle.imageContent}>
              {' '}
              <TransformComponent>
                <CardImage
                  styleImg={{
                    height: '100%',
                    maxWidth: '90vw',
                    maxHeight: '80vh',
                  }}
                  styleFather={{
                    height: '100%',
                  }}
                  removeStyle={true}
                  src={props.srcImage}
                  disableDownload
                />
              </TransformComponent>
            </div>
          </React.Fragment>
        )}
      </TransformWrapper>
    </div>
  );
};

ImageZoom.propTypes = {
  onClick: PropTypes.func,
  srcImage: PropTypes.string,
  showClose: PropTypes.bool,
};

export default ImageZoom;

