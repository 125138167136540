import React, { useEffect, useState } from 'react';
import CountDownStyle from './CountDown.module.scss';
import { timeRemaining } from '../../utils/timeRemaining';
import funnel from '../../assets/funnel.svg';

export default function CountDown(props) {
  const [countdown, setCountdown] = useState({});
  let interval;

  useEffect(() => {
    if (props.round.bol_is_countdown) {
      interval = setInterval(() => {
        Countdown(
          props.round.dat_finish,
        );
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  const Countdown = finish => {
    let num = timeRemaining(finish);
    if (num < 0) {
      clearInterval(interval);
      interval = null;
      if (
        props.round.uuid_round_type === '0d55fca0-e7a2-11ec-a8bc-000000000000'
      ) {
        if (!sessionStorage.getItem('bol_finished')) {
          props.save && props.save();
        }
      } else {
        props.save && props.save();
      }
      props.setshow && props.setshow(true);
      props.setRound && props.setRound(props.round)
      return setCountdown({ H: '0', M: '0', S: '0' });
    } else {
      const H = Math.floor(num / 60 / 60);
      const M = Math.floor(num / 60 - H * 60);
      const S = Math.floor(num - H * 60 * 60 - M * 60);
      return setCountdown({ H, M, S });
    }
  };

  return (
    <div className={CountDownStyle.HMS}>
      <img
        src={funnel}
        className={
          CountDownStyle.funnel +
          ((countdown.H === '0' || countdown.H === '00') && (countdown.M === '0' || countdown.M === '00') && (countdown.S === '0' || countdown.S === '00')
            ? ' ' + CountDownStyle.endAnimation
            : ' ' + CountDownStyle.animation)
        }
      />
      <p className={CountDownStyle.countdown}>
        {countdown.H === undefined ? '0' : countdown.H}
        <span className={CountDownStyle.font}>H</span>
        {countdown.M < 10 ? '0' + countdown.M : (countdown.M === undefined ? '0' : countdown.M)}
        <span className={CountDownStyle.font}>M</span>
        {countdown.S < 10 ? '0' + countdown.S : (countdown.S === undefined ? '0' : countdown.S)}
        <span className={CountDownStyle.font}>S</span>
      </p>
    </div>
  );
}
