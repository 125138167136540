import React from 'react';
import editRoundSamplesSampleStyle from './editroundsamplessample.module.scss';
import PropTypes from 'prop-types';
import {
  CardRow,
  Button,
  TextField,
  DropdownField,
  Checkbox,
  CardColumn,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { HelpCircle } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import swap from '../../../assets/swapIcon.png';

const EditRoundSamplesSample = props => {
  const { t } = useTranslation();
  const showFreshnessFields =
    props.round.uuid_round_type === '43b11c76-043c-11eb-b18d-026fdc1cc8f2' ||
    props.round.uuid_round_type === 'b8082a00-e1d1-11ec-97a3-000000000000';
  const handleSampleName = () => {
    const yearDatProd = props.sample.dat_production?.substring(0, 4);
    const monthDatProd = t(
      `month_${props.sample.dat_production?.substring(5, 7) - 1}`,
    );
    const dayDatProd = props.sample.dat_production?.substring(8, 10);

    const yearDatExp = props.sample.dat_expire?.substring(0, 4);
    const monthDatExp = t(
      `month_${props.sample.dat_expire?.substring(5, 7) - 1}`,
    );
    const datDatExp = props.sample.dat_expire?.substring(8, 10);

    return `${props.sample.str_site_name} - Pr. ${
      props.sample.dat_production
        ? dayDatProd + '/' + monthDatProd + '/' + yearDatProd
        : 'N/A'
    } - Ex. ${
      props.sample.dat_expire
        ? datDatExp + '/' + monthDatExp + '/' + yearDatExp
        : 'N/A'
    } - ${props.sample.str_batch ? props.sample.str_batch : 'N/A'}`;
  };

  const handleChangeStorage = params => {
    if (props.splitRound) {
      props.onChangeSplitRow(params.target.name, params.target.value);
      return;
    }
    props.sample.uuid_storage_time = params.target.value;
    props.setRound(props.round);
  };

  const handleChangeTemperature = params => {
    if (props.splitRound) {
      props.onChangeSplitRow(params.target.name, params.target.value);
      return;
    }
    props.sample.uuid_temperature = params.target.value;
    props.setRound(props.round);
  };

  const handleChangeCountAsKPI = countAsKpi => {
    props.sample.bol_count_as_kpi = countAsKpi;
    props.setRound(props.round);
  };

  const handleSwapSample = swapSample => {
    props.showSampleList();
  };

  return (
    <div className={editRoundSamplesSampleStyle.draggableCardRow}>
      {!props.hideOder && (
        <div className={editRoundSamplesSampleStyle.sampleCardNumber}>
          {props.sample.int_order_app + 1}
        </div>
      )}
      <div className={editRoundSamplesSampleStyle.sampleCard}>
        <CardRow flexWrap="wrap" transparent flex={1}>
          {props.sample?.bol_kpi != null &&
          props.round.uuid_round_type ==
            '4ed984d9-021d-4d06-a992-991a24b3d7ce' &&
          !!props.round.uuid_kpi_codes ? (
            <CardColumn style={{ flex: 'initial' }}>
              <CardRow>
                <HelpCircle
                  data-tip={t(`samples_uncheck_kpi_sample_tooltip`)}
                  style={{ color: '#B11F24', marginTop: '9px' }}
                  size="20"
                />
                <ReactTooltip
                  effect="solid"
                  place="top"
                  type="warning"
                  style={{ whiteSpace: 'normal' }}
                />
                <Checkbox
                  style={{ alignSelf: 'end', paddingBottom: '5px' }}
                  label={t('kpi_count_as_kpi_sample')}
                  name="bol_count_as_kpi"
                  padding="8px 10px 8px 30px"
                  value={props.sample.bol_count_as_kpi}
                  disabled={props.sample.isCountKPIControl}
                  onChange={evt => {
                    handleChangeCountAsKPI(evt.target.value);
                  }}
                />
              </CardRow>
            </CardColumn>
          ) : props.round.uuid_round_type ==
              '4ed984d9-021d-4d06-a992-991a24b3d7ce' &&
            !!props.round.uuid_kpi_codes ? (
            <div style={{ width: '152px' }}></div>
          ) : (
            <></>
          )}
          <TextField
            name="str_brand_name"
            label={t('common_brand')}
            margin="0px 10px 0px 0px"
            value={props.sample.str_brand_name}
            disabled
            labelStyle={{
              whiteSpace: 'nowrap',
            }}
          />
          {props.sample.str_type === 'PRODUCTION' ? (
            <TextField
              name="dat_kpi"
              label={t('common_kpi_month')}
              margin="0px 10px 0px 0px"
              value={
                props.sample.dat_kpi
                  ? `${t(
                      `month_${props.sample.dat_kpi?.substring(5, 7) - 1}`,
                    )}/${props.sample.dat_kpi?.substring(0, 4)}`
                  : 'Not for KPI'
              }
              labelStyle={{
                whiteSpace: 'nowrap',
              }}
              disabled
            />
          ) : null}
          {props.sample.str_type === 'PROJECT' ? (
            <TextField
              name="str_trial_identifier"
              label={t('sample_trial_identifier')}
              margin="0px 10px 0px 0px"
              value={props.sample.str_trial_identifier}
              disabled
              labelStyle={{
                whiteSpace: 'nowrap',
              }}
            />
          ) : null}
          <TextField
            name="uuid_sample"
            label={t('common_sample')}
            margin="0px 10px 0px 0px"
            flex={6}
            value={handleSampleName()}
            disabled
            style={{ minWidth: '360px' }}
            labelStyle={{
              whiteSpace: 'nowrap',
            }}
          />
          {props.comments != undefined && (
            <TextField
              name={props.commentsName}
              label={t('triangle_comment')}
              margin="0px 10px 0px 0px"
              flex={2}
              disabled={!props.sample.uuid_sample}
              value={props.comments}
              style={{ minWidth: '360px' }}
              onChange={props.setComments}
              labelStyle={{
                whiteSpace: 'nowrap',
              }}
            />
          )}
          {showFreshnessFields && (
            <>
              <DropdownField
                bolOrder={false}
                options={props.temperatures ? props.temperatures : []}
                valueField="uuid_temperature"
                labelField="val_temperature"
                flex={2}
                label={t('common_temperature')}
                margin="0px 10px 10px 0px"
                value={props.sample.uuid_temperature}
                onChange={handleChangeTemperature}
                name={'uuid_temperature'}
                labelStyle={{
                  whiteSpace: 'nowrap',
                }}
              />
              <DropdownField
                options={props.storage_times ? props.storage_times : []}
                bolOrder={false}
                valueField="uuid_storage_time"
                labelField="val_time"
                flex={2}
                label={t('common_storage_times')}
                margin="0px 10px 10px 0px"
                value={props.sample.uuid_storage_time}
                onChange={handleChangeStorage}
                name={'uuid_storage_time'}
                labelStyle={{
                  whiteSpace: 'nowrap',
                }}
              />
            </>
          )}
        </CardRow>
        {(props.state?.length === 4 || props.state?.length === 5) &&
          (props.round.uuid_round_type ===
            '4ed984d9-021d-4d06-a992-991a24b3d7ce' ||
            props.round.uuid_round_type ===
              'b8082a00-e1d1-11ec-97a3-000000000000') && (
            <img
              src={swap}
              alt={''}
              height={30}
              width={30}
              style={{
                marginLeft: '-4px',
                cursor: 'pointer',
              }}
              onClick={handleSwapSample}
            />
          )}
        {!props.hideDelButton && (
          <Button
            disabled={
              typeof props.state === 'object' &&
              props.state.length >= 4 &&
              props.state[3] === 'IN_PROGRESS'
            }
            round
            remove
            label="x"
            margin="0px 10px 0px 5px"
            className={editRoundSamplesSampleStyle.removeButton}
            onClick={evt => {
              props.onRemove(props.sample, props.itemIndex);
            }}
          />
        )}
      </div>
    </div>
  );
};

EditRoundSamplesSample.propTypes = {
  round: PropTypes.any,
  sample: PropTypes.any,
  itemIndex: PropTypes.any,
  temperatures: PropTypes.any,
  storage_times: PropTypes.any,
  onRemove: PropTypes.func,
  setRound: PropTypes.func,
};

export default EditRoundSamplesSample;
