import MainService from './main.service';
import _ from 'lodash';

export default class UserService {
  mainService = null;
  path = null;

  constructor() {
    this.mainService = new MainService();
    this.path = '/v1/admin';
  }

  listRoundTypes = () => {
    return this.mainService.get(this.path + '/list_types');
  };

  listRoundCategoriesSamples = uuid_round => {
    return this.mainService.get(
      this.path + '/list_categories/' + uuid_round + '/samples',
    );
  };

  listRounds = () => {
    return this.mainService.get(this.path + '/list_rounds');
  };

  listRoundsReport = body => {
    return this.mainService.post(this.path + '/list_rounds/reports', body);
  };

  listRoundsProductReport = body => {
    return this.mainService.post(
      this.path + '/list_rounds/product_site/reports',
      body,
    );
  };

  addFlavorCard = body => {
    return this.mainService.post(
      this.path + '/round/flavors_identification',
      body,
    );
  };

  deleteFlavorCard = identification => {
    return this.mainService.delete(
      this.path + '/round/flavors_identification/' + identification,
    );
  };

  listRoundsByServer = body => {
    if (Object.keys(body.fields).length > 0) {
      if (body.fields.str_status !== null) {
        let search = body.fields.str_status;
        let results = _.filter(this.listStatusRound(), function (item) {
          return (
            item.str_status_code.toLowerCase().indexOf(search.toLowerCase()) >
            -1
          );
        });
        body.fields.str_status =
          results[0] !== undefined
            ? results[0].str_status_code
            : body.fields.str_status;
      }
    }

    return new Promise((resolve, reject) => {
      this.mainService.post(this.path + '/list_rounds/pagination', body).then(
        res => {
          typeof res.data === 'object' &&
            res.data.forEach(roundTemp => {
              roundTemp.bol_template =
                roundTemp.bol_template === 1 ? 'Yes' : 'No';
              if (roundTemp.arr_status) {
                roundTemp.str_status = this.findRoundStatus(
                  roundTemp.arr_status[roundTemp.arr_status.length - 1],
                );
              }
            });

          const respo = res;

          resolve(respo);
        },
        error => {
          reject(error);
        },
      );
    });
  };

  listRoundSamples = uuid_round => {
    return this.mainService.get(
      this.path + '/list_rounds/' + uuid_round + '/samples',
    );
  };

  listRoundUsers = uuid_round => {
    return this.mainService.get(
      this.path + '/list_rounds/' + uuid_round + '/users',
    );
  };

  updateRound = obj_round => {
    return this.mainService.post(this.path + '/round', obj_round);
  };

  setUserAsKeyTaster = (uuid_round, uuid_user) => {
    return this.mainService.post(`${this.path}/round/add/key_taster`, {
      uuid_round,
      uuid_user,
    });
  };

  unsetUserAsKeyTaster = (uuid_round, uuid_user) => {
    return this.mainService.post(`${this.path}/round/remove/key_taster`, {
      uuid_round,
      uuid_user,
    });
  };

  duplicateRound = obj_round => {
    return this.mainService.post(
      this.path + '/round/category_samples/duplicate',
      obj_round,
    );
  };

  updateRoundUsers = obj_round => {
    return this.mainService.post(this.path + '/round/users', obj_round);
  };

  updateRoundSamples = obj_round => {
    return this.mainService.post(this.path + '/round/samples', obj_round);
  };

  deleteRound = uuid_round => {
    return this.mainService.delete(this.path + '/round/' + uuid_round);
  };

  closeRound = round => {
    return this.mainService.post(`/v1/app/finish`, round);
  };

  reOpenRound = round => {
    return this.mainService.post(`/v1/app/reopen_round`, round);
  };

  listRoundStatus = () => {
    return [
      { str_status_code: 'SCHEDULED', str_name: 'round_status_scheduled' },
      { str_status_code: 'TASTERS', str_name: 'round_status_tasters' },
      { str_status_code: 'SAMPLES', str_name: 'round_status_samples' },
      { str_status_code: 'IN_PROGRESS', str_name: 'round_status_in_progress' },
      { str_status_code: 'FINISHED', str_name: 'round_status_finished' },
      { str_status_code: 'CLOSED', str_name: 'round_status_closed' },
    ];
  };

  findRoundStatus = str_status_code => {
    switch (str_status_code) {
      case 'SAMPLES':
        return 'round_status_samples';
      case 'SCHEDULED':
        return 'round_status_scheduled';
      case 'TASTERS':
        return 'round_status_tasters';
      case 'IN_PROGRESS':
        return 'round_status_in_progress';
      case 'FINISHED':
        return 'round_status_finished';
      case 'CLOSED':
        return 'round_status_closed';
      default:
        return 'round_status_created';
    }
  };

  findStatusRound = str_status => {
    switch (str_status) {
      case 'round_status_samples':
        return 'SAMPLES';
      case 'round_status_scheduled':
        return 'SCHEDULED';
      case 'round_status_tasters':
        return 'TASTERS';
      case 'round_status_in_progress':
        return 'IN_PROGRESS';
      case 'round_status_finished':
        return 'FINISHED';
      case 'round_status_closed':
        return 'CLOSED';
      default:
        return 'NOT SCHEDULED';
    }
  };

  listStatusRound = () => {
    return [
      {
        str_status_code: 'SCHEDULED',
        str_name: 'round_status_scheduled',
      },
      { str_status_code: 'TASTERS', str_name: 'round_status_tasters' },
      { str_status_code: 'SAMPLES', str_name: 'round_status_samples' },
      {
        str_status_code: 'IN_PROGRESS',
        str_name: 'round_status_in_progress',
      },
      {
        str_status_code: 'FINISHED',
        str_name: 'round_status_finished',
      },
      { str_status_code: 'CLOSED', str_name: 'round_status_closed' },
      {
        str_status_code: 'NOT SCHEDULED',
        str_name: 'round_status_created',
      },
    ];
  };

  listCategories = () => {
    return this.mainService.get(`${this.path}/categories`);
  };

  updateCategory = category => {
    return this.mainService.post(`${this.path}/category`, category);
  };

  deleteCategory = uuid_category => {
    return this.mainService.delete(`${this.path}/category/${uuid_category}`);
  };

  listComments = () => {
    return this.mainService.get(`${this.path}/comments`);
  };

  updateComment = comment => {
    return this.mainService.post(`${this.path}/comment`, comment);
  };

  deleteComment = uuid_comment => {
    return this.mainService.delete(`${this.path}/comment/${uuid_comment}`);
  };

  listDegrees = () => {
    return this.mainService.get(`${this.path}/degrees`);
  };

  updateDegree = degree => {
    return this.mainService.post(`${this.path}/degree`, degree);
  };

  deleteDegree = uuid_degree => {
    return this.mainService.delete(`${this.path}/degree/${uuid_degree}`);
  };

  blockRoundResults = round => {
    return this.mainService.post(`${this.path}/block_round_results`, round);
  };

  splitSamples = data => {
    return this.mainService.post(`${this.path}/round/split_samples`, data);
  };

  updateRoundName = data => {
    return this.mainService.post(`${this.path}/round/update_round_name`, data);
  };

  restoreRound = data => {
    return this.mainService.post(`${this.path}/round/restore_round`, data);
  };

  getSamplesSplit = uuid_round => {
    return this.mainService.get(
      `${this.path}/list_rounds/${uuid_round}/sample_split`,
    );
  };

  swapSample = data => {
    return this.mainService.post(`${this.path}/round/swap_sample`, data);
  };
  getRoundListFlavors = data => {
    return this.mainService.post(`${this.path}/round/round_list_flavors`, data);
  };

  updateListFlavors = data => {
    return this.mainService.post(
      `${this.path}/round/update_list_flavors
    `,
      data,
    );
  };

  updateAllFlavors = data => {
    return this.mainService.post(
      `${this.path}/round/update_all_flavors_identification
    `,
      data,
    );
  };

  saveProcessCheck = data => {
    return this.mainService.post(`${this.path}/process_check`, data);
  };
  getProcessCheckPagination = data => {
    return this.mainService.post(
      `${this.path}/list_process_check/pagination`,
      data,
    );
  };
  delProcessCheck = uuid_round => {
    return this.mainService.delete(`${this.path}/process_check/${uuid_round}`);
  };

  getSuspensionPagination = data => {
    return this.mainService.post(
      `${this.path}/list_production_suspension/pagination`,
      data,
    );
  };

  saveSuspension = data => {
    return this.mainService.post(`${this.path}/production_suspension`, data);
  };

  delSuspension = uuid_production_suspension => {
    return this.mainService.delete(
      `${this.path}/production_suspension/${uuid_production_suspension}`,
    );
  };

  uploadSuspension = (uuid_production_suspension, str_file) => {
    return this.mainService.post(
      `${this.path}/production_suspension/upload_file`,
      {
        uuid_production_suspension,
        str_file,
      },
    );
  };

  getShippingSites = () => {
    return this.mainService.get(`${this.path}/list_sent_site`);
  };

  getProcessCheckDetail = uuid_round => {
    return this.mainService.get(`${this.path}/get_process_check/${uuid_round}`);
  };
}
