import React, { useEffect, useState } from 'react';
import EditRoundFlavorStyle from './EditRoundFlavor.module.scss';
import ThresholdService from '../../services/threshold/threshold.service';
import RoundAppService from '../../services/rounds/roundApp.service';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from '../../../components';
import { RotateCw, Shuffle } from 'react-feather';

const Preview = props => {
  const thresholdService = new ThresholdService();
  const roundAppService = new RoundAppService();
  const { t } = useTranslation();
  const [Flavors, setFlavors] = useState([]);
  const [active, setActive] = useState(1);
  const [resList, setResList] = useState([]);
  const [flavorsList, setFlavorsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [shuffleLoading, setShuffleLoading] = useState(false);

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    thresholdService
      .listFlavors({ uuid_round_type: props.round.uuid_round_type })
      .then(res => {
        if (res.type === 'success' && res.results) {
        } else {
          window.alert(res.message);
        }
      });

    roundAppService
      .getFlavorPreviewHeaders(props.round.uuid_round)
      .then(res => {
        if (res.type === 'success' && res.results) {
          setFlavors(res.results);
        } else {
          window.alert(res.message);
        }
      });

    roundAppService.getFlavorPreview(props.round.uuid_round).then(res => {
      if (res.type === 'success' && res.results) {
        setResList(res.results);
        const arr = res.results.filter((arr, index) => {
          return index >= 0 && index <= 7;
        });
        setFlavorsList(arr);
        setLoading(false);
      } else {
        window.alert(res.message);
      }
    });
    const arr = resList.filter((arr, index) => {
      return index >= 0 && index <= 7;
    });
    setFlavorsList(arr);
  };

  const pagnum = () => {
    var num = [];
    for (let i = 1; i <= Math.ceil(resList.length / 8); i++) {
      num = [...num, i];
    }
    return num;
  };

  const pagavtive = (n, next) => {
    if (n === 0 && active < pagnum().length) {
      setActive(pagnum().length);
      const arr = resList.filter((arr, index) => {
        return (
          index >= pagnum().length * 8 - 8 && index <= pagnum().length * 8 - 1
        );
      });
      setFlavorsList(arr);
    } else if (next && active < pagnum().length) {
      setActive(active + 1);
      const arr = resList.filter((arr, index) => {
        return index >= (active + 1) * 8 - 8 && index <= (active + 1) * 8 - 1;
      });
      setFlavorsList(arr);
    } else if (n > 0 && n <= pagnum().length) {
      setActive(n);
      const arr = resList.filter((arr, index) => {
        return index >= n * 8 - 8 && index <= n * 8 - 1;
      });
      setFlavorsList(arr);
    }
  };
  const handleChangeShuffle = () => {
    setShuffleLoading(true);
    setLoading(true);
    thresholdService
      .updateFlavorRandom({ uuid_round: props.round.uuid_round, is_random: 1 })
      .then(res => {
        if (res.type === 'success' && res.results) {
          setShuffleLoading(false);
          setActive(1);
          refreshState();
        } else {
          window.alert(res.message);
          setShuffleLoading(false);
          setLoading(false);
        }
      });
  };
  const handleChangeReset = () => {
    setResetLoading(true);
    setLoading(true);
    thresholdService
      .updateFlavorRandom({ uuid_round: props.round.uuid_round, is_random: 0 })
      .then(res => {
        if (res.type === 'success' && res.results) {
          setResetLoading(false);
          setActive(1);
          refreshState();
        } else {
          window.alert(res.message);
          setResetLoading(false);
          setLoading(false);
        }
      });
  };

  return (
    <div className={EditRoundFlavorStyle.Preview}>
      <div className={EditRoundFlavorStyle.menuButton}>
        <Button
          onClick={() => handleChangeReset()}
          disabled={
            (typeof props.round.arr_status === 'object' &&
              props.round.arr_status.length >= 4 &&
              props.round.arr_status[3] === 'IN_PROGRESS') ||
            shuffleLoading ||
            loading
          }
          loading={resetLoading}
          confirm
          margin="20px 60px 0px 0px"
          style={{
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {t('reset')}
          &ensp;
          <RotateCw size="14" />
        </Button>
        <Button
          onClick={() => handleChangeShuffle()}
          confirm
          margin="20px 0px 0px 0px"
          loading={shuffleLoading}
          disabled={
            (typeof props.round.arr_status === 'object' &&
              props.round.arr_status.length >= 4 &&
              props.round.arr_status[3] === 'IN_PROGRESS') ||
            resetLoading ||
            loading
          }
          blue
          style={{
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {t('round_randomize_samples_order_button')}
          &ensp;
          <Shuffle size="14" />
        </Button>
      </div>
      <div className={EditRoundFlavorStyle.centerPreview}>
        <button
          className={EditRoundFlavorStyle.off}
          onClick={() => props.setPreviewsShow(false)}
        >
          ×
        </button>
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <ul
              className={
                Flavors.length === 1
                  ? EditRoundFlavorStyle.hearder2
                  : EditRoundFlavorStyle.hearder
              }
            >
              <li className={EditRoundFlavorStyle.Order}>
                {t('flavor_order')}
              </li>
              {typeof Flavors === 'object' &&
                Flavors.map((item, index1) => {
                  return (
                    <li key={index1} className={EditRoundFlavorStyle.li}>
                      <img
                        className={EditRoundFlavorStyle.img}
                        src={require('../../../assets/preview/beer' +
                          (index1 + 1) +
                          '.png')}
                        alt=""
                      />
                      &nbsp;{item.str_name_en}
                    </li>
                  );
                })}
            </ul>
            <div className={EditRoundFlavorStyle.hr} />
            {typeof flavorsList === 'object' &&
              flavorsList.map((item, index) => {
                return (
                  <ul
                    key={index}
                    className={
                      item.arr_identification.length === 1
                        ? EditRoundFlavorStyle.codecopy
                        : EditRoundFlavorStyle.code
                    }
                  >
                    <li className={EditRoundFlavorStyle.code1}>
                      {index + 1 + (active - 1) * 8 < 10
                        ? t('code') + ' 00' + (index + 1 + (active - 1) * 8)
                        : index + 1 + (active - 1) * 8 > 9 &&
                          index + 1 + (active - 1) * 8 < 100
                          ? t('code') + ' 0' + (index + 1 + (active - 1) * 8)
                          : t('code') + ' ' + (index + 1 + (active - 1) * 8)}
                    </li>
                    {item.arr_identification.map((item2, index2) => {
                      return (
                        <li
                          key={index2}
                          className={EditRoundFlavorStyle.codejiu}
                        >
                          {item2.number}
                        </li>
                      );
                    })}
                  </ul>
                );
              })}
            <ul className={EditRoundFlavorStyle.pagnum}>
              <li
                className={EditRoundFlavorStyle.lastNext}
                onClick={() => pagavtive(0)}
              >
                {t('last')}
              </li>
              {pagnum().map((num, index) => {
                if (num > active - 2 && num < active + 2) {
                  return (
                    <li
                      key={num}
                      className={
                        EditRoundFlavorStyle.pagnumpag +
                        (active === index + 1
                          ? ' ' + EditRoundFlavorStyle.active
                          : '')
                      }
                      onClick={() => pagavtive(num)}
                    >
                      {num}
                    </li>
                  );
                }
              })}
              <li
                className={EditRoundFlavorStyle.lastNext}
                onClick={() => pagavtive(pagnum().length + 1, 'next')}
              >
                {t('common_next')}
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
export default Preview;

