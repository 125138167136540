import React, { useRef, useState, useEffect, memo } from 'react';
import editRoundSamplesSampleStyle from './editRoundCategoriesSample.module.scss';
import PropTypes from 'prop-types';
import {
  Label,
  CardRow,
  Button,
  TextField,
  CardColumn,
  DatePickerField,
  DropdownField,
} from '../../../components';
import { X } from 'react-feather';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import SampleService from '../../services/samples/sample.service';
import RoundService from '../../services/rounds/round.service';
import _ from 'lodash';

const EditRoundCategoriesSampleSimple = props => {
  const inputText = useRef(null);
  const [sampleId, setSampleId] = useState('');
  const [collectionPoint, setCollectionPoint] = useState();
  const [ptsCode, setPtsCode] = useState();
  const [processItem, setProcessItem] = useState();
  const [brand, setBrand] = useState('');
  const [testType, setTestType] = useState('');
  const [collectionDate, setCollectionDate] = useState();
  const sampleService = new SampleService();
  const roundService = new RoundService();
  const [brewNumbers, setBrewNumbers] = useState([]);
  const [brewNumber, setBrewNumber] = useState('');
  const [supplier, setSupplier] = useState('');
  const CPT_EOC_Zero_Hour =
    props.sample.uuid_category_option ===
      '32892408-ee44-11ed-ba07-0017fa02c1e1' ||
    props.sample.uuid_category_option ===
      '36907a60-ee44-11ed-ba08-0017fa02c1e1' ||
    props.sample.uuid_category_option ===
      '3c2f932a-ee44-11ed-ba09-0017fa02c1e1';
  const dropDownOpts = [
    {
      valueField: '1',
      labelField: 'Yes',
    },
    {
      valueField: '0',
      labelField: 'No',
    },
  ];
  const [strNumberDaysOverAged, setStrNumberDaysOverAged] = useState('');
  const [strProductionLine, setStrProductionLine] = useState('');
  const [strPackagedBeerDataCode, setStrPackagedBeerDataCode] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    setBrewNumbers(
      props.sample.arr_brew_numbers?.length > 0
        ? props.sample.arr_brew_numbers
        : [],
    );
    setSampleId(props.sample.str_sample_id);
    setCollectionPoint(props.sample.str_collection_point);
    setSupplier(props.sample.str_supplier);
    setPtsCode(props.sample.str_pts_code);
    setProcessItem(props.sample.str_process_item);
    setStrNumberDaysOverAged(props.sample.str_number_days_over_aged);
    setStrProductionLine(props.sample.uuid_production_line);
    setStrPackagedBeerDataCode(props.sample.str_packaged_beer_data_code);

    if (props.sample.dt_collection) {
      const collectionAux = props.sample.dt_collection?.split('T');
      const collectionDateAux = collectionAux[0];
      setCollectionDate(`${collectionDateAux}`);
    }
  }, [props.sample]);

  const handleRemoveBrewNumber = brewNumber => {
    const newBrewNumbers = brewNumbers.filter(
      numberfilter =>
        !(numberfilter.uuid_brew_number === brewNumber.uuid_brew_number),
    );

    if (brewNumber.uuid_brew_number) {
      sampleService.deleteBrewNumber(brewNumber.uuid_brew_number).then(
        res => {
          if (res.type === 'success' && res.results) {
            if (newBrewNumbers.length === 0) {
              setBrewNumbers([]);
            } else {
              setBrewNumbers(newBrewNumbers);
            }
          }
        },
        error => {
          //console.log(error);
        },
      );
    }
  };

  const handleCollectionDate = evt => {
    const value = evt.target.value;
    if (value.length == 0) {
      setCollectionDate(null);
    } else {
      setCollectionDate(value);
    }
    props.sample['dt_collection'] = value;
    handleSampleSave();
  };

  const handleCollectionPoint = evt => {
    const value = evt.target.value;
    setCollectionPoint(value);
    props.sample['str_collection_point'] = value;
    handleSampleSave();
  };
  const handleSupplier = evt => {
    const value = evt.target.value;
    setSupplier(value);
    props.sample['str_supplier'] = value;
    handleSampleSave();
  };

  const handlePtsCode = evt => {
    const value = evt.target.value;
    setPtsCode(value);
    props.sample['str_pts_code'] = value;
    handleSampleSave();
  };

  const handleProcessItem = evt => {
    const value = evt.target.value;
    setProcessItem(value);
    props.sample['str_process_item'] = value;
    handleSampleSave();
  };

  const handleChangeBrand = evt => {
    const value = evt.target.value;
    setBrand(value);
    props.sample['uuid_brand'] = value;
    handleSampleSave();
  };

  const handleSampleId = evt => {
    const value = evt.target.value;
    setSampleId(value);
    props.sample['str_sample_id'] = value;
    handleSampleSave();
  };

  const handleChangetestType = evt => {
    const value = evt.target.value;
    setTestType(value);
    props.sample['uuid_routine_warehouse'] = value;
    handleSampleSave();
  };

  const handleChangeInput = evt => {
    const value = evt.target.value;
    const name = evt.target.name;
    setTestType(value);
    if (name === 'str_number_days_over_aged') {
      setStrNumberDaysOverAged(value);
    }
    if (name === 'bol_over_aged_beer' && value === '0') {
      props.sample.str_number_days_over_aged = '';
    }
    if (name === 'uuid_production_line') {
      setStrProductionLine(value);
    }
    if (name === 'str_packaged_beer_data_code') {
      setStrPackagedBeerDataCode(value);
    }

    props.sample[evt.target.name] = value;
    handleSampleSave();
  };

  const handleSampleSave = () => {
    props.round.arr_samples = props.round.arr_samples.map(sampleTemp => {
      if (sampleTemp.uuid_sample === props.sample.uuid_sample) {
        return props.sample;
      }
      return sampleTemp;
    });
    let roundTemp = props.round;

    let samplesTemp = [];
    if (roundTemp.uuid_round_type === 'be229b18-2eb4-11eb-ba1d-0229531a6f14') {
      // SET SAMPLES OK/NOK
      for (let i = 0; i < roundTemp.arr_samples.length; i++) {
        const sample = roundTemp.arr_samples[i];
        if (
          (sample.dat_del === null || typeof sample.dat_del === 'undefined') &&
          sample.uuid_category_option !== null &&
          sample.uuid_category_option !== '' &&
          sample.uuid_category_option !== undefined
        ) {
          samplesTemp.push(sample);
        }
      }
    }

    roundService
      .updateRoundSamples({
        uuid_round: roundTemp.uuid_round,
        arr_samples: samplesTemp,
      })
      .then(
        res => {
          if (res.type === 'success' && res.results) {
            //Maybe add a notification message to inform the user that rounds samples was saved.
          } else {
            window.alert(res.message);
          }
        },
        error => {
          //console.log(error);
        },
      );
  };

  const handleSelectBrewNumber = (value, isBlurEvent) => {
    const uuid_category_option = props.sample.uuid_category_option;
    const uuid_category = props.sample.uuid_category;
    const uuid_round = props.round.uuid_round;
    const int_order_app = props.round.int_order_app;
    const uuid_sample = props.sample.uuid_sample;

    if (value) {
      if (brewNumber) {
        let hasNumber = brewNumbers.find(
          numberFilter =>
            numberFilter.str_descripition.toLowerCase() ===
            brewNumber.toLowerCase(),
        );
        if (!!!hasNumber) {
          let uuid_brew_number = uuid();
          let newBrew = {
            str_descripition: brewNumber,
            uuid_brew_number,
            uuid_category_option,
            uuid_category,
            uuid_round,
            int_order_app,
            uuid_sample,
          };

          sampleService.updateBrewNumber(newBrew).then(
            res => {
              if (res.type === 'success' && res.results) {
                setBrewNumbers([...brewNumbers, newBrew]);
                props.sample['arr_brew_numbers'] = [...brewNumbers, newBrew];
              }
            },
            error => {
              //console.log(error);
            },
          );
        }
        setBrewNumber('');
      }

      if (!isBlurEvent) {
        inputText.current.focus();
      }
    }
  };

  return (
    <div className={editRoundSamplesSampleStyle.draggableCardRow}>
      <div className={editRoundSamplesSampleStyle.sampleCardNumber}>
        {props.sample.int_order_app + 1}
      </div>
      <div className={editRoundSamplesSampleStyle.sampleCard}>
        <Button
          disabled={
            typeof props.state === 'object' &&
            props.state.length >= 4 &&
            props.state[3] === 'IN_PROGRESS'
          }
          round
          remove
          label="x"
          className={editRoundSamplesSampleStyle.btnRemoveSample}
          onClick={evt => {
            props.onRemove(props.sample);
          }}
        />
        <CardRow flexWrap="wrap" transparent>
          <CardColumn flex={2} padding="5px 0px 5px 10px">
            <CardRow>
              <CardColumn padding="5px 10px 2px 10px">
                <TextField
                  name="str_name_category_sample"
                  label={t('common_sample')}
                  value={props.sample.str_name_category_sample}
                  disabled
                />
              </CardColumn>

              <CardColumn padding="5px 10px 2px 10px">
                <TextField
                  name="str_name_category"
                  label={t('common_category_sample')}
                  value={props.sample.str_name_category}
                  disabled
                />
              </CardColumn>
            </CardRow>

            <CardRow>
              <CardColumn padding="0px 10px 2px 10px" style={{ width: '50%' }}>
                <TextField
                  name="str_sample_id"
                  label={t('common_sample_id')}
                  value={sampleId}
                  onChange={handleSampleId}
                />
              </CardColumn>
              <CardColumn padding="0px 10px 2px 10px" style={{ width: '50%' }}>
                <DatePickerField
                  name="dt_collection"
                  type={'date'}
                  label={t('common_collection_date')}
                  value={collectionDate ? collectionDate : ''}
                  onChange={handleCollectionDate}
                />
              </CardColumn>
            </CardRow>

            <CardRow>
              <CardColumn padding="0px 10px 5px 10px">
                <TextField
                  name="str_collection_point"
                  label={t('common_collection_point')}
                  value={collectionPoint}
                  onChange={handleCollectionPoint}
                />
              </CardColumn>
              <CardColumn padding="0px 10px 5px 10px">
                <DropdownField
                  name="uuid_brand"
                  options={props.brands}
                  valueField="uuid_brand"
                  labelField="str_name"
                  label={t('common_brand')}
                  margin="0px 0px 0px 0px"
                  value={props.sample.uuid_brand}
                  onChange={handleChangeBrand}
                />
              </CardColumn>
            </CardRow>
            <CardRow>
              <CardColumn padding="0px 10px 5px 10px">
                <TextField
                  name="str_supplier"
                  label={t('common_supplier')}
                  value={supplier}
                  onChange={handleSupplier}
                />
              </CardColumn>
              {props.sample.bol_routine ? (
                <CardColumn padding="0px 10px 5px 10px">
                  <DropdownField
                    name="uuid_routine_warehouse"
                    options={props.testTypes}
                    valueField="uuid_routine_warehouse"
                    labelField="str_name_routine_warehouse"
                    label={t('common_round_type')}
                    margin="0px 0px 0px 0px"
                    value={
                      props.sample.uuid_routine_warehouse
                        ? props.sample.uuid_routine_warehouse
                        : (props.sample.uuid_routine_warehouse =
                            '38b53f88-e19c-11ed-a1b7-0017fa02c1e1')
                    }
                    onChange={handleChangetestType}
                    bolOrder={false}
                    hideRemoveButton
                  />
                </CardColumn>
              ) : props.sample.uuid_category_option ===
                '5a4b7e18-0230-4f33-9f8e-2bdb44888539' ? (
                <CardColumn padding="0px 10px 5px 10px">
                  <DropdownField
                    name="uuid_production_line"
                    options={
                      props.productionLineOpts ? props.productionLineOpts : []
                    }
                    valueField="uuid_production_line"
                    labelField="str_name_production_line"
                    label={t('common_production_line')}
                    margin="0px 0px 0px 0px"
                    value={strProductionLine}
                    onChange={handleChangeInput}
                    bolOrder={false}
                  />
                </CardColumn>
              ) : CPT_EOC_Zero_Hour ? (
                <CardColumn padding="0px 10px 5px 10px">
                  <DropdownField
                    name="bol_over_aged_beer"
                    options={dropDownOpts}
                    valueField="valueField"
                    labelField="labelField"
                    label={t('common_over_aged_beer')}
                    margin="0px 0px 0px 0px"
                    value={
                      props.sample.bol_over_aged_beer
                        ? props.sample.bol_over_aged_beer
                        : (props.sample.bol_over_aged_beer = '0')
                    }
                    onChange={handleChangeInput}
                    bolOrder={false}
                    hideRemoveButton
                  />
                </CardColumn>
              ) : (
                <CardColumn padding="0px 10px 5px 10px"></CardColumn>
              )}
            </CardRow>
            {props.sample.uuid_category_option ===
              '5a4b7e18-0230-4f33-9f8e-2bdb44888539' && (
              <CardRow>
                {props.sample.uuid_category_option ===
                '5a4b7e18-0230-4f33-9f8e-2bdb44888539' ? (
                  <>
                    <CardColumn padding="0px 10px 5px 10px">
                      <DropdownField
                        name="bol_masking_process_for_bottles"
                        options={dropDownOpts}
                        valueField="valueField"
                        labelField="labelField"
                        label={t('common_masking_process_for_bottles')}
                        margin="0px 0px 0px 0px"
                        value={
                          props.sample.bol_masking_process_for_bottles
                            ? props.sample.bol_masking_process_for_bottles
                            : (props.sample.bol_masking_process_for_bottles =
                                '0')
                        }
                        onChange={handleChangeInput}
                        bolOrder={false}
                        hideRemoveButton
                      />
                    </CardColumn>
                    <CardColumn padding="0px 10px 5px 10px"></CardColumn>
                  </>
                ) : (
                  ''
                )}
              </CardRow>
            )}
          </CardColumn>

          <CardColumn padding="0px 20px 5px 0px">
            <CardRow>
              <CardColumn padding="11px 0px 2px 2px">
                <Label label={t('common_brew_number')}></Label>
                <div
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '5px',
                    marginTop: '8px',
                    display: 'flex',
                    flexFlow: 'row wrap',
                  }}
                >
                  {brewNumbers?.map(numb => (
                    <Button
                      key={numb.uuid_brew_number}
                      zIndex={0}
                      style={{
                        whiteSpace: 'nowrap',
                        width: 'auto',
                        padding: '2px 2px',
                        margin: '2px',
                        paddingLeft: '6px',
                        display: 'flex',
                        flexFlow: 'row nowrap',
                      }}
                      confirm
                      label={numb.str_descripition}
                      onClick={() => handleRemoveBrewNumber(numb)}
                    >
                      <X
                        style={{ marginLeft: '5px' }}
                        width="15px"
                        height="15px"
                        strokeWidth="5px"
                      ></X>
                    </Button>
                  ))}

                  <TextField
                    style={{ maxWidth: '160px' }}
                    withouBorder
                    padding="5px"
                    name="str_brew_number"
                    placeholder={t('common_brew_number')}
                    focus={inputText}
                    onHintEnter={e => handleSelectBrewNumber(e, false)}
                    onBlur={e => handleSelectBrewNumber(e, true)}
                    onChange={e => setBrewNumber(e.target.value)}
                    value={brewNumber}
                  />
                </div>
              </CardColumn>
            </CardRow>
            <CardRow>
              <CardColumn padding="0px 0px 2px 2px">
                <Label label={t('common_name_process_item')}></Label>
                <div
                  style={{
                    borderRadius: '5px',
                    display: 'flex',
                    flexFlow: 'row wrap',
                  }}
                >
                  <TextField
                    placeholder={t('common_name_process_item_placeholder')}
                    name="str_process_item"
                    value={processItem}
                    onChange={handleProcessItem}
                  />
                </div>
              </CardColumn>
            </CardRow>
            <CardRow>
              <CardColumn padding="2px 0px 2px 2px">
                <Label label={t('common_name_pts_code')}></Label>
                <div
                  style={{
                    borderRadius: '5px',
                    display: 'flex',
                    flexFlow: 'row wrap',
                  }}
                >
                  <TextField
                    placeholder={t('common_name_pts_code_placeholder')}
                    name="str_pts_code"
                    value={ptsCode}
                    onChange={handlePtsCode}
                  />
                </div>
              </CardColumn>
            </CardRow>
            <CardRow>
              <CardColumn padding="2px 0px 2px 2px">
                {props.sample.uuid_category_option ===
                '5a4b7e18-0230-4f33-9f8e-2bdb44888539' ? (
                  <TextField
                    name="str_packaged_beer_data_code"
                    label={t('common_packaged_beer_data_code')}
                    value={strPackagedBeerDataCode}
                    onChange={handleChangeInput}
                  />
                ) : (
                  CPT_EOC_Zero_Hour &&
                  props.sample.bol_over_aged_beer === '1' && (
                    <TextField
                      name="str_number_days_over_aged"
                      label={t('common_str_number_days_over_aged')}
                      value={strNumberDaysOverAged}
                      onChange={handleChangeInput}
                      text
                      isIconRequired
                    />
                  )
                )}
              </CardColumn>
            </CardRow>
          </CardColumn>
        </CardRow>
      </div>
    </div>
  );
};

EditRoundCategoriesSampleSimple.propTypes = {
  round: PropTypes.any,
  sample: PropTypes.any,
  brands: PropTypes.any,
  onRemove: PropTypes.func,
  setRound: PropTypes.func,
};

const EditRoundCategoriesSample = memo(
  EditRoundCategoriesSampleSimple,
  (prevProps, nextProps) => {
    return Object.is(prevProps.sample, nextProps.sample);
  },
);
export default EditRoundCategoriesSample;

