import React, { useState, useEffect } from 'react';
import editSampleStyle from './editsample.module.scss';
import { Label, Tab, Spinner } from '../../../components';
import EditSampleProduction from '../editSampleProduction/EditSampleProduction';
import EditSampleProject from '../editSampleProject/EditSampleProject';
import BrandService from '../../services/brands/brand.service';
import PackageService from '../../services/packages/package.service';
import { useTranslation } from 'react-i18next';
import SampleService from '../../services/samples/sample.service';
import MaltVarietiesService from '../../services/malt-varieties/malt_varieties.service';
import RoundService from '../../services/rounds/round.service';

const EditSample = props => {
  const { t } = useTranslation();
  const [sample, setSample] = useState({});
  const [families, setFamilies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [packages, setPackages] = useState([]);
  const [volumes, setVolumes] = useState([]);
  const [products, setProducts] = useState([]);
  const [maltVarieties, setMaltVarieties] = useState([]);
  const [isKpiBrand, setIsKpiBrand] = useState(false);
  const brandService = new BrandService();
  const packageService = new PackageService();
  const sampleService = new SampleService();
  const maltVarietiesService = new MaltVarietiesService();
  const [newSampleNum, setNewSampleNum] = useState(0);
  const roundService = new RoundService();
  const [shippingSites, setShippingSite] = useState([]);

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    sampleService.listProducts().then(
      res => {
        if (res.type === 'success' && res.results) {
          setProducts(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );

    brandService.listFamilies().then(
      res => {
        if (res.type === 'success' && res.results) {
          setFamilies(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );

    brandService.listBrands().then(
      res => {
        if (res.type === 'success' && res.results) {
          setBrands(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );

    packageService.listPackages().then(
      res => {
        if (res.type === 'success' && res.results) {
          setPackages(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );

    packageService.listVolumes().then(
      res => {
        if (res.type === 'success' && res.results) {
          setVolumes(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );

    roundService
      .getShippingSites()
      .then(res => {
        setShippingSite(res.results);
      })
      .catch(error => {
        console.log(error);
      });

    maltVarietiesService.listMaltVarieties().then(
      res => {
        if (res.type === 'success' && res.results) {
          setMaltVarieties(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );

    if (!props.location.state.isNewSample) {
      sampleService.getSample(props.location.state.values.uuid_sample).then(
        res => {
          if (res.type === 'success' && res.results) {
            setSample({
              ...res.results[0],
              str_submission_type_name:
                props.location.state.values.str_submission_type_name,
            });
          } else {
            window.alert(res.message);
          }
        },
        error => {
          //console.log(error);
        },
      );
    } else {
      setSample(props.location.state.values);
    }

    if (
      !!props.location.state.values.uuid_site &&
      !!props.location.state.values.uuid_brand
    ) {
      setIsKpiBrand(false);
      sampleService
        .isBreweryBrandKPI(
          props.location.state.values.uuid_site,
          props.location.state.values.uuid_brand,
        )
        .then(
          res => {
            const isAllowToBeKPI = res.results[0].kpi_sp_se_existing_brand_kpi;

            setIsKpiBrand(isAllowToBeKPI);
          },
          err => {
            console.log('error calling service', err);
          },
        );
    }
  };

  const handleType = () => {
    switch (props.location.state.values.str_type) {
      case 'PROJECT':
        return 2;
      case 'PRODUCTION':
      default:
        return 1;
    }
  };

  const tabs = [
    {
      index: 1,
      description: t('common_production'),
      component: () => (
        <EditSampleProduction
          privilege={props.location.state.privilege}
          values={props.location.state.values}
          sample={sample}
          setSample={setSample}
          // setBrand={setBrands}
          families={families}
          products={products}
          brands={brands}
          packages={packages}
          volumes={volumes}
          maltVarieties={maltVarieties}
          shippingSites={shippingSites}
          sampleType={props.location.state.values.str_type}
          isKpiBrand={isKpiBrand}
          newSampleNum={newSampleNum}
          setNewSampleNum={setNewSampleNum}
          isNewSample={props.location.state.isNewSample}
          setForIsKpiBrand={setIsKpiBrand}
        />
      ),
    },
    {
      index: 2,
      description: t('common_project'),
      component: () => (
        <EditSampleProject
          privilege={props.location.state.privilege}
          sample={sample}
          setSample={setSample}
          families={families}
          products={products}
          brands={brands}
          packages={packages}
          volumes={volumes}
          maltVarieties={maltVarieties}
          sampleType={props.location.state.values.str_type}
        />
      ),
    },
  ];

  return (
    <div className={editSampleStyle.pageContainer}>
      <Label
        label={
          props.location?.state?.isNewSample
            ? t('sample_new_sample')
            : t('sample_edit_sample')
        }
        bold
        neutral
      />
      {sample &&
      families.length > 0 &&
      brands.length > 0 &&
      packages.length > 0 &&
      volumes.length > 0 ? (
        <Tab tabs={tabs} selectedTabIndex={handleType()} />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default EditSample;

