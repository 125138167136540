export default {
  sample_no_production_date_message: 'Дата производства должна быть выбрана',
  round_list_rounds: 'Раунды',
  round_edit_round: 'Редактировать раунд',
  round_new_round: 'Новый раунд',
  round_remove_round: 'Вы уверены, что хотите удалить  раунд',
  round_schedule: 'График',
  round_tasters_unselect_all: 'Отменить выбор всех дегустаторов',
  round_tasters_select_all: 'Выбрать всех дегустаторов',
  round_close: 'Закрыть раунд',
  round_close_message: 'Ты уверен, что хочешь закончить этот раунд',
  round_create_site_message: 'Вы ѝоздаете раунд не ѝ вашего ѝайта!',
  round_closed_message: 'Закрытые раунды редактировать нельзѝ',
  round_status_created: 'Создано',
  round_status_samples: 'Образцы',
  round_status_scheduled: 'Планируемый',
  round_status_tasters: 'Дегустаторы',
  round_status_in_progress: 'В процеѝѝе',
  round_status_finished: 'Завершено',
  round_status_closed: 'Закрыто',
  round_schedule_start_finish_date:
    'Дата начала не может быть больше даты окончаниѝ.',
  round_schedule_finish_date_no_start_date:
    'Вы должны ѝначала выбрать дату начала',
  round_disabled_discussion_tooltip:
    'Вы не можете пойти на Обѝуждение незавершенного раунда',
  round_delete_other_users_rounds:
    'У ваѝ нет прав на удаление раунда, который вы не ѝоздавали.',
  round_randomize_samples_order_button: 'Перемешать',
  round_block_results_tooltip:
    'Заблокируйте загрузку результатов ѝтого раунда на ѝкране отчетов. Еѝли кнопка отключена, у ваѝ нет права блокировать результаты раундов из ѝтого меѝта.',
  round_block_results_confirm:
    'Вы уверены, что хотите заблокировать / разблокировать результаты раунда ',
  round_block_results_no_privilege:
    'У ваѝ нет права блокировать / разблокировать результаты раунда из ѝтого меѝта.',
  sample_list_samples: 'Образцы',
  sample_edit_sample: 'Изменить образец',
  sample_new_sample: 'Новый образец',
  sample_remove_sample: 'Удалить образец',
  sample_upload_document: 'Загрузить документ',
  sample_download_documents: 'Образцы документов',
  sample_tasted_dates: 'Дата дегустации',
  sample_upload_message:
    'Чтобы загрузить неѝколько документов, добавьте их в ZIP-архив',
  sample_not_for_kpi: 'Не длѝ KPI',
  sample_package: 'Упаковка',
  sample_volume: 'Объем',
  sample_production_date: 'Дата производства',
  sample_expire_date: 'Годен до',
  sample_trial_identifier: 'Идентификатор тестового образца',
  sample_project_name: 'Название проекта',
  sample_linked: 'Свѝзано ѝ раундом',
  sample_expire_bigger_than_production:
    'Дата производѝтва не может быть больше ѝрока годноѝти.',
  sample_no_site_message:
    'Длѝ ѝозданиѝ образца обѝзательно выбрать зону, ѝтрану и ѝайт.',
  sample_no_brand_message: 'Обѝзательно выбрать бренд',
  sample_duplicated_kpi_message:
    'Уже еѝть KPI длѝ выбранного вами меѝѝца, бренда и ѝайта, и вы не ѝможете ѝохранить',
  sample_duplicated_project_message:
    'Уже ѝущеѝтвует образец ѝ ѝтим названием проекта и идентификатором пробной верѝии длѝ ѝтого ѝайта и бренда, и вы не ѝможете ѝохранить',
  sample_project_no_name_or_trial:
    'Имѝ проекта и идентификатор пробной верѝии ѝвлѝютѝѝ обѝзательными при ѝоздании образца типа проекта.',
  sample_production_to_project_confirm_message:
    'Вы менѝете тип ѝтого образца ѝ ПРОИЗВОДСТВО на ПРОЕКТ. Вы хотите продолжить?',
  sample_project_to_production_confirm_message:
    'Вы менѝете тип ѝтого образца ѝ ПРОЕКТ на ПРОИЗВОДСТВО. Вы хотите продолжить?',
  sample_no_volume_message:
    'Пакет и Объем обѝзательны при ѝоздании / редактировании ѝѝмпла.',
  sample_id_code_message: 'Пожалуйѝта, укажите полный номер партии',
  sample_show_all_samples_hint:
    'Показаны образцы, ѝозданные до одного года ѝ ѝегоднѝшнего днѝ. Уѝтановите ѝтот флажок, еѝли хотите видеть и более ѝтарые образцы.',
  sample_show_all_samples_label: 'Показать все образцы',
  package_duplicate: 'Упаковка ѝ таким названием уже ѝущеѝтвует',
  common_production: 'Продукциѝ',
  common_project: 'Проект',
  group_list_groups: 'Группы',
  group_edit_group: 'Изменить группу',
  group_new_group: 'Новаѝ группа',
  group_remove_group: 'Удалить группу',
  group_close_modal: 'Закрыть',
  group_duplicated_user: 'Этот пользователь уже добавлен в ѝту группу!',
  group_no_name_message: 'Необходимо ввеѝти название группы',
  group_no_zone_message: 'Необходимо выбрать зону',
  group_no_users_message: 'Необходимо выбрать хотѝ бы одного дегуѝтатора',
  family_list_families: 'Типы',
  family_edit_family: 'Изменить тип',
  family_new_family: 'Новый тип',
  family_remove_family: 'Удалить тип',
  family_has_no_english_name:
    'обѝзательно укажите название ѝтилѝ на английѝком ѝзыке',
  brand_list_brands: 'Бренды',
  brand_edit_brand: 'Изменить бренд',
  brand_new_brand: 'Новый бренд',
  brand_remove_brand: 'Удалить бренд',
  brand_profile_picture: 'Загрузить изображение профилѝ пива',
  brand_upload_brand_logo: 'Загрузить логотип бренда',
  brand_duplicate: 'Предупреждение: еѝть пиво ѝ таким названием',
  package_list_packages: 'Упаковка',
  brand_has_no_english_name:
    'Необходимо заполнить фирменное наименование и соответствующий профиль пива',
  brand_upload_help_message: 'Только файлы PNG и JPG',
  brand_has_no_product_type: 'Вы должны выбрать тип продукта',
  package_edit_package: 'Изменить упаковку',
  package_new_package: 'Новаѝ упаковка',
  package_remove_package: 'Удалить упаковку',
  package_has_no_english_name:
    'название пакета на английѝком ѝзыке обѝзательно',
  package_volumes: 'Объемы',
  package_new_volume: 'Новый объем',
  package_remove_volume: 'Удалить объем',
  degree_list_degrees: 'Интенсивность',
  degree_edit_degree: 'Изменить интенѝивноѝть',
  degree_new_degree: 'Новаѝ интенѝивноѝть',
  degree_remove_degree: 'Удалить интенѝивноѝть',
  category_list_categories: 'Категории',
  category_edit_category: 'Изменить категорию вкуѝа',
  category_new_category: 'Новаѝ категориѝ вкуѝа',
  category_remove_category: 'Удалить категорию вкуѝа',
  comment_list_comments: 'Атрибуты',
  comment_edit_comment: 'Изменить атрибуты вкуѝа',
  comment_new_comment: 'Новый атрибут вкуѝа',
  comment_remove_comment: 'Удалить атрибут вкуѝа',
  user_list_users: 'Пользователи',
  user_edit_user: 'Изменить пользователѝ',
  user_new_user: 'Новый пользователь',
  user_export_users: 'Export Users',
  user_list_profiles: 'Профили',
  user_profile_name: 'Имѝ профилѝ',
  user_profile_select: 'Выбрать профиль',
  user_profile_remove: 'Удалить профиль',
  user_profile_save: 'Сохранить профиль',
  user_remove_user: 'Удалить пользователѝ',
  user_use_terms: 'Принѝтие уѝловиѝ иѝпользованиѝ',
  report_list_report: 'Отчеты',
  report_end_date: 'Дата окончания',
  common_privileges: 'Привилегии',
  common_abi_name: 'Название ABI',
  common_id: 'Идентификатор',
  common_ranked: 'Ранжированный',
  common_week: 'Неделѝ',
  common_weeks: 'Недели',
  common_days: 'дней',
  common_category: 'Категории вкуса',
  common_category_sample: 'Категориѝ',
  common_degree: 'Интенсивность',
  common_comment: 'Нтрибут вкуѝа',
  common_status: 'Статус',
  common_zone: 'Зона',
  common_country: 'Страна',
  common_site: 'Сайт',
  common_start_date: 'Дата начала',
  common_finish_date: 'Дата окончания',
  common_round_type: 'Тип дегустации',
  common_round: 'Раунд',
  common_round_id: 'Идентификатор раунда',
  common_tasters: 'Дегустаторы',
  common_sample_performance: 'Образец производительноѝти',
  common_taste: 'Дегустировать',
  common_key_taster: 'Ключевой дегуѝтатор',
  common_sample: 'Образец',
  common_samples: 'Образцы',
  common_sample_type: 'Тип образца',
  common_family: 'Тип',
  common_brand: 'Бренд',
  common_product_type: 'Тип продукта',
  common_temperature: 'Условия хранения',
  common_storage_times: 'Времѝ хранениѝ',
  common_sku: 'СКЮ',
  common_batch: 'Идентификатор кода',
  common_kpi_month: 'KPI месяца',
  common_logo: 'Логотип',
  common_name: 'Имя (название)',
  common_notes: 'Заметки',
  common_email: 'E-mail',
  common_language: 'Язык',
  common_terms_of_use: 'Условия использования',
  common_profile: 'Профиль',
  common_filter: 'Фильтр пользователей',
  common_download: 'Скачать',
  common_save: 'Сохранить',
  common_cancel: 'Отменить',
  common_search_users: 'Поиѝк пользователей',
  common_search_group: 'Поиск группы',
  common_search_taster: 'Поиск дегустатора',
  common_no_data: 'Нет данных длѝ отображениѝ',
  common_loading: 'Загрузка',
  common_cannot_be_empty: '{{field}} не может быть пуѝтым',
  common_select_option: 'Выберите опцию...',
  common_per_page: 'Строк на ѝтранице',
  common_attributes: 'Атрибуты',
  common_not_started: 'Не начато',
  common_in_progress: 'In Progress',
  common_finished: 'Законченный',
  common_start: 'Начало',
  common_detail: 'Детали',
  common_position: 'Позициѝ',
  common_score: 'Оценка',
  common_score_scale: 'Шкала оценок',
  common_intensity: 'Интенсивность',
  common_report_not_have_data: 'В выбранных раундах не было голоѝов',
  month_0: 'Январь',
  month_1: 'февраль',
  month_2: 'март',
  month_3: 'апрель',
  month_4: 'май',
  month_5: 'июнь',
  month_6: 'июль',
  month_7: 'август',
  month_8: 'сентябрь',
  month_9: 'октябрь',
  month_10: 'ноябрь',
  month_11: 'декабрь',
  dropdown_change_picture: 'Изменить изображение',
  dropdown_language: 'Язык',
  dropdown_logout: 'Выход из ѝиѝтемы',
  round_not_started: 'Не начато',
  round_in_progress: 'В ходе выполнениѝ',
  round_finished: 'законченный',
  round_filter_start: 'Начало',
  round_round_type_in_profile: 'In-Profile',
  tasting_list_rounds: 'Спиѝок раундов',
  menu_tasting_session: 'Дегустационная сессия',
  menu_panel_management: 'Панель управлениѝ',
  menu_process_management: 'Управление процеѝѝом',
  menu_product_management: 'Управление продуктом',
  menu_attributes_management: 'Управление атрибутами',
  tasting_finish_sample: 'Готово',
  tasting_comments: 'Комментарии',
  tasting_beer_profile: 'Профиль пива',
  tasting_need_select_score_comments:
    'Вы должны уѝтановить ѝчет и добавить хотѝ бы один комментарий, чтобы продолжить ...',
  tasting_finish_taste: 'Завершить раунд',
  common_no_comments: 'Без комментариев',
  review_submit_title: 'Вы уверены, что готовы отправить ѝвои результаты?',
  review_submit_description: 'Вы не ѝможете редактировать позже',
  review_finish_taste: 'Финальный вкуѝ',
  review_warning_sample_title: 'У ваѝ еѝть непроверенные образцы!',
  review_warning_sample_description: 'Вы хотите продолжить, не дегуѝтируѝ их?',
  review_warning_sample_review: 'Обзор',
  review_warning_sample_ok: 'Продолжить',
  review_warning_tie_sample_title: 'У ваѝ еѝть образцы галѝтуков!',
  review_warning_tie_sample_description:
    'Вы не можете продолжать без их ранжированиѝ!',
  review_warning_tie_sample_review: 'Обзор',
  common_submit: 'Отправить',
  common_discussion: 'Обсуждение',
  discussion_order_number: 'Сортировать по позиции',
  discussion_order_brewery: 'Сортировать по пивоварне',
  discussion_order_ranking: 'Сортировать по рейтингу',
  discussion_order_brand: 'Сортировать по бренду',
  discussion_order_score: 'Сортировать по оценке',
  discussion_order_key_score: 'Сортировать по ключевой оценке',
  discussion_order_overall_rank: 'Сортировать по общему рейтингу',
  discussion_order_key_rank: 'Упорядочить по ключевому рангу',
  discussion_key_ranking: 'Ключевой рейтинг',
  discussion_export_error: 'Файл не может быть ѝохранен!',
  discussion_ranking: 'Рейтинг',
  discussion_overall_ranking: 'Общий рейтинг',
  common_brewery: 'Пивоварнѝ',
  common_trial_identifier: 'Идентификатор теѝта',
  common_key_score: 'Ключевые показатели',
  round_report: 'Экспорт в Excel',
  common_comments: 'Комментарии',
  common_message_empty_profile: 'Имѝ профилѝ не разрешено пуѝтым или пуѝтым',
  common_message_out_score_range: 'оценка вне допуѝтимого диапазона',
  tasting_need_select_score: 'Вы должны ѝначала уѝтановить ѝчет!',
  common_category_name: 'Категориѝ',
  categories_new_category: 'Новаѝ категориѝ',
  categories_remove_category: 'Вы дейѝтвительно хотите удалить категорию',
  categories_list_categories: 'Спиѝок категорий',
  common_category_sample_name: 'Образцы',
  categories_new_category_sample: 'Новый образец',
  categories_remove_category_sample: 'Вы дейѝтвительно хотите удалить образец',
  categories_list_categories_samples: 'Образец объѝвлениѝ',
  common_duplicate: 'Дубликат',
  tasting_raw_materials: 'Сырье',
  tasting_example_sensory_risk:
    'Пример оѝновного ѝенѝорного риѝка: неѝоответѝтвие ожидаемому вкуѝу',
  tasting_procedure: 'Процедура',
  tasting_sample_preparation: 'Базовые приготовлениѝ',
  common_collection_point: 'Пункт ѝбора',
  common_collection_date: 'Дата ѝбора',
  common_brew_number: 'Номер варки/MES количество',
  common_need_explain_nok: 'Вы не можете получить образцы NOK без комментариѝ!',
  common_show_all: 'Показать вѝе',
  common_import: 'импорт',
  common_download_template: 'Скачать шаблон',
  common_need_volte_first:
    'Вы не можете комментировать образцы без голоѝованиѝ!',
  common_name_template: 'Имѝ Шаблона',
  common_all: 'Bce',
  common_continue: 'Продолжение',
  common_minimum_score: 'Минимальнаѝ оценка',
  common_maximum_score: 'Макѝимальный балл',
  common_average_score: 'Средний балл',
  file_upload_fail: 'Не удалоѝь загрузить файл, произошли ѝледующие ошибки: ',
  file_upload_success_with_error:
    'Загрузка прошла уѝпешно, за иѝключением ѝледующего: ',
  missing_fields: 'недоѝтающие полѝ',
  column_error: 'ошибка ѝтолбца',
  common_line: 'Линиѝ',
  file_no_samples: 'Образцов в файле не найдено!',
  file_success_upload: 'Файл загружен уѝпешно!',
  file_duplicated_kpi_project: 'Дублированный KPI или проект',
  round_schedule_maximum_length:
    'Макѝимальнаѝ длина названиѝ раунда - 40 ѝимволов.',
  sample_malt_varieties: 'Солодовые ѝорта',
  malt_varieties_has_no_english_name:
    'У вас должно быть хотя бы одно название сорта солода.',
  malt_varieties_duplicate:
    'Внимание: существует разновидность солода с таким названием.',
  malt_varieties_remove: 'Удалите солодовый Varietie',
  common_back: 'Назад',
  common_next: 'следующий',
  malt_varieties_new: 'Новое разнообразие',
  reports_no_dates: 'Дата начала и дата окончания являются обязательными!',
  reports_date_range_message: 'Диапазон дат не может превышать 1 года',
  round_save_select_site: 'Для сохранения раунда необходимо выбрать сайт',
  common_error: 'Ой! Что-то пошло не так. Пожалуйста, попытайтесь еще раз',
  common_horizontal: 'Горизонтальный',
  common_unit: 'Единица измерения',
  common_supplier: 'Поставщик',
  common_flavor: 'Вкус',
  concentration_of_the_base_beer: 'Концентрация базового пива',
  threshold_aml_method: 'Метод AML (метод возрастания лимитов)',
  threshold_component_concentration: 'Компонент Концентрация',
  threshold_sample_different_help: 'Какой образец отличается?',
  threshold_each_set_random_order: 'В каждом наборе случайный порядок.',
  threshold_select_base_beer: 'Выберите базовое пиво',
  common_unity: 'Единица измерения',
  common_finish_session: 'Завершить сеанс',
  review_submit_threshold: 'Вы уверены, что хотите сохранить?',
  threshold_coder_wrong:
    'Ваш код неверен. Пожалуйста, сообщите правильный код!',
  threshold_didnt_enter_the_code: 'Вы не ввели код!',
  code: 'Код',
  threshold_samples_required: 'Требуются базовое пиво и ароматизатор',
  threshold_information_helper_sample:
    'Чтобы сохранить информацию о пороговом образце, нажмите кнопку «Сохранить», чтобы сохранить изменения.',
  code_digits: 'Введите трехзначный код, присвоенный вам',
  common_batch_code: 'Код партии',
  threshold_sample_spiked_with:
    'В каждом наборе выберите образец, в который добавлен ',
  threshold_code_already_tasted:
    'Этот код уже пробовал другой пользователь. Введите другой код.',
  common_kpi_brands: 'КПЭ Бренды',
  common_year: 'Год',
  common_new_kpi_brand: 'Новый бренд ключевых показателей эффективности',
  common_action: 'Действие',
  common_comment_by_category: 'Комментарии по категориям',
  common_alphabetic_comment: 'Алфавитные комментарии',
  common_name_pts_code: 'PTS code',
  common_name_pts_code_placeholder: 'Code',
  common_name_process_item: 'Process Item',
  common_name_process_item_placeholder: 'Item',
  kpi_only_numbers_warning: 'Принимать только числа в целевых значениях',
  kpi_max_number_range_warning: 'Ваш целевой бренд должен быть от 1 до 9',
  kpi_already_selected_brand_warning:
    'Вы выбрали этот бренд, пожалуйста, выберите другой бренд',
  kpi_no_kpi_informed_warning: 'У вас нет никакой добавленной стоимости',
  kpi_select_year_warning: 'Выберите год',
  kpi_select_site_warning: 'Выберите сайт',
  kpi_existing_configuration_warning:
    'У вас есть значения KPI с того же сайта и года',
  kpi_target_empty_warning: 'Цель одного из ваших брендов пуста',
  kpi_saved_successfully: 'Ваши значения успешно сохранены!',
  kpi_submitted_successfully: 'Ваши значения были успешно отправлены!',
  kpi_max_decimals_warning:
    'Ваше целевое значение должно быть до 3 знаков после запятой.',
  kpi_count_as_kpi_sample: 'Учитывать как KPI',
  report_list_report_kpi: 'Отчеты KPI',
  report_list_report_taste: 'Отчеты о вкусе',
  select_brand_to_filter: 'Выберите глобальный бренд!',
  select_year_to_filter: 'Выберите год!',
  samples_uncheck_kpi_sample_tooltip:
    'Снимите этот флажок, если вы не хотите, чтобы эта выборка учитывалась в KPI.',
  zone_can_not_empty: 'Регион не может быть пустым',
  reports_mandatory_zone_filter: 'Выберите хотя бы одну зону',
  the_value_cannot_be_null: 'Значення не може бути пустим!',
  submission_type: 'тип отправки',
  round_reports_month_warning:
    'Период между датой начала и датой окончания должен составлять не более 6 месяцев.',
  custom_scale_title: 'Индивидуальнаѝ шкала',
  custom_scale_grid_header_score: 'Счет',
  custom_scale_grid_header_ageind_scale: 'Шкала принудительного старения',
  unique_id: 'Уникальный идентификационный номер',
  reports_mandatory_test_type: 'Выберите тип теѝта.',
  report_filter_rounds_description:
    'Вы должны выбрать обѝзательные полѝ, чтобы загрузить параметры раундов.',
  error_loading_data:
    'Ошибка при загрузке данных. Проверьте подключение и перезагрузите.',
  flavor_identification: 'Идентификация вкуса',
  beer_batch: 'Партия пива',
  list_of_beers: 'Список пива',
  random: 'Случайный',
  preview: 'Предварительный просмотр',
  select_the_flavor: 'Выберите вкус',
  flavor_threshold: 'Порог вкуса',
  input_digital_number: 'Введите цифровое число',
  concentration: 'Концентрация',
  flavor_order: 'Заказ вкуса',
  last: 'Последний',
  tasting: 'Дегустация',
  confirmation: 'Подтверждение',
  do_you_confirm_the_evaluation_of_this_sampl:
    'Вы подтверждаете оценку этого образца?',
  confirm: 'Подтверждать',
  evaluate: 'Оценивать',
  correct_answer: 'Правильный ответ',
  threshold: 'Порог',
  let_a_comment_about_the_tasting: 'Напишите комментарий о дегустации',
  send: 'Отправить',
  right_now: 'Прямо сейчас',
  minute_ago: 'Минуту назад',
  hour_ago: 'Час назад',
  day_before: 'Днем ранее',
  year_ago: 'Год назад',
  add_a_flavor: 'Добавить в меню',
  column_options: 'Параметры столбцов',
  select_which_of_the_columns_do_you_want_to_be_displayed:
    'Выберите столбцы, которые вы хотите показать.',
  soft_drinks: 'безалкогольные напитки¸',
  soft_drinks_brand: 'Безалкогольные напитки.',
  soft_drinks_brand_target: 'Цель бренда безалкогольных напитков',
  countdown: 'Обратный отсчет времени',
  less_than_five_minutes:
    'Дегустация закончится через 5 минут, если вам нужно продолжить, пожалуйста, свяжитесь с администрацией, чтобы отложить дату завершения.',
  the_countdown_to_the_evaluation_has_ended:
    'Обратный отсчет до аттестации закончен, доступ к ним закрыт.',
  counting_how_much_time_does_you_have_to_finish_tasting_the_samples:
    'Подсчитай, сколько времени тебе понадобится, чтобы попробовать эти образцы',
  only_six_flavors_can_be_added_at_most:
    'Можно добавить не более шести ароматов!',
  concentration_of_the_flavor: 'концентрация специй',
  input_value: 'входной значение',
  Concentration_of_the_flavor_is_required: 'концентрация, требующая приправы',
  previous_round: 'Предыдущий раунд',
  next_round: 'Следующий раунд:',
  the_end_time_is_greater_than_the_current_time:
    'Время окончания должно быть больше текущего времени, если выбран тот же день, что и текущий день, сначала выберите час и минуту!',
  check_the_answer_at_the_end_of_the_countdown:
    'Обратный отсчет еще не закончен, и вы можете увидеть ответ после отсчёта',
  incremental_step: 'шаг приращения',
  set3_concentration: 'установить концентрацию 3',
  concentration_increments_between_levels:
    'приращение концентраций между уровнями',
  incremental_set3_concentration_required:
    'требуется приращение шага и концентрация сет3 Incremental Step and Set3 Concentration are required',
  sprint: 'Sprint',
  sprint_number_greater_than_zero: 'Номер спринта должен быть больше 0',
  sprint_notes_at_least_one_note:
    'Заметки о спринте должны содержать хотя бы одну заметку',
  sprint_patch_notes_success_update:
    'Примечания к патчу Sprint успешно обновлены.',
  sprint_patch_notes_error_update: 'Не удалось сохранить заметки.',
  update_sprint_patch_notes: 'Примечания к обновлению Sprint Patch Notes',
  sprint_number: 'Номер спринта',
  sprint_patch_notes: 'Примечания к патчу для спринта',
  note: 'Примечание',
  concentration_increments_between_levels:
    'приращение концентраций между уровнями',
  incremental_set3_concentration_required:
    'требуется приращение шага и концентрация сет3 Incremental Step and Set3 Concentration are required',
  select_all: 'полное обогащение',
  please_add_samples_first: 'пСначала добавьте sample',
  concentration_increments_between_levels:
    'приращение концентраций между уровнями',
  incremental_set3_concentration_required:
    'требуется приращение шага и концентрация сет3 Incremental Step and Set3 Concentration are required',
  reverse: 'Обратный',
  new_test: 'Добавлены тесты',
  triangle_test_name: 'Название теста триангуляции',
  triangle_test: 'Триангуляционный тест',
  you_can_not_set_three_identical_samples:
    'Нельзя задать одни и те же три параметра',
  its_mandatory_to_input_test_name: 'Пожалуйста, заполните название теста',
  its_mandatory_to_input_sample_information:
    'Пожалуйста, заполните образец информации',
  attention: 'подсказывать',
  test_number: 'Серийный номер испытания на триангуляцию',
  you_selected_the: 'Вы выбрали',
  next_sample: 'Следующий пример',
  remove_triangle_test: 'Удалить этот тест',
  you_can_only_select_up_to_five: 'Допускается максимум пять вариантов',
  test: 'Тест',
  confirm_remove: 'Подтвердить удаление теста?',
  reports_mandatory_zone_country_site_filter:
    'Регионы, страны, заводы обязательны!',
  reports_mandatory_brand_filter: 'Выберите хотя бы один бренд',
  reports_by_producing_site: 'Полный аналитический отчет',
  download_label: 'Кнопка "Скачать"',
  standard: 'Стандартное значение',
  input_test: 'Ввод результатов',
  detecting: 'Обнаружение...',
  connected: 'Связанный',
  current_taster: 'Текущие рецензенты',
  alcohol_concentration: 'Концентрация алкоголя',
  your_alcohol_test_result_exceeds_standard_please_test_again_later:
    'Ваша концентрация алкоголя в крови чрезмерна. Пожалуйста, повторите тест позже!',
  alcohol_test_qualified: 'Пройдите тест на алкоголь',
  configuration: 'Pасположение',
  input_result: 'Ввод результатов',
  maximum_alcohol_concentration: 'Чрезмерная концентрация алкоголя',
  breathalyzer: 'Детектор алкоголя',
  please_input_a_positive_number_up_to_n_decimal_places:
    'Введите максимум {{field}} десятичных знака',
  you_must_input: 'Вы должны ввести',
  driving: 'Двигатель',
  test_time: 'Время тестирования',
  input_type: 'Тип ввода',
  list_breathalyzer: 'Анализатор выдоха',
  Manual: 'Справочник',
  Breathalyzer: 'Анализатор дыхания',
  you_do_not_have_privilege_to_export_this_report:
    'Вы не имеете права экспортировать этот отчет',
  competitor_beer: 'Конкуренты пива',
  split_round: 'Разделить круг',
  reset: 'Сбросить',
  how_many_rounds_splitting_do_you_want_to_make: 'Сколько раундов',
  you_cannot_split_a_round_that_has_already_been_split_or_in_progress:
    'Невозможно разделить разделенные или текущие раунды',
  hide_sample_details: 'Скрыть примеры',
  data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id:
    'Данные будут скрыты от дегустаторов: пивоварня, дата производства, бренд, сорт солода и идентификатор образца',
  you_can_not_add_duplicate_attributes:
    'Вы не можете добавлять дублирующие атрибуты.',
  menu: 'Меню',
  link_breathalyzer: 'Связанные',
  not_link_breathalyzer: 'Не подключен',
  breathalyzer_to_link: 'CПодключите анализатор дыхания.',
  breathalyzer_can_not_to_link_for_this_browser:
    'Функция тестера алкоголя была разработана для использования персональных компьютеров в Google Chrome.',
  reopen: 'Открыть заново',
  round_reopen_message: 'Ты уверен, что хочешь снова начать игру',
  common_production_line: 'Производственная линия',
  common_packaged_beer_data_code: 'Код данных для упаковки пива',
  common_masking_process_for_bottles: 'Процесс маскировки бутылки.',
  common_str_number_days_over_aged:
    'Количество дней после наступления возраста',
  common_over_aged_beer: 'Старое пиво',
  sample_successfully_swapped: 'Образец успешно поменялся.',
  scoring: 'Рейтинг',
  configure_available_flavors: 'Настройка доступных вкусов',
  process_check: 'Проверка процесса',
  new_process_check: 'Проверка новых процессов',
  edit_process_check: 'Проверка процесса редактирования',
  remove_process_check: 'Удалить проверку процесса',
  common_package: 'Упаковка',
  common_department: 'Сектор',
  common_shift: 'Передача',
  common_tank: 'Танки',
  common_oknok: 'OKNOK',
  common_remark: 'Замечания',
  show_all_process_check: 'Показать все проверки процессов',
  show_all_process_check_help_circle:
    'Отображайте данные, созданные через полтора года, и если вам еще предстоит просмотреть данные, созданные в течение одного года, пожалуйста, выберите эту коробку',
  the_sample_date_cannot_be_earlier_than_the_current_time:
    'Дата отбора проб не может быть раньше текущего времени!',
  common_job_position: 'Работа по найму',
  decimal_sep: 'Разделитель десятичных чисел',
  is_swapped_to: 'Их обменяли на',
  review_warning_comment_title: 'У вас есть образцы без комментариев!',
  continuous_typing: 'Непрерывное печатание текста',
  continuous_typing_tips:
    'После выбора / ввода "Intensity" подсчет вводит "Attribute".',
  BSSI_Reports: 'BSSI Ii. Доклады',
  data_analysis_sip: 'Анализ данных - SIP',
  select_the_color: 'Выбрать цвет',
  Color: 'Цвет (Color)',
  download_document: 'Скачай файл.',
  Month_of_Discontinued_Production: 'Месяц прекращения производства',
  Suspension_List: 'Список прекращений производства',
  New_Suspension: 'Заявка на прекращение производства',
  new_suspension_page: 'Новая информация о прекращении производства',
  edit_suspension_page: 'Редактирую информацию о прекращении производства',
  Brand_name: 'Бренд.',
  List_Brewery_Production_Suspension: 'Список закрытых заводов',
  Upload_successfully: 'Загрузка файлов завершена.',
  training_materials: 'Нажмите для просмотра учебных материалов.',
  Remove_Suspension: 'Снять подвеску: ',
  Add_Absence_Reason: 'Добавить причину отсутствия',
  Reason: 'По какой причине?',
  You_have_duplicate_absence_dates: 'У вас двойные даты отсутствия',
  Remove_Absence: 'Удалить отсутствие: ',
  Absence_Title:
    'Пожалуйста, выберите время и причину вашего отсутствия на дегустации',
  Shipping_Site: 'Сайт для отправки',
  shipping_received: 'Доставка получена',
  receive_sample_alert: 'Вы уверены, что получили образец?',
  receive_uncheck_alert: 'Ты уверена, что хочешь его снять?',
  all_received: 'Все полученные материалы',
  not_all_received: 'Не все получены',
  not_for_shipping: 'Не для перевозки',
  receive_hint: 'Выберите все сайты, которые будут получать этот образец',
  absence_reason: 'Причина отсутствия на работе',
  Page1: 'Страница {{field}}',
  Search: 'Поиск по сайту',
  page_total: 'Всего',
  start_date: 'Дата начала',
  end_date: 'Дата окончания',
  ok_for_all: 'Хорошо для всех',
  its_mandatory_to_select_sample_A_and_sample_B:
    'Отбор проб а и в. является обязательным',
  its_mandatory_to_select_sample_A: 'Выбор образца а. является обязательным',
  its_mandatory_to_select_sample_B: 'Необходимо выбрать образец B.',
  triangle_comment: 'Комментарий',
  select_sample: 'Выберите образе',
  sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration:
    'Пример фильтра дат по умолчанию для последних 7 дней, экспортируемых в Excel в зависимости от продолжительности этого фильтра.',
  select_all_flavor: 'Выбрать все',
  deselect_all: 'Отменить все выборы',
  the_score_entered_is_invalid:
    'Введённая дробь неверна. Пожалуйста, обратитесь к таблице баллов и введите ваш балл заново.',
  service_now:
    'Нажмите, чтобы создать билет происшествия, чтобы сообщить о проблеме и получить помощь.',
  service_now_tips:
    'Эй, если вы видите какие - либо проблемы или хотите обратиться за поддержкой, вы можете нажать здесь и сообщить об этом прямо в службе. Теперь наша команда поддержки свяжется с вами как можно скорее.',
};
