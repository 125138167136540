import React, { useEffect } from "react";
import {useUser} from "../contexts/User";
import Login from '../modules/Login/Login';
import LoginSSO from '../modules/LoginSSO/LoginSSO';
import Template from "../modules/Template/Template";
import _ from "lodash";

const Routes = () => {
    const {isLogged} = useUser(); 

    return !isLogged ? (
      process.env.REACT_APP_STAGE === "local" ||
      process.env.REACT_APP_STAGE === "dev" ? (
        <Login />
      ) : (
        <LoginSSO />
      )
    ) : (
      <Template />
    );
};

export default Routes;