import React, { useState, useEffect, useRef } from 'react';
import { GenericTable, Modal, Button, TextField } from '../../../components';
import AlcoholService from '../../services/users/alcohol.service';
import { useTranslation } from 'react-i18next';
import AppService from '../../../app.service';
import listInputResultStyle from './listInputResult.module.scss';
import { Edit } from 'react-feather';
import Moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { useUser } from '../../../contexts/User';

export default function ListInputResult() {
  const [loading, setLoading] = useState(false);
  const [isShowInputResult, setIsShowInputResult] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [list, setList] = useState([]);
  const [alcholStandard, setAlcholStandard] = useState('');
  const [curRow, setCurRow] = useState();
  const [tasteDate, setTasteDate] = useState();
  const appService = new AppService();
  const alcoholService = new AlcoholService();
  const { t } = useTranslation();
  const { user } = useUser();
  const nowSite = user.str_name_site;
  const [isLink, setIsLink] = useState(false);
  const readOnlyButton = !('serial' in navigator);
  const [showDevice, setShowDevice] = useState(false);
  const [deviceInformation, setDeviceInformation] = useState({});
  const [zoneUnit, setZoneUnit] = useState('');

  sessionStorage.setItem('inputType', 'Manual');
  useEffect(() => {
    refreshState();
    return () => {
      if ('serial' in navigator) {
        navigator.serial.removeEventListener('connect', () => {});
        navigator.serial.removeEventListener('disconnect', () => {});
      }
    };
  }, []);

  const refreshState = async () => {
    const privilegeRes = await appService.listPrivileges(
      'USER_MANAGEMENT_EDIT',
    );
    if (privilegeRes.type === 'success' && privilegeRes.results) {
    } else {
      window.alert(privilegeRes.message);
    }
    setLoading(true);
    const res = await alcoholService.listAlcoholResult({
      taste_date: Moment(new Date()).format('YYYY-MM-DD'),
    });
    setTasteDate(Moment(new Date()).format('YYYY-MM-DD'));
    setList(res.results);
    const standard = await alcoholService.userAlcoholStandard();
    setAlcholStandard(
      standard.results[0]?.flt_alcohol_concentration ||
        standard.results[0]?.flt_alcohol_concentration == 0
        ? standard.results[0].flt_alcohol_concentration
        : '',
    );
    setZoneUnit(
      standard.results[0]?.str_unit ? standard.results[0].str_unit : '',
    );
    const device = await alcoholService.getDeviceInformation();
    if (device.results.length > 0) {
      setDeviceInformation(device.results[0]);
      setShowDevice(true);
    }
    setLoading(false);
  };

  const headers = [
    {
      description: `${t('sample_tasted_dates')}`,
      field: 'dat_test_date',
      type: 'datetime',
      cache: true,
      fieldCache: 'round.dat_start',
      notSearchable: true,
      haveDefaultDay: true,
    },
    { description: `${t('common_taster')}`, field: 'str_name' },
    { description: `${t('driving')}`, field: 'str_driving' },
    {
      description: `${t('alcohol_concentration')} ${
        zoneUnit ? `(${zoneUnit})` : ''
      } `,
      field: 'flt_alcohol_result',
    },
    {
      description: `${t('test_time')}`,
      field: 'dat_alcohol_test_time',
      type: 'datetime',
    },
    {
      description: `${t('input_type')}`,
      field: 'str_input_type',
      type: 'selsect',
      fieldCache: 'str_input_type',
      notSearchable: true,
      selsectSearch: true,
    },
  ];
  const handleInput = row => {
    sessionStorage.setItem('inputType', row.input_type);
    setIsShowInputResult(true);
    setCurRow(row);
    renderInputModal(row);
    setInputValue(
      row?.flt_alcohol_result !== null ? row?.flt_alcohol_result : '',
    );
  };

  const handleChangeInput = evt => {
    sessionStorage.setItem('inputType', 'Manual');
    const value = evt.target.value;
    //NAZ
    if (
      deviceInformation.uuid_breathalyzer_device ===
      'f8887220-148d-11ee-a134-000000000000'
    ) {
      let reg = new RegExp(
        /^(([0-9][0-9]*)|(([0]\.\d{0,3}|[0-9][0-9]*\.\d{0,3})))$/,
      );
      if (value && reg.test(Number(value)) === false) {
        return alert(
          t('please_input_a_positive_number_up_to_n_decimal_places', {
            field: 3,
          }),
        );
      }
    }
    //apac
    if (
      deviceInformation.uuid_breathalyzer_device ===
      '5ddfb0da-ef0d-11ed-99d8-0017fa02c1e1'
    ) {
      let reg = new RegExp(
        /^(([0-9][0-9]*)|(([0]\.\d{1,2}|[0-9][0-9]*\.\d{1,2})))$/,
      );
      if (value && reg.test(Number(value)) === false) {
        return alert(
          t('please_input_a_positive_number_up_to_n_decimal_places', {
            field: 2,
          }),
        );
      }
    }

    setInputValue(value);
  };

  const handleConfirm = async row => {
    if (inputValue === '' || inputValue === null || inputValue === undefined) {
      return alert(`${t('input_value')} ${t('alcohol_concentration')}`);
    }

    setIsShowFailModal(true);
    setIsShowInputResult(false);
    renderModal(row);
    const time = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const confirmInfo = {
      uuid_user: curRow?.uuid_users,
      alcohol_result: inputValue,
      input_type: sessionStorage.getItem('inputType'),
      dat_alcohol_test_time: time,
    };
    setLoading(true);
    await alcoholService.alcoholResult(confirmInfo);
    const inputRes = await alcoholService.listAlcoholResult({
      taste_date: tasteDate,
    });
    setList(inputRes.results);
    setLoading(false);
  };

  const changeInputDate = async e => {
    setLoading(true);
    const res = await alcoholService.listAlcoholResult({
      taste_date: e.target.value,
    });
    if (res) {
      setTasteDate(e.target?.value);
      setList(res.results);
      setLoading(false);
    }
  };
  const renderModal = row => {
    return (
      <Modal
        className={listInputResultStyle.ModalSuccess}
        show={isShowFailModal}
      >
        {Number(alcholStandard) <= Number(inputValue) ? (
          <div className={listInputResultStyle.modalContainer}>
            <div className="wrong">
              <div
                className={'iconfont ' + listInputResultStyle.del}
                onClick={() => {
                  setIsShowFailModal(false);
                }}
              >
                &#xe61d;
              </div>
            </div>
            <div className={listInputResultStyle.right}>
              <span className={'iconfont ' + listInputResultStyle.alert}>
                &#xe69f;
              </span>
            </div>
            <div className={listInputResultStyle.attention}>
              {t('attention')}
            </div>
            <div className={listInputResultStyle.reminder}>
              <div className={listInputResultStyle.text}>
                {t(
                  'your_alcohol_test_result_exceeds_standard_please_test_again_later',
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={listInputResultStyle.modalContainer}>
            <div className="wrong">
              <div
                className={'iconfont ' + listInputResultStyle.del}
                onClick={() => {
                  setIsShowFailModal(false);
                }}
              >
                &#xe61d;
              </div>
            </div>
            <div className={listInputResultStyle.right}>
              <span className={'iconfont ' + listInputResultStyle.wrong}>
                &#xe8bd;
              </span>
            </div>
            <div className={listInputResultStyle.attention}>
              {t('attention')}
            </div>
            <div className={listInputResultStyle.reminder}>
              {t('alcohol_test_qualified')}
            </div>
          </div>
        )}
        <div className={listInputResultStyle.buttonContainer}>
          <Button
            className={listInputResultStyle.confirmButton}
            label={t('confirm')}
            confirm
            onClick={() => {
              setIsShowFailModal(false);
            }}
          ></Button>
        </div>
      </Modal>
    );
  };
  const renderInputModal = row => {
    return (
      <Modal className={listInputResultStyle.Modal} show={isShowInputResult}>
        <div className={listInputResultStyle.modalContainer}>
          <div className={listInputResultStyle.topContainer}>
            <div className={listInputResultStyle.tasterContainer}>
              <div className={listInputResultStyle.currentTasterText}>
                {t('current_taster')}
              </div>
              <div className={listInputResultStyle.tasterName}>
                {curRow?.str_name ? curRow.str_name : ''}
              </div>
            </div>
            <div className="wrong">
              <div
                className={'iconfont ' + listInputResultStyle.del}
                onClick={() => {
                  setIsShowInputResult(false);
                  setDisabledEdit(false);
                  setInputValue('');
                }}
              >
                &#xe61d;
              </div>
            </div>
          </div>
          <div className={listInputResultStyle.labelName}>
            {t('alcohol_concentration')}
          </div>

          <div className={listInputResultStyle.input}>
            <TextField
              margin="0px 10px 0px 0px"
              padding="12.5px 5px"
              value={inputValue}
              onChange={handleChangeInput}
              maxLength="20"
              disabled={!disabledEdit}
              style={{
                fontSize: '16px',
              }}
              number
              className={listInputResultStyle.alcohol}
              name="alcohol_concentration"
            />
            <Edit
              className={listInputResultStyle.edit}
              onClick={() => {
                setDisabledEdit(!disabledEdit);
              }}
            ></Edit>
          </div>
          <div className={listInputResultStyle.buttonContainer}>
            <Button
              className={listInputResultStyle.cancelButton}
              label={t('common_cancel')}
              remove
              margin="0px 10px 0px 0px"
              onClick={() => {
                setIsShowInputResult(false);
                setDisabledEdit(false);
                setInputValue('');
              }}
            ></Button>

            <Button
              className={listInputResultStyle.confirmButton}
              label={t('confirm')}
              confirm
              onClick={() => {
                handleConfirm(row);
                setDisabledEdit(false);
              }}
            ></Button>
          </div>
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    if ('serial' in navigator) {
      navigator.serial.addEventListener('connect', async e => {
        setTimeout(async () => {
          linkBreathalyzer();
        }, 1500);
      });
      navigator.serial.addEventListener('disconnect', async () => {
        clearInterval(interval);
        await close();
      });
    }
    return () => {
      if (port && port.readable) {
        port.readable.cancel();
        port.close();
      }
    };
  });

  let port = '';
  let interval = '';
  let reader = '';
  const linkBreathalyzer = async () => {
    clearInterval(interval);
    port = '';
    let filters = [
      {
        usbVendorId: deviceInformation.str_usb_vendorid,
        usbProductId: deviceInformation.str_usb_productid,
      },
    ];
    try {
      port = await navigator.serial.requestPort({ filters });
    } catch (e) {}
    await port.open({ baudRate: Number(deviceInformation.str_baud_rate) });
    reader = port.readable.getReader();
    setIsLink(true);
    interval = setInterval(async () => {
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          reader.releaseLock();
          break;
        }
        const data = String.fromCharCode.apply(null, new Uint8Array(value));
        const lastIndex = data.indexOf(zoneUnit);
        let valNumber = data.substring(0, lastIndex);

        //APAC
        if (
          deviceInformation.uuid_breathalyzer_device ===
          '5ddfb0da-ef0d-11ed-99d8-0017fa02c1e1'
        ) {
          const daIndex = valNumber.indexOf('>');
          if (daIndex > -1) {
            valNumber = valNumber.substring(daIndex + 1, valNumber.length);
          }
          setInputValue(valNumber ? valNumber : 0);
        }
        //NAZ
        if (
          deviceInformation.uuid_breathalyzer_device ===
          'f8887220-148d-11ee-a134-000000000000'
        ) {
          const dianIndex = valNumber.indexOf('.');
          if (dianIndex === 0) {
            valNumber = '0' + valNumber;
          }
          setInputValue(data);
        }

        sessionStorage.setItem('inputType', 'Breathalyzer');
      }
    }, 500);
  };

  const close = async () => {
    while (port.readable) {
      try {
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            break;
          }
        }
      } catch (error) {
      } finally {
        reader.releaseLock();
      }
    }
    setIsLink(false);
    await port?.close();
  };
  return (
    <div>
      <div className={listInputResultStyle.commonSite}>
        <div>
          {t('common_site')}:<b>&ensp;{`${nowSite}`}</b>
          <br />
          {t('maximum_alcohol_concentration')}:
          {alcholStandard !== (null || undefined || '') && (
            <b>&ensp;{`${alcholStandard}  ${zoneUnit}`}</b>
          )}
        </div>
        <div className={listInputResultStyle.linkBreathalyzer}>
          <p className={listInputResultStyle.status}>
            {`${t('list_breathalyzer')} `}
            <b
              className={
                isLink
                  ? listInputResultStyle.linkBreathalyzerGt
                  : listInputResultStyle.linkBreathalyzerRt
              }
            >
              {isLink ? t('link_breathalyzer') : t('not_link_breathalyzer')}
            </b>
          </p>
          <div>
            <Button
              className={listInputResultStyle.linkBreathalyzerBtn}
              label={t('breathalyzer_to_link')}
              onClick={() => {
                linkBreathalyzer();
              }}
              readOnly={!showDevice || readOnlyButton}
              confirm
              dataTip={t('breathalyzer_can_not_to_link_for_this_browser')}
            ></Button>
            {showDevice && readOnlyButton && (
              <ReactTooltip effect="solid" place="top" />
            )}
          </div>
        </div>
      </div>
      {renderModal(curRow)}
      {renderInputModal(curRow)}
      <GenericTable
        arrHeader={headers}
        arrRow={list ? list : []}
        searchable={true}
        onInput
        loading={loading}
        handleInput={handleInput}
        changeInputDate={changeInputDate}
        alcholStandard={alcholStandard}
      />
    </div>
  );
}

